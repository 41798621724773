import React, { useState, useEffect, ChangeEvent, FormEvent } from 'react';
import { useAppState } from '../../../../state';

// Material UI
import { Typography, makeStyles, TextField, Grid, Button, InputLabel, Theme } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) => ({
  gutterBottom: {
    marginBottom: '1em',
  },
  inputContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: '1.5em 0 3.5em',
    '& div:not(:last-child)': {
      marginRight: '1em',
    },
    [theme.breakpoints.down('sm')]: {
      margin: '1.5em 0 2em',
    },
  },
  textFieldContainer: {
    width: '100%',
  },
  continueButton: {
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
}));

interface RoomNameScreenProps {
  roomName: string;
  userUuid: string;
  nickName: string;
  updateNickName: (nickName: string) => void;
  setRoomName: (roomName: string) => void;
  handleSubmit: (event: FormEvent<HTMLFormElement>) => void;
}

export default function NickNameScreen({ roomName, userUuid, nickName, updateNickName, setRoomName, handleSubmit }: RoomNameScreenProps) {
  const classes = useStyles();
  // const { user } = useAppState();

  const [name, setName] = useState<String>("")

  useEffect(() => {
    if (nickName !== "" && nickName !== name) {
      setName(nickName)
    }
  }, [nickName])

  const submitName = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    updateNickName(name);
    handleSubmit(event);
  }

  return (
    <Grid container direction='column' style={{padding:'12px', maxWidth:'300px', marginLeft:'auto'}}>

      <Grid item xs={12}>
        <Typography variant="h5" className={classes.gutterBottom}>
          Join Video Chat
        </Typography>
      </Grid>

      <Grid item xs={12}>
        <form onSubmit={submitName}>
            {(
              <div className={classes.textFieldContainer}>
                <InputLabel shrink htmlFor="input-user-name">
                  Your Name
                </InputLabel>
                <TextField
                  id="input-user-name"
                  variant="outlined"
                  fullWidth
                  size="small"
                  value={name}
                  onChange={(e) => setName(e?.target?.value)}
                />
              </div>
            )}
          <Grid container justify="flex-end" style={{paddingTop: '6px'}}>
            <Button
              variant="contained"
              type="submit"
              color="primary"
              fullWidth
              disabled={!name}
              className={classes.continueButton}
            >
              Continue
            </Button>
          </Grid>
        </form>
      </Grid>

    </Grid>
  );
}
