import React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

import Button from '@material-ui/core/Button';
import EndCallButton from '../Buttons/EndCallButton/EndCallButton';
import FlipCameraButton from './FlipCameraButton/FlipCameraButton';

import useRoomState from '../../../hooks/useRoomState/useRoomState';
import useVideoContext from '../../../hooks/useVideoContext/useVideoContext';
import { Typography, Grid, Hidden } from '@material-ui/core';
import ToggleAudioButton from '../Buttons/ToggleAudioButton/ToggleAudioButton';
import ToggleRemoteAudioButton from '../Buttons/ToggleRemoteAudioButton/ToggleRemoteAudioButton';
import ToggleVideoButton from '../Buttons/ToggleVideoButton/ToggleVideoButton';
import ToggleScreenShareButton from '../Buttons/ToogleScreenShareButton/ToggleScreenShareButton';
import DeviceChangeButton from '../Buttons/DeviceChangeButton';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      // backgroundColor: theme.palette.background.paper,
      bottom: 0,
      left: 0,
      right: 0,
      position: 'relative',
      display: 'flex',
      padding: '0',
      marginTop: '4px',
      zIndex: 10,
      [theme.breakpoints.down('sm')]: {
        padding: 0,
      },
    },
    screenShareBanner: {
      position: 'fixed',
      zIndex: 10,
      bottom: '8px',
      left: 0,
      right: 0,
      height: '104px',
      background: 'rgba(0, 0, 0, 0.5)',
      '& h6': {
        color: 'white',
      },
      '& button': {
        margin: '0 2em',
        '&:hover': {
          color: '#600101',
          border: `2px solid #600101`,
          background: '#FFE9E7',
        },
      },
    },
    hideMobile: {
      display: 'initial',
      [theme.breakpoints.down('sm')]: {
        display: 'none',
      },
    },
    menuButton: {
      padding: '0 12px 0 12px',
      margin: '0 12px',
      display: 'flex',
      color: 'rgba(255,255,255,.85)',
      backgroundColor: 'rgba(75,75,75,.85)',
      '&:hover': {
        backgroundColor: 'rgba(125,125,125,.85)',
      },
      [theme.breakpoints.down('sm')]: {
        display: 'inline',
        margin: '0'
      },
    },
    endCallButton: {
      padding: '0 12px 0 12px',
      margin: '0 12px',
      display: 'flex',
      color: 'rgba(255,255,255,.85)',
      [theme.breakpoints.down('sm')]: {
        display: 'inline',
        margin: '0'
      },
    },
  })
);

export default function MenuBar() {
  const classes = useStyles();
  const { isSharingScreen, toggleScreenShare } = useVideoContext();
  const roomState = useRoomState();
  const isReconnecting = roomState === 'reconnecting';
  const { room } = useVideoContext();

  return (
    <>
      {isSharingScreen && (
        <Grid container justify="center" alignItems="center" className={classes.screenShareBanner}>
          <Typography variant="h6">You are sharing your screen</Typography>
          <Button
            onClick={() => toggleScreenShare()}
          >
            Stop Sharing
          </Button>
        </Grid>
      )}
      <footer className={classes.container}>
        <Grid container justify="center" alignItems="center">

          <Grid item style={{flex:1}}>
            <Grid container justify="center" style={{height:'100%'}}>
              <DeviceChangeButton className={classes.menuButton} />
              <ToggleAudioButton disabled={isReconnecting} className={classes.menuButton} />
              <ToggleVideoButton disabled={isReconnecting} className={classes.menuButton} />
              {/* <Hidden smDown>{
                !isSharingScreen &&
                <ToggleScreenShareButton disabled={isReconnecting} className={classes.menuButton} />
              }</Hidden> */}
              <FlipCameraButton className={classes.menuButton} />
            </Grid>
          </Grid>
          <Hidden smDown>
            <Grid item>
              <Grid container justify="flex-end" style={{height:'100%'}}>
                <ToggleRemoteAudioButton disabled={isReconnecting} className={classes.menuButton} />
                <EndCallButton className={classes.endCallButton} />
              </Grid>
            </Grid>
          </Hidden>
        </Grid>
      </footer>
    </>
  );
}
