import React, { useEffect, useState } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import {
Grid,
Button,
Input,
Checkbox,
Typography,
Link,
CircularProgress,
Backdrop,
} from '@material-ui/core/';
import {useStripe, useElements, PaymentElement} from '@stripe/react-stripe-js';
import { useSnackbar } from 'notistack';
import logToServer from '../../utils/log_to_server';

const useStyles = makeStyles((theme) => createStyles({
  container: {
    padding: theme.spacing(2),
  }
}));

export default function StripePayments(props) {
  const classes = useStyles();
  const stripe = useStripe();
  const elements = useElements();
  const { enqueueSnackbar } = useSnackbar();

  // State
  const [disableCheckout, setDisableCheckout] = useState(true);
  const [inProgress, setInProgress] = useState(false);
  const [showPayment, setShowPayment] = useState(false);

  useEffect(() => {
    // Once stripe is loaded, handle stripe redirect landing
    if (stripe) {
      let urlParams = window.location.search;
      let searchParams = new URLSearchParams(urlParams);
      let client_secret = searchParams.get('payment_intent_client_secret')

      // Only process if this is landing from a stripe redirect
      if (client_secret) {
        getIntent(client_secret);
      } else {
        // Show payment element
        setShowPayment(true);
      }
    }
  }, [stripe]);

  // Check payment intent from redirect for success
  const getIntent = async (client_secret) => {
    // We landed here do to a redirect from a completed Stripe transaction
    // Check the status of that transaction and inform the user
    const intent = await stripe.retrievePaymentIntent(client_secret);
    if (intent.error) {
      console.error('Could not retreive payment intent ', intent);
    } else {
      switch (intent.paymentIntent.status) {
        case 'succeeded':
          // enqueueSnackbar('Thank you for subscribing to SetStream!', {variant: 'info'});
          document.location.replace(document.location.origin + document.location.pathname + '?success=true');
          break;
        case 'requires_payment_method':
          // TODO: handle all other intent states
          // Get reason for failed payment and display
          enqueueSnackbar('We had an issue with your payment. ', { variant: 'warning' });
          console.error('Payment Error ', intent.paymentIntent.status);
          props.handleClose();
          break;
        default:
          console.log('intent status is ', intent.status);
          props.handleClose();
      }
    }
  }

  const makePayment = async () => {
    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      enqueueSnackbar('We encountered an issue.  Your card has not been charged.  Please try again. - NoStripe' , {variant:'warning'} );
      return;
    }
    setInProgress(true);

    const payment = await stripe.confirmPayment({
      elements,
      redirect: 'if_required',
      confirmParams: {
        return_url: window.location.href,
      }
    });
    setInProgress(false);

    // Handle direct response
    if (payment.error) {
      enqueueSnackbar('We had an issue with your payment. ' + payment.error.message, { variant: 'warning' });
      console.error('Payment Error ', payment.error);
      // Log error to server
      logToServer(payment.error);
    } else {
      switch (payment.paymentIntent.status) {
        case 'succeeded':
          enqueueSnackbar('Success.  We are creating your subscription now.', { variant: 'success' });
          props.handleClose();
          break;
        case 'requires_payment_method':
          // There was an issue with the payment
          enqueueSnackbar('We had an issue with your payment. ' + payment.paymentIntent.status, { variant: 'warning' });
          console.error('Payment Error ', payment.paymentIntent.status);
          break;
        default:
          // There was an issue with the payment
          enqueueSnackbar('We had an issue with your payment. ' + payment.paymentIntent.status, { variant: 'warning' });
          console.error('Payment Error ', payment.paymentIntent.status);

      }
    }
  }

  return (
    <Grid container className={classes.container}>
      <Grid item xs={12}>
        {
          showPayment &&
          <PaymentElement />
        }
      </Grid>

      <Grid item xs={12} align="right">
        <Checkbox
          type="checkbox"
          checked={!disableCheckout}
          onChange={() => setDisableCheckout(!disableCheckout)}
          label="I Agree"
        />
        <Typography display="inline">
          I have read and agree to the SetStream.io
          <Link
            href={process.env.REACT_TERMS}
            target="_blank"
          >
            {' Terms and Conditions.'}
          </Link>
        </Typography>
      </Grid>

      <Grid item xs={12} align="right">
        <Button
          onClick={makePayment}
          disabled={disableCheckout}
          variant="contained"
          color="primary"
        >
          Subscribe
        </Button>
      </Grid>

      <Backdrop open={inProgress} style={{zIndex:2001}}>
        <CircularProgress color="primary" variant="indeterminate" />
      </Backdrop>
    </Grid>
  );

}
