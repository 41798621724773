import React from 'react';
import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import { CookiesProvider } from 'react-cookie';

// Utility
import ComponentErrorBoundary from './errors/component_error_boundary';
import useDarkTheme from './themes/useDarkTheme';
import AppContent from './AppContent';
import { BrandingProvider } from './components/Branding/Context/BrandingProvider';

// ************** APP ****************
export default function App() {
  const darkTheme = useDarkTheme();

  return (
    <ComponentErrorBoundary
      section='App'
      showErrors={true}
    >
      <CookiesProvider>
        <MuiThemeProvider theme={darkTheme}>
          <BrandingProvider>
            <CssBaseline />
            <AppContent />
          </BrandingProvider>
        </MuiThemeProvider>
      </CookiesProvider>
    </ComponentErrorBoundary>
  );
}
