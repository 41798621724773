
import React, { useEffect, useState, useRef, useMemo } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import {
Grid,
Button,
} from '@material-ui/core/';
import { useSnackbar } from 'notistack';
import useCamera from '../../CameraProvider/useCamera';

const useStyles = makeStyles((theme) => createStyles({
  container: {
    padding: theme.spacing(4),
    // border: '2px solid ' + theme.palette.secondary.main,
    borderRadius: theme.spacing(2),
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  item: {
    padding: theme.spacing(3),
  },
  section: {
    margin: 'auto',
    textAlign: 'center'
  },
}));

interface Props {

}

export default function Connection(props: Props) {
  // Hooks
  const {
    enabled,
    label,
    mediaStream,
    mediaStreamStatus,
    peerConnection,
    peerConnectionStatus,
    connectPeerConnection,
    closePeerConnection,
    cssClasses,
    getVideoMedia,
    stopAllMedia
  } = useCamera();
  const classes = useStyles();

  // State

  // Effects

  // Actions
  const buttonAction = () => {
    switch (peerConnectionStatus) {
      case "connected":
        return closePeerConnection
        break;
      case "new":
      case "connecting":
      case "disconnected":
      case "failed":
      case "closed":
      default:
        return connectPeerConnection
        break;
    }
  }

  const buttonText = () => {
    switch (peerConnectionStatus) {
      case "connected":
        return "Stop Stream"
        break;
      case "new":
      case "connecting":
        return "Connecting"
        break;
      case "disconnected":
      case "failed":
      case "closed":
      default:
        return "Start Stream"
        break;
    }
  }

  // Render
  return (
    <Grid 
      container 
      className={classes.container}
    >

      <Grid
        item
        xs={6}
        className={classes.section}
      >
        <Button
          align="left"
          variant="contained"
          onClick={ mediaStreamStatus === "active" ? stopAllMedia : getVideoMedia }
          // disabled={mediaStream?.active}
        >
          { mediaStreamStatus === "active" ? "Stop Preview" : "Preview"}
        </Button>
      </Grid>

      <Grid 
        item 
        xs={6} 
        className={classes.section}
      >
        <Button
          align="right"
          variant="contained"
          color="primary"
          onClick={buttonAction()}
        >
          { buttonText() }
        </Button>
      </Grid>

    </Grid>
  );

}
