import React, { useEffect, useState } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import {
Grid,
} from '@material-ui/core/';
import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';

const stripe = loadStripe(process.env.REACT_APP_STRIPE_KEY);

export default function StripeProvider(props) {

  const appearance = {
    theme: 'night',
  }

  return (
    <Elements
      stripe={stripe}
      options={{
        clientSecret: props.clientSecret,
        appearance: appearance
      }}
    >
      {props.children}
    </Elements>
  );

}
