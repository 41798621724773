import React, { useState } from 'react';
import { Route, Switch, BrowserRouter } from 'react-router-dom';

// Material UI
import {
  AppBar,
  Toolbar,
  Box,
  Grid,
  Hidden,
  Tooltip,
} from '@material-ui/core';
import { Button, IconButton } from '@material-ui/core';
import CancelIcon from '@material-ui/icons/Cancel';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';

// Utility Libraries
import { SnackbarProvider } from 'notistack';
import Cookies from 'js-cookie';
import axios from 'axios';
import adapter from 'webrtc-adapter';

// Routes
import ClientViewer from './components/Viewers/client_portal';
import ProjectView from './components/Project/project_portal';
import SiteAdmin from './components/Admin/admin_portal';
import SiteAdminLogin from './components/Admin/admin_sign_in';
import DashboardPortal from './components/Dashboard/dashboard_portal';
import AccountLogin from './components/Dashboard/Account/account_login';
import CreateError from './testing/create_error';
import ComponentErrorBoundary from './errors/component_error_boundary';

// Context
import { DashboardAccountProvider } from './components/Dashboard/Context/DashboardAccountProvider';

// VC
import { VideoProvider } from './components/Twilio/VideoProvider';
import useConnectionOptions from './utils/useConnectionOptions/useConnectionOptions';
import AppStateProvider, { useAppState } from './state';
import ErrorDialog from './components/Twilio/ErrorDialog/ErrorDialog';

// Utility
import useCustomCSS from './themes/useCustomCSS';
import BrandLogo from './components/Branding/BrandLogo';

const VideoApp = (props) => {
  const { error, setError } = useAppState();
  const connectionOptions = useConnectionOptions();

  return (
      <VideoProvider 
        options={connectionOptions} 
        onError={setError}
        roomName={props.roomName}
        userUuid={props.userUuid}
      >
        <ErrorDialog dismissError={() => setError(null)} error={error} />
        {props.content}
      </VideoProvider>
  );
};

// ************** APP ****************
export default function AppContent(props) {
  const classes = useCustomCSS();
  
  // Dynamic app bar content
  const [projectName, setProjectName] = useState('');
  const [switcherControls, setSwitcherControls] = useState([]);
  const [chatControls, setChatControls] = useState([]);
  const [videoControls, setVideoControls] = useState([]);
  const [room, setRoom] = useState();
  const [viewerId, setViewerId] = useState();

  // Snackbar handlers
  const notistackRef = React.createRef();
  const closeSnack = (key) => () => {
      notistackRef.current.closeSnackbar(key);
  }

  // Actions
  async function logout() {
    let pageToLogOut = window.location.pathname.split('/');
    console.log('Logging out ', pageToLogOut[3] ? pageToLogOut[3] : pageToLogOut[2]);

    switch (pageToLogOut[1]) {
      case 'VideoVillage':
        axios.post(process.env.REACT_APP_HOST_URL + '/api/vv_logout/' + viewerId)
        .then(result => {
          Cookies.remove(viewerId, {  domain: process.env.REACT_APP_DOMAIN });
          window.location.reload(false);
        })
        .catch(err => {
          Cookies.remove(viewerId, {  domain: process.env.REACT_APP_DOMAIN });
          window.location.reload(false);
          console.error('Error - Could not log you out.', err);
        });
        break;
      case 'Broadcast':
        Cookies.remove(pageToLogOut[2], {  domain: process.env.REACT_APP_DOMAIN });
         window.location.reload(false);
        break;
      case 'Project':
        Cookies.remove(pageToLogOut[2], {  domain: process.env.REACT_APP_DOMAIN });
         window.location.reload(false);
        break;
      case 'site_admin':
        Cookies.remove(pageToLogOut[2], {  domain: process.env.REACT_APP_DOMAIN });
        window.location.reload(false);
        break;
      default:
        Cookies.remove(pageToLogOut[2], {  domain: process.env.REACT_APP_DOMAIN });
         window.location.reload(false);
    }

  }

  // Render Functions
  let content = (
    <Switch>
      <Route
        path = '/Producer'
        render = {() => (
          <DashboardAccountProvider>
            <DashboardPortal cssClasses={classes} />
          </DashboardAccountProvider>
        )}
      />
      {
        process.env.REACT_APP_ENV === 'development' &&
        <Route
        path = '/Account/Login'
        render = {() => <AccountLogin cssClasses={classes} />}
        />
      }
      <Route
        path = '/Project'
        render = {() => 
          <ProjectView 
            setProjectName={setProjectName}
            setSwitcherControls={setSwitcherControls}
            setChatControls={setChatControls}
            setVideoControls={setVideoControls}
            setRoom={setRoom}
            setViewerId={setViewerId}
            adapter={adapter} 
            cssClasses={classes} 
          />
        }
      />
      <Route
        path = '/Broadcast'
        render = {() => 
        <ProjectView 
          setProjectName={setProjectName}
          setSwitcherControls={setSwitcherControls}
          setChatControls={setChatControls}
          setVideoControls={setVideoControls}
          setRoom={setRoom}
          setViewerId={setViewerId}
          adapter={adapter} 
          cssClasses={classes} 
        />
        }
      />
      <Route
        path = '/VideoVillage'
        render = {() => 
          <ClientViewer 
            setProjectName={setProjectName}
            setSwitcherControls={setSwitcherControls}
            setChatControls={setChatControls}
            setVideoControls={setVideoControls}
            setRoom={setRoom}
            setViewerId={setViewerId}
            adapter={adapter} 
            cssClasses={classes} 
          />
        }
      />
      <Route
        exact path = '/ssadmin'
        render = {() => <SiteAdminLogin cssClasses={classes} /> }
      />
      <Route
        path = '/site_admin'
        render = {() => <SiteAdmin cssClasses={classes} />}
      />
      {/* Do not delete - Do not enable */}
      {/* <Route
        path = '/register'
        render = {() => <SignUp cssClasses={classes} />}
      /> */}
      <Route
        path = '/error'
        render = {() => <CreateError />}
      />
      <Route
        path = '*'
        render = {() => window.location.href = "https://setstream.io/my-account/projects/" }
      />
    </Switch>
  );

  return (
    <SnackbarProvider
      maxSnack={3}
      preventDuplicate
      dense={true}
      ref={notistackRef}
      classes={{
        variantSuccess: classes.notiSuccess,
        variantInfo: classes.notiInfo,
        variantError: classes.notiError,
        variantWarning: classes.notiWarning,
      }}
      action = {(key) => (
        <IconButton onClick={closeSnack(key)}>
          <CancelIcon />
        </IconButton>
      )}
    >
      <BrowserRouter>
        <Box className={classes.root} id="appBase">
          <AppStateProvider>
            {
              window.location.href.indexOf('Producer') === -1 &&
              <Hidden smDown >
                {/* Desktop */}
                <AppBar position="sticky" elevation={10} className={classes.appBar}>
                  <Toolbar style={{height:'45px', minHeight:'45px', maxHeight:'45px'}}>
                    <Grid container>
                      <Grid item xs={3}>
                        <BrandLogo
                          size="small"
                          showName={true}
                        />
                      </Grid>

                      <Grid item align="left" xs={8} style={{margin: 'auto'}}>
                        {projectName}
                        <span style={{paddingRight: '32px'}}></span>
                        {switcherControls}
                        <Hidden mdUp>
                          {chatControls}
                        </Hidden>
                        {videoControls}
                      </Grid>

                      <Grid item xs={1} align="right">
                        <Tooltip
                          title="Logout"
                          aria-label='Logout'
                        >
                          <Button
                            onClick={logout}
                            label="Logout"
                            variant="outlined"
                            className={classes.loginButton}
                          >
                            <ExitToAppIcon />
                          </Button>
                        </Tooltip>
                      </Grid>
                    </Grid>
                  </Toolbar>
                </AppBar>
              </Hidden>
            }

            {
              window.location.href.indexOf('Producer') === -1 &&
              <Hidden mdUp >
                {/* Mobile */}
                <AppBar position="relative" elevation={10} className={classes.appBar}>
                  <Toolbar style={{height:'fit-content', minHeight:'45px'}}>
                    <Grid container>
                      <Grid 
                        item 
                        xs={1} 
                      >
                        <Grid container>
                        <BrandLogo
                          size="small"
                          showName={false}
                        />
                        </Grid>
                      </Grid>

                      <Grid 
                        item 
                        align="center" 
                        xs={10} 
                      >
                        {switcherControls}
                        {chatControls}
                        {videoControls}
                      </Grid>

                      <Grid 
                        item 
                        xs={1} 
                        align="right" 
                      >
                        <Tooltip
                          title="Logout"
                          aria-label='Logout'
                        >
                          <Button
                            onClick={logout}
                            label="Logout"
                            variant="outlined"
                            className={classes.loginButton}
                          >
                            <ExitToAppIcon />
                          </Button>
                        </Tooltip>
                      </Grid>
                    </Grid>
                  </Toolbar>
                </AppBar>
              </Hidden>
            }

            <ComponentErrorBoundary
              section='Root'
              showErrors={true}
            >
              <VideoApp 
                content={content} 
                roomName={room}
                userUuid={viewerId}
              />
            </ComponentErrorBoundary>

          </AppStateProvider>
        </Box>
      </BrowserRouter>
    </SnackbarProvider>
  );
}
