import setStereoOpus from "./setStereoOpus";
import setMultiOpus from "./setMultiOpus";

export default function updateSdpAudioParameters(sdp, audioChannels) {
  if (audioChannels < 2) {
    return sdp;
  } else if (audioChannels === 2) {
    return setStereoOpus(sdp)
  } else if (audioChannels > 2) {
    setMultiOpus(sdp)
  }
}