import React, { useEffect, useState, useRef, ReactNode } from 'react';
import { VideoCodecs, videoCodecs } from './Settings/video_codecs'
import { VideoBitrates, videoBitrates } from './Settings/video_bitrates';
import { VideoResolutions, videoResolutions } from './Settings/video_resolutions';
import { AudioChannels, audioChannels, audioChannelTypes } from './Settings/audio_channels';
import getAvailableDevices from './Utils/getAvailableDevices';
import useVideoDevices from './Hooks/useVideoDevices';
import useAudioDevices from './Hooks/useAudioDevices';
import useScreenDevices from './Hooks/useScreenDevices';
import usePeerConnection from './Hooks/usePeerConnection';
import useUserMedia from './Hooks/useUserMedia';
import useIce from './Hooks/useIce';
import useDevices from './Hooks/useDevices';
import useMcDir from './Hooks/useMcDir';
import useMcWss from './Hooks/useMcWss';
import usePeerConnectionStatus from './Hooks/usePeerConnectionStatus';

interface Props {
  label: string,
  enabled: boolean,
  color: string,
  logoLoop: any,
  cssClasses: any,
  children: ReactNode
}

interface Context {
  // Camera Metadata
  label: string,
  enabled: boolean,
  color: string,
  
  // Video Devices
  videoInputDevices: MediaDeviceInfo[],
  videoDeviceId: string,
  updateVideoDeviceId: React.Dispatch<React.SetStateAction<boolean>>,
  videoCodecs: [VideoCodec],
  videoCodec: string,
  updateVideoCodec: (arg0: string) => void,
  videoBitrates: [VideoBitrates],
  videoBitrate: number,
  updateVideoBitrate: (arg0: number) => void,
  videoResolutions: [VideoResolutions],
  videoResolution: number,
  updateVideoResolution: (arg0: number) => void,
  
  // Audio Devices
  audioInputDevices: MediaDeviceInfo[],
  audioDeviceId: string,
  updateAudioDeviceId: React.Dispatch<React.SetStateAction<boolean>>,
  echoCancellation: boolean,
  updateEchoCancellation: boolean,
  audioChannelTypes: [AudioChannelTypes],
  audioChannels: number,
  updateAudioChannels: (arg0: number) => void,

  // Peer Connection
  rtcPeerConnection: RTCPeerConnection,

  // Misc
  logoLoop: any,
  cssClasses: any,
}

export const CameraContext = React.createContext<Context>(null!);

export function CameraProvider(props: Props) {

  // ** STATE **

  // Meta
  const [enabled, setEnabled] = useState<Boolean>(true);

  // Media Devices 
  const {
    devices,
    videoInputDevices,
    audioInputDevices,
    audioOutputDevices,
    videoInputPermission,
    audioInputPermission,
    audioOutputPermission
  } = useDevices();

  // Video
  const {
    videoDeviceId,
    updateVideoDeviceId,
    videoConstraints,
    updateVideoContstraints,
    facingMode,
    updateFacingMode,
    videoResizeMode,
    updateVideoResizeMode,
    videoCodec,
    updateVideoCodec,
    videoBitrate,
    updateVideoBitrate,
    videoWidth, 
    updateVideoWidth
   } = useVideoDevices(
    props.label,
    props.publisherUuid,
    videoInputDevices, 
  );

  // Audio
  const {
    audioDeviceId,
    updateAudioDeviceId,
    audioConstraints,
    setAudioConstraints,
    audioChannels,
    updateAudioChannels,
    echoCancellation,
    updateEchoCancellation,
    noiseSuppression,
    updateNoiseSuppression
   } = useAudioDevices(
    props.label,
    props.publisherUuid,
    audioInputDevices, 
    audioOutputDevices
  );

  // Screen Sharing
  const {
    screenConstraints,
    setScreenConstraints,
    showCursor,
    setShowCursor,
    screenResizeMode,
    setScreenResizeMode
   } = useScreenDevices();

  // Connection
  const {
    peerConnectionStatus,
    updatePeerConnectionStatus
  } = usePeerConnectionStatus();

  const {
    mediaStream,
    mediaStreamStatus,
    getVideoMedia,
    getScreenMedia,
    // restartVideoMedia,
    stopAllMedia,
    updatePCTracks,
    setUpdatePCTracks
  } = useUserMedia(
    enabled, 
    videoDeviceId, 
    videoConstraints, 
    audioDeviceId, 
    audioConstraints, 
    screenConstraints
  );

  const {
    iceServers,
    getIceServers
   } = useIce();

   const {
    mcWssUrl,
    mcWssToken,
    streamName,
    getMcWssAuth,
    resetMcAuth
   } = useMcDir(
    enabled, 
    props.label, 
    props.publisherUuid, 
    props.isBroadcastOnly,
    props.mcDirUrl,
    props.mcDirToken,
    props.mcStreams
  );

  const {
    mcWss,
    createMcWss,
  } = useMcWss(
    enabled, 
    props.label, 
    mcWssUrl, 
    mcWssToken,
    getMcWssAuth,
    resetMcAuth,
    updatePeerConnectionStatus
  );
  
  const {
    peerConnection,
    connectPeerConnection,
    closePeerConnection
   } = usePeerConnection(
    enabled,
    streamName,
    mediaStream,
    mediaStreamStatus,
    updatePCTracks,
    setUpdatePCTracks,
    getVideoMedia,
    stopAllMedia,
    videoBitrate,
    videoCodec,
    audioChannels,
    iceServers, 
    getIceServers,
    getMcWssAuth,
    mcWss,
    createMcWss,
    updatePeerConnectionStatus
  );

  // ** Refs **
  const videoElement: React.RefObject<HTMLElement> = React.createRef();

  // ** Effects **

  return (
    <CameraContext.Provider
      value={{
        // Meta
        label: props.label,
        enabled: enabled,
        color: props.color,
        publisherUuid: props.publisherUuid,
        isBroadcastOnly: props.isBroadcastOnly,

        // Video
        videoInputDevices: videoInputDevices,
        videoDeviceId: videoDeviceId,
        updateVideoDeviceId: updateVideoDeviceId,
        videoInputPermission: videoInputPermission,
        facingMode: facingMode,
        updateFacingMode: updateFacingMode,
        videoResizeMode: videoResizeMode,
        updateVideoResizeMode: updateVideoResizeMode,
        videoCodecs: videoCodecs,
        videoCodec: videoCodec,
        updateVideoCodec: updateVideoCodec,
        videoBitrates: videoBitrates,
        videoBitrate: videoBitrate,
        updateVideoBitrate: updateVideoBitrate,
        videoResolutions: videoResolutions,
        videoWidth: videoWidth,
        updateVideoWidth: updateVideoWidth,
        
        // Audio
        audioInputDevices: audioInputDevices,
        audioDeviceId: audioDeviceId,
        updateAudioDeviceId: updateAudioDeviceId,
        audioInputPermission: audioInputPermission,
        audioChannelTypes: audioChannelTypes,
        audioChannels: audioChannels,
        updateAudioChannels: updateAudioChannels,
        echoCancellation: echoCancellation,
        updateEchoCancellation: updateEchoCancellation,
        noiseSuppression: noiseSuppression,
        updateNoiseSuppression: updateNoiseSuppression,
        
        // Screen
        screenConstraints: screenConstraints,
        setScreenConstraints: setScreenConstraints,
        showCursor: showCursor,
        setShowCursor: setShowCursor,
        screenResizeMode: screenResizeMode,
        setScreenResizeMode: setScreenResizeMode,
        
        // Peer Connection
        peerConnection: peerConnection,
        peerConnectionStatus: peerConnectionStatus,
        connectPeerConnection: connectPeerConnection,
        closePeerConnection: closePeerConnection,
        
        // Millicast
        mcDirUrl: props.mcDirUrl,
        mcDirToken: props.mcDirToken,
        iceServers: iceServers,
        getIceServers: getIceServers,
        mcWss: mcWss,
        createMcWss: createMcWss,
        
        // Media Stream
        mediaStream: mediaStream,
        mediaStreamStatus: mediaStreamStatus,
        getVideoMedia: getVideoMedia,
        // restartVideoMedia: restartVideoMedia,
        stopAllMedia: stopAllMedia,
        
        // Elements
        videoElement: videoElement,
        logoLoop: props.logoLoop,
        cssClasses: props.cssClasses,
      }}
    >
      {props.children}
    </CameraContext.Provider>
  );
}
