import React, { FormEvent, useEffect, useState } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import {
Grid,
Hidden,
Typography,
Backdrop,
CircularProgress,
Paper,
TextField,
Button,
Link
} from '@material-ui/core/';
import { useSnackbar } from 'notistack';
import BrandLogo from '../Branding/BrandLogo';

const useStyles = makeStyles((theme) => createStyles({
  signInBox: {
    justifyContent: 'center',
    alignContent: 'center'
  },
  signInPaper: {
    width: 'calc(100% - 24px)',
    maxWidth: '400px !important',
    margin: 'auto',
    // marginTop: theme.spacing(24),
    padding: theme.spacing(12),
    [theme.breakpoints.down('sm')]: {
    }
  },
  signInLogoBox: {
    margin: "auto",
    maxWidth: "50px",
    maxHeight: "50px",
  },
  signInTitle: {
    paddingTop: theme.spacing(6)
  }
}));

export default function RequestWaitingRoomAccess(props) {
  // Hooks
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  // State
  const [inProgress, setInProgress] = useState(false);

  // Render
  return (
      <Paper className={classes.signInPaper}>
        <Grid container className={classes.signInBox}>
          <Grid item xs={12} align="center">
            <Grid className={classes.signInLogoBox}>
              <BrandLogo
                size="medium"
                showName={false}
              />
            </Grid>
          </Grid>
          <Typography className={classes.signInTitle} variant="h5">
            Request Access
          </Typography>

          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="nickName"
            label="Your Name"
            type="text"
            id="nickName"
            value={props.nickName}
            onChange = {(e) => props.updateState('nickName', e.target?.value)}
          />

          {
            !props.wrHash &&
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="code"
              label="Access Code"
              type="password"
              id="code"
              value={props.accessCode}
              onChange = {(e) => props.setAccessCode(e.target?.value)}
            />
          }

          <Button
            onClick={props.loginWaitingRoom}
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            disabled={inProgress}
          >
            Request Access
          </Button>

          <Grid container className={props.cssClasses.tableFooter}>
            <Grid item xs={12} align="right" className={props.cssClasses.tableCell}>
              By signing in you agree to our<br />
              <Link href="https://setstream.io/terms-and-conditions/" target="_blank">
                {" Terms and Conditions"}
              </Link>
            </Grid>
          </Grid>

        </Grid>
        
        <Backdrop open={inProgress} style={{zIndex:2001}}>
          <CircularProgress color="primary" variant="indeterminate" />
        </Backdrop>
      </Paper>
  );

}
