import React, { useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import formatExpiration from '../../../utils/format_expiration';

// Material UI
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import {
Grid,
Table,
TableContainer,
TableHead,
TableRow,
TableBody,
TableCell,
Paper,
Typography,
Button,
Select,
MenuItem,
CircularProgress,
Link,
Hidden,
IconButton,
} from '@material-ui/core/';
import SettingsIcon from '@material-ui/icons/Settings';
import CustomerPortal from '../../Subscriptions/customer_portal';
import useDashboardAccount from '../Context/useDashboardAccount';

// Subscriptions
import PurchaseSubscriptionDialog from '../../Subscriptions/Dialog/purchase_subscription_dialog';
import usePusher from '../Pusher/usePusher';
import SessionAttachSelect from '../Subscriptions/session_attach_select';
import useHasActiveSubs from '../../Passes/Hooks/use_has_active_subs';

export default function SubscriptionsList(props) {
  // Account
  const { 
    subscriptions, 
    isLoading, 
    customer_uuid, 
    projects,
    getAccountData,
  } = useDashboardAccount();

  // Setup Pusher
  const [pusher, channel] = usePusher();
  useEffect(() => {
    if (channel) {
      channel.bind('update_dashboard', (message) => {
        setIsDialogOpen(false);
      });
    }
    return (() => {
      if (channel) {
        channel.unbind('update_dashboard');
      }
    })
  }, [pusher, channel]);
  
  const {enqueueSnackbar, closeSnackbar} = useSnackbar();
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isSettingsOpen, setIsSettingsOpen] = useState(false);
  const hasActiveSubs = useHasActiveSubs(subscriptions)

  const headers = {
    _id: 'Subscription ID',
    expires_on: 'Expires',
    max_allowed_users: 'Max Viewers',
    session_id_attached_to: 'Active Project'
  }

  useEffect(() => {
    // check for landing here from stripe redirect
    let urlParams = window.location.search;
    let searchParams = new URLSearchParams(urlParams);
    let payment_intent = searchParams.get('payment_intent');
    let success = searchParams.get('success');
    if (payment_intent) {
      setIsDialogOpen(true);
    }
    if (success === 'true') {
      enqueueSnackbar('Thank you for subscribing to SetStream!', {variant: 'info'});
    }
  }, []);

  function formatCell(sub, meta) {
    switch (meta) {
      case 'settings':
        return(
          <IconButton
            onClick={() => setIsSettingsOpen(!isSettingsOpen)}
          >
            <SettingsIcon />
          </IconButton>
        )
        break;
      case '_id':
      case 'max_allowed_users':
        return(
          <Typography>
            {sub[meta]}
          </Typography>
        )
        break;
      case 'expires_on':
        if (sub[meta]) {
          return (
            <Typography>
              {
                formatExpiration(sub[meta])
              }
            </Typography>
          );
        } else {
          return ( '-' );
        }
        break;
      case 'session_id_attached_to':
        return (
          <SessionAttachSelect
            sub={sub}
            cssClasses={props.cssClasses}
          />
        )
        break;
    }
  }

  return (
    <Grid className={props.cssClasses.expansion}>
      <Grid className={props.cssClasses.tableBody}>

        {/* Desktop */}
        <Hidden smDown>
          <Grid container className={props.cssClasses.tableBody}>
          {/* <Grid item xs={1} align="center" className={props.cssClasses.tableHeader}>
            Settings
          </Grid> */}

            <Grid item xs={4} align="center" className={props.cssClasses.tableHeader}>
              Subscription ID
            </Grid>

            <Grid item xs={3} align="center" className={props.cssClasses.tableHeader}>
              Renews
            </Grid>

            <Grid item xs={2} align="center" className={props.cssClasses.tableHeader}>
              Viewers
            </Grid>

            <Grid item xs={3} align="center" className={props.cssClasses.tableHeader}>
              Project
            </Grid>
          </Grid>

          {
            hasActiveSubs &&
            subscriptions?.map((sub, row) => (
              <Grid key={row} container className={props.cssClasses.tableRow}>
                {/* <Grid key={'cell0' + row} item xs={1} align="center" className={props.cssClasses.tableCell}>
                  {formatCell(sub, 'settings')}
                  <SubscriptionsSettings
                    {...props}
                    isOpen={isSettingsOpen}
                    handleClose={() => setIsSettingsOpen(!isSettingsOpen)}
                    subscriptionId={sub._id}
                  />
                </Grid> */}

                <Grid key={'cell1' + row} item xs={4} align="center" className={props.cssClasses.tableCell}>
                  {formatCell(sub, '_id')}
                </Grid>

                <Grid key={'cell2' + row} item xs={3} align="center" className={props.cssClasses.tableCell}>
                  {formatCell(sub, 'expires_on')}
                </Grid>

                <Grid key={'cell3' + row} item xs={2} align="center" className={props.cssClasses.tableCell}>
                  {formatCell(sub, 'max_allowed_users')}
                </Grid>

                <Grid key={'cell4' + row} item xs={3} align="center" className={props.cssClasses.tableCell}>
                  {formatCell(sub, 'session_id_attached_to')}
                </Grid>
              </Grid>
            ))
          }
        </Hidden>

        {/* Mobile */}
        <Hidden mdUp>
          {
            hasActiveSubs &&
            subscriptions?.map((sub, row) => (
              <Grid key={row} container className={props.cssClasses.tableRow}>
                {/* <Grid item xs={2} align="center" className={props.cssClasses.tableHeader}>
                  Settings
                </Grid> */}
                <Grid item xs={12} align="center" className={props.cssClasses.tableHeader}>
                  Subscription ID
                </Grid>
                {/* <Grid key={'cell1' + row} item xs={2} align="center" className={props.cssClasses.tableCell}>
                  {formatCell(sub, 'settings')}
                  <SubscriptionsSettings
                    {...props}
                    isOpen={isSettingsOpen}
                    handleClose={() => setIsSettingsOpen(!isSettingsOpen)}
                    subscriptionId={sub._id}
                  />
                </Grid> */}
                <Grid key={'cell5' + row} item xs={12} align="center" className={props.cssClasses.tableCell}>
                  {formatCell(sub, '_id')}
                </Grid>

                <Grid key={'cell6' + row} item xs={12} style={{paddingTop: '16px'}}></Grid>

                <Grid key={'cell7' + row} item xs={6} align="center" className={props.cssClasses.tableCell}>
                  Renews
                </Grid>
                <Grid key={'cell8' + row} item xs={6} align="center" className={props.cssClasses.tableCell}>
                  Viewers
                </Grid>

                <Grid key={'cell9' + row} item xs={6} align="center" className={props.cssClasses.tableCell}>
                  {formatCell(sub, 'expires_on')}
                </Grid>
                <Grid key={'cell10' + row} item xs={6} align="center" className={props.cssClasses.tableCell}>
                  {formatCell(sub, 'max_allowed_users')}
                </Grid>

                <Grid key={'cell11' + row} item xs={12} style={{paddingTop: '24px'}}></Grid>

                <Grid key={'cell12' + row} item xs={12} align="center" className={props.cssClasses.tableCell}>
                  Project
                </Grid>
                <Grid key={'cell13' + row} item xs={12} align="center" className={props.cssClasses.tableCell}>
                  {formatCell(sub, 'session_id_attached_to')}
                </Grid>
              </Grid>
            ))
          }
        </Hidden>

        {
          !hasActiveSubs &&
          !isLoading &&
          <Grid container className={props.cssClasses.tableBody}>
            <Grid item xs={12} className={props.cssClasses.tableCell}>
              No active subscription found.  Would you like to
              <Link
                onClick={() => setIsDialogOpen(!isDialogOpen)}
              >
                {' purchase one'}
              </Link>
              ?
            </Grid>
          </Grid>
        }

        <Grid container className={props.cssClasses.tableBody}>
          <Grid item xs={12} align="center" className={props.cssClasses.tableCell}>
            {/* {
              isLoading &&
              <CircularProgress color="secondary" />
            } */}
          </Grid>
        </Grid>

        <Grid container className={props.cssClasses.tableBody}>
          <Grid item xs={12} sm={9} align="right" className={props.cssClasses.tableCell} style={{padding:'16px'}}>
            <CustomerPortal
              {...props}
              isOpen={isSettingsOpen}
              handleClose={() => setIsSettingsOpen(!isSettingsOpen)}
            />
            <Button
              onClick={() => setIsSettingsOpen(!isSettingsOpen)}
              variant="contained"
            >
              Manage Subscriptions
            </Button>
          </Grid>
     
          <Grid item xs={12} sm={3} align="right" className={props.cssClasses.tableCell} style={{padding:'16px'}}>
            <PurchaseSubscriptionDialog
              {...props}
              isOpen={isDialogOpen}
              handleClose={() => setIsDialogOpen(!isDialogOpen)}
            />
            <Button
              onClick={() => setIsDialogOpen(!isDialogOpen)}
              variant="contained"
              color="primary"
            >
              Purchase Subscription
            </Button>
          </Grid>
        </Grid>

      </Grid>
    </Grid>
  );

}
