import React, { useState, useEffect } from 'react';
import LocalVideoPreview from '../PreJoinScreens/DeviceSelectionDialog/LocalVideoPreview/LocalVideoPreview';
import ToggleAudioButton from '../Buttons/ToggleAudioButton/ToggleAudioButton';
import ToggleVideoButton from '../Buttons/ToggleVideoButton/ToggleVideoButton';
import { useAppState } from '../../../state';
import useVideoContext from '../../../hooks/useVideoContext/useVideoContext';
import { useSnackbar } from 'notistack';
import useLocalAudioToggle from '../../../hooks/useLocalAudioToggle/useLocalAudioToggle';
import SquareCard from '../../Elements/SquareCard';
import AudioInputList from '../PreJoinScreens/DeviceSelectionDialog/AudioInputList/AudioInputList';
import AudioOutputList from '../PreJoinScreens/DeviceSelectionDialog/AudioOutputList/AudioOutputList';
import VideoInputList from '../PreJoinScreens/DeviceSelectionDialog/VideoInputList/VideoInputList';
import LinearProgress from '@material-ui/core/LinearProgress';

import {
  makeStyles,
  Typography,
  Grid,
  Button,
  Theme,
  Hidden,
  Dialog,
  DialogActions,
  DialogContent
} from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    width: '650px',
    [theme.breakpoints.down('sm')]: {
      width: 'auto'
    },
  },
  gutterBottom: {
    marginBottom: '1em',
  },
  marginTop: {
    marginTop: '1em',
  },
  deviceButton: {
    width: '100%',
    border: '2px solid #aaa',
    margin: '1em 0',
  },
  localPreviewContainer: {
    paddingRight: '2em',
    [theme.breakpoints.down('sm')]: {
      padding: '0 2.5em',
    },
  },
  joinButtons: {
    width:'150px',
    [theme.breakpoints.down('sm')]: {
      width: '100px'
    },
    display:'block',
    marginTop:'12px',
    marginBottom:'12px',
    marginLeft:'auto',
    marginRight:'auto',
    color: 'rgba(255,255,255,1)',
    backgroundColor: 'rgba(75,75,75,.85)',
    '&:hover': {
      backgroundColor: 'rgba(125,125,125,.85)',
    },
  },
  connectButton: {
    width:'150px',
    [theme.breakpoints.down('sm')]: {
      width: '100px'
    },
    display:'block',
    marginTop:'12px',
    marginBottom:'12px',
    marginLeft:'auto',
    marginRight:'auto',
  },
  mobileButtonBar: {
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      justifyContent: 'space-between',
      margin: '1.5em 0 1em',
    },
  },
  mobileButton: {
    padding: '0.8em 0',
    margin: 0,
  },
}));

interface DeviceChangeScreenProps {
  name: string;
  roomName: string;
  userUuid: string;
  isOpen: Boolean;
  setIsOpen: () => void;
}

export default function DeviceSelectionDialog({ name, roomName, userUuid, isOpen, setIsOpen }: DeviceChangeScreenProps) {
  const classes = useStyles();
  const { getToken, isFetching } = useAppState();
  const { connect, isAcquiringLocalTracks, isConnecting, isSharingScreen, toggleScreenShare, removeLocalAudioTrack, removeLocalVideoTrack } = useVideoContext();
  const disableButtons = isFetching || isAcquiringLocalTracks || isConnecting;
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [isEnabled, toggleAudioEnabled] = useLocalAudioToggle();

  const handleJoin = () => {
    getToken(name, userUuid, roomName).then(token => {

      if (token && token.authToken) {
        // Connect to room
        connect(token.authToken)

        // Close modal
        setIsOpen(false);

      } else {
        // Session is not active, notify user
        enqueueSnackbar(token.message, {variant:'warning'})

        // Close modal
        setIsOpen(false);

      }
    });
  };

  const handleCancel = () => {
    if (isSharingScreen) {
      toggleScreenShare();
    }
    removeLocalAudioTrack();
    removeLocalVideoTrack();
    setIsOpen(false);
    setStep(Steps.roomNameStep);
  }

  return (
    <Dialog
      open={isOpen}
      onClose={() => setIsOpen(!isOpen)}
      maxWidth="lg"
    >
      <DialogContent>
        <Grid container justify="center" className={classes.container}>

          <Grid item xs={12} style={{height: '24px', paddingBottom: '8px'}}>
            {
              disableButtons &&
              <LinearProgress
                variant="indeterminate"
              />
            }
          </Grid>

          <Grid item sm={12} md={3} style={{paddingTop: '20px'}}>
            <SquareCard border={false}>
              <LocalVideoPreview identity={name} />
            </SquareCard>
          </Grid>

          <Grid item xs={12} md={9}>
            <VideoInputList disableButtons={disableButtons} />
            <AudioInputList disableButtons={disableButtons} />
            <AudioOutputList />
          </Grid>

        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => setIsOpen(!isOpen)}
          variant="contained"
          color="primary"
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}
