import React, { useEffect, useState } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import {
Grid,
TextField,
Button,
Backdrop,
CircularProgress,
} from '@material-ui/core/';
import { useSnackbar } from 'notistack';
import useDashboardAccount from '../Context/useDashboardAccount';

const useStyles = makeStyles((theme) => createStyles({
  container: {
    padding: theme.spacing(2),
  }
}));

export default function CreateNewProject(props) {
  // Hooks
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const { 
    customer_uuid,
    getAccountData,
  } = useDashboardAccount();
  
  // State
  const [inProgress, setInProgress] = useState(false);
  const [projectName, setProjectName] = useState('');
  const [projectNameError, setProjectNameError] = useState(false);
  
  useEffect(() => {
    if (projectName !== '') {
      setProjectNameError(false);
    }
  }, [projectName])

  // Actions
  const createNewProject = async () => {
    // Don't allow empty names
    if (projectName === '') {
      enqueueSnackbar('Please name the new project ', {variant: 'warning'});
      setProjectNameError(true);
      return;
    }

    try {
      setInProgress(true);

      let fetchUrl = process.env.REACT_APP_HOST_URL + '/api/producer_create/' + customer_uuid
      let fetchData = {
        method: 'POST',
        headers: {
          'Content-Type':'application/json',
        },
        body: JSON.stringify({
          uuid: customer_uuid,
          project_name: projectName
        })
      }
      let res = await fetch(fetchUrl, fetchData);
      if (res.ok) {
        let response = await res.json();
        setInProgress(false);
        // Store response in state
        enqueueSnackbar(projectName + ' created', { variant: 'success' });
        setProjectName('');
        getAccountData();
      } else {
        setInProgress(false);
        enqueueSnackbar('Could not create project.  Please try again ' + res.status, { variant: 'warning' });
        console.error('Could not retrieve data ', res.status);
      }  
    } catch (error) {
      setInProgress(false);
      enqueueSnackbar('We encountered an issue ', + error.message, { variant: 'error' });
      console.error('Network error getting data ', error);
    }
  }

  // Render
  return (
    <Grid container className={classes.container}>
      <Grid item xs={12} className={props.cssClasses.tableBody}>

        <Grid container className={props.cssClasses.tableHeader}>
          <Grid item xs={12} className={props.cssClasses.tableCell}>
            Create New Project
          </Grid>
        </Grid>

        <Grid container className={props.cssClasses.tableRow}>
          <Grid item xs={12} sm={9} align="center" className={props.cssClasses.tableCell}>
            <TextField
              variant= 'outlined'
              fullWidth
              required
              id="ProjectName"
              label="Project Name"
              name="ProjectName"
              type="text"
              error={projectNameError}
              value={projectName}
              onChange = {(e) => setProjectName(e.target.value)}
            />
          </Grid>

          <Grid item xs={12} sm={3} align="center" className={props.cssClasses.tableCell} style={{margin:'auto'}}>
            <Button
              onClick={createNewProject}
              className={props.cssClasses.submitButton}
              variant="contained"
              color="primary"
            >
              Create New Project
            </Button>
          </Grid>
        </Grid>
      </Grid>

      <Backdrop open={inProgress} style={{zIndex:2001}}>
        <CircularProgress color="primary" variant="indeterminate" />
      </Backdrop>
    </Grid>
  );

}
