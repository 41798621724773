import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';

export const DashboardAccountContext = React.createContext({});

export function DashboardAccountProvider(props) {
  const { enqueueSnackbar } = useSnackbar();
  let parsedURL = window.location.pathname.split('/');
  let uuid;
  let customer_uuid;
  if (parsedURL.length > 2 && parsedURL[2] !== "") {
    uuid = parsedURL[2];
    customer_uuid = parsedURL[2];
  }
  const [brandName, setBrandName] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [subscriptions, setSubscriptions] = useState([]);
  const [projects, setProjects] = useState([]);
  const [archivedProjects, setArchivedProjects] = useState();
  const [passes, setPasses] = useState([]);
  const [availablePasses, setAvailablePasses] = useState();
  const [scheduledPasses, setScheduledPasses] = useState();
  const [activePasses, setActivePasses] = useState();
  const [expiredPasses, setExpiredPasses] = useState();
  const [isPartner, setIsPartner] = useState(false);

  useEffect(() => {
    getAccountData(uuid);
  }, []);

  const getAccountData = async () => {
    setIsLoading(true);

    try {
      let fetchUrl = process.env.REACT_APP_HOST_URL + '/api/producer/account/' + uuid
      let fetchData = {
        method: 'GET'
      }
      let res = await fetch(fetchUrl, fetchData);

      if (res.ok) {
        let response = await res.json();
        setIsLoading(false);

        // Store response in state
        setProjects(response.projects ? response.projects : []);
        setArchivedProjects(response.archivedProjects ? response.archivedProjects : []);
        setSubscriptions(response.subscriptions ? response.subscriptions : []);
        setAvailablePasses(response.availablePasses ? response.availablePasses : []);
        setScheduledPasses(response.scheduledPasses ? response.scheduledPasses : []);
        setActivePasses(response.activePasses ? response.activePasses : []);
        setExpiredPasses(response.expiredPasses ? response.expiredPasses : []);
        setIsPartner(response.isPartner ? response.isPartner : false);

      } else {
        setIsLoading(false);
        enqueueSnackbar('We had an issue loading your account.  Please refresh the page', { variant: 'warning' });
        console.error('Could not retrieve account ', res.status);
      }  
    } catch (error) {
      setIsLoading(false);
      console.error('Network error getting account ', error);
      enqueueSnackbar('We encountered a network error ' + error, { variant: 'error' });
    }
  }

  return (
    <DashboardAccountContext.Provider 
      value={{
        uuid:uuid,
        customer_uuid:uuid,
        brandName:brandName,
        subscriptions:subscriptions,
        projects:projects,
        archivedProjects:archivedProjects,
        passes:passes,
        availablePasses:availablePasses,
        scheduledPasses:scheduledPasses,
        activePasses:activePasses,
        expiredPasses:expiredPasses,
        isPartner:isPartner,
        isLoading:isLoading,
        getAccountData:getAccountData,
      }}
    >
      {props.children}
    </DashboardAccountContext.Provider>
  );
}
