import React, { useEffect, useState } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import {
  Grid,
  Table,
  TableHead,
  TableBody,
  TableFooter,
  TableRow,
  TableCell,
  Typography,
  Button,
  Link,
} from '@material-ui/core/';
import prettyKeys from '../../../utils/prettyKeys';
import { useSnackbar } from 'notistack';

const useStyles = makeStyles((theme) => createStyles({
  container: {
    padding: theme.spacing(6),
  },
  complete: {
    backgroundColor: theme.palette.primary.main,
  },
  errored: {
    backgroundColor: theme.palette.warning.main,
  },
  dense: {
    marginTop: 0,
    marginBottom: 0,
    paddingTop: 0,
    paddingBottom: 0,
    border: 'none',
  },
  selectButton: {
    whiteSpace: "nowrap",
    margin: theme.spacing(4),
    marginTop: 0,
  },
}));

export default function CompletedStep(props) {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  return (
    <Grid container className={classes.container}>
      <Grid item xs={12}>

        <Table>
          <TableHead
            className={
              props.actionData.actionText === 'Error' ?
              classes.errored : classes.completed
            }
          >
            <TableRow>
              {
                Object.keys(props.displayData).map(value => (
                  <TableCell align="center">
                    {prettyKeys(value)}
                  </TableCell>
                ))
              }
            </TableRow>
          </TableHead>

          <TableBody>
              {
                Object.values(props.displayData).map(pass => (
                  <TableRow>
                    {Object.values(pass).map(value => (
                        <TableCell align="center">
                          {value}
                        </TableCell>
                    ))}
                  </TableRow>
                ))
              }

            {
              props.actionData.checkoutUrl &&
              <TableRow>
                <TableCell align="center">
                  <Link
                    href={props.actionData.checkoutUrl}
                    target="_blank"
                    ariaLabel="Checkout Link"
                  >
                    {props.actionData.checkoutUrl}
                  </Link>
                </TableCell>
              </TableRow>
            }
          </TableBody>
        </Table>

      </Grid>
    </Grid>

  );

}
