import React, { useEffect, useState, useRef } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import {
Grid,
Menu,
MenuItem,
Dialog,
DialogTitle,
DialogContent,
DialogActions,
Typography,
TextField,
Button,
Link,
IconButton,
Paper,
Backdrop,
CircularProgress
} from '@material-ui/core/';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever'
import EditIcon from '@material-ui/icons/Edit';
import BackspaceIcon from '@material-ui/icons/Backspace';
import SettingsIcon from '@material-ui/icons/Settings';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import { useSnackbar } from 'notistack';
import useDashboardAccount from '../Context/useDashboardAccount';

const useStyles = makeStyles((theme) => createStyles({
  container: {
    padding: theme.spacing(2),
  },
  archived: {
    opacity:'.5',
    textDecoration: 'line-through'
  }
}));

export default function ProjectListItem(props) {
  const classes = useStyles();
  const anchorEl = useRef();
  const { enqueueSnackbar } = useSnackbar();
  const { getAccountData, customer_uuid } = useDashboardAccount();

  // State
  // Display / hide
  const [inProgress, setInProgress] = useState(false);
  const [showSettings, setshowSettings] = useState(false);
  const [showDialog, setShowDialog] = useState(false);

  // Project Data
  const [newProjectName, setNewProjectName] = useState();
  const [projectAction, setProjectAction] = useState();

  const do_setting = async (e, proj_id) => {
    e.preventDefault();
    setInProgress(true);

    let fetchData = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        project_id: proj_id,
        new_project_name: newProjectName
      })
    }
    try {
      let res = await fetch(
        process.env.REACT_APP_HOST_URL + '/api/producer_update_session/restore_project/' + customer_uuid,
        fetchData
      )
      let response = await res.json();
      if (res.ok) {
        // Update List
        getAccountData();
        enqueueSnackbar('Project Restored');
      } else {
        console.error('Error ', response.message);
      }

    } catch (e) {
      console.error('Could not update setting ', e);
    } finally {
      setInProgress(false);
      setShowDialog(false);
      setshowSettings(false);
    }
  }

  return (
    <Grid container className={classes.container}>
      <Grid item xs={1} align="center" className={props.cssClasses.tableCell}>

        {/* Settings */}
        <IconButton
          onClick={e => setshowSettings(true)}
          ref={anchorEl}
        >
          <SettingsIcon />
        </IconButton>
        <Menu
          id={props.project._id + "-settings"}
          anchorEl = {anchorEl.current}
          keepMounted
          open={showSettings}
          onClose={e => setshowSettings(false)}
          PaperProps={{
            className:props.cssClasses.popoverMenu,
            style: {
              minWidth: '45ch',
            },
          }}
          MenuListProps={{
            style: {

            }
          }}
        >
          <Grid container align="center" className={props.cssClasses.tableHeader}>
            {props.project.project_name} (ID: {props.project._id})
          </Grid>

          <Grid container className={props.cssClasses.settingsContainer}>

              <Grid item xs={12}>
                <MenuItem
                  onClick={e => setShowDialog(true)}
                >
                  <Grid container>
                    <Grid item style={{paddingRight: '8px'}}>
                      <EditIcon />
                    </Grid>
                    <Grid item>
                      Restore Project
                    </Grid>
                  </Grid>
                </MenuItem>

                <Dialog
                  open={showDialog}
                  onClose={e => setShowDialog(false)}
                >
                  <DialogTitle className={props.cssClasses.dtitle}>
                    <Typography>
                      Restore Project
                    </Typography>
                  </DialogTitle>

                  <DialogContent className={props.cssClasses.dcontent}>
                    <Paper elevation={0} className={props.cssClasses.padding}>
                      <Typography color="black">
                        Would you like to restore access to {props.project.project_name}
                      </Typography>
                    </Paper>
                  </DialogContent>

                  <DialogActions className={props.cssClasses.dactions}>
                    <Button
                      id = 'cancel'
                      onClick = {e => setShowDialog(false)}
                      variant="contained"
                      className={props.cssClasses.selectButton}
                    >
                      Cancel
                    </Button>
                    <Button
                      id = {props.project._id}
                      onClick = {e => do_setting(e, props.project._id)}
                      variant="contained"
                      color="primary"
                      className={props.cssClasses.selectButton}
                    >
                      Confirm
                    </Button>
                  </DialogActions>
                </Dialog>
              </Grid>

          </Grid>
        </Menu>
      </Grid>

      <Grid item xs={3} className={props.cssClasses.tableCell}>
        <Typography className={classes.archived}>
          {props.project.project_name}
        </Typography>
      </Grid>

      <Grid item xs={6} className={props.cssClasses.tableCell}>
      </Grid>

      <Grid item xs={2}>

      </Grid>

      <Backdrop open={inProgress} style={{zIndex: '2001'}}>
        <CircularProgress color="primary" variant="indeterminate" />
      </Backdrop>
    </Grid>
  );

}
