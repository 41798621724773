import React, { useEffect, useState } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import {
Grid,
Hidden,
Typography,
FormControl,
FormControlLabel,
Switch,
Input,
InputLabel,
Select,
MenuItem,
Button,
Checkbox
} from '@material-ui/core/';
import { useSnackbar } from 'notistack';

import useCamera from '../../CameraProvider/useCamera'
import logToServer from '../../../../../utils/log_to_server';

const useStyles = makeStyles((theme) => createStyles({
  container: {
    padding: theme.spacing(2),
    border: '2px solid ' + theme.palette.secondary.dark,
  }
}));

interface Props {

}

export default function AudioControls(props: Props) {
  // Hooks
  const {
    enabled,
    label,
    audioInputDevices,
    audioDeviceId,
    updateAudioDeviceId,
    audioChannelTypes,
    audioChannels,
    updateAudioChannels,
    echoCancellation,
    updateEchoCancellation,
    noiseSuppression,
    updateNoiseSuppression,
    rtcPeerConnection,
    cssClasses
  } = useCamera();
  const classes = useStyles();
  const {enqueueSnackbar} = useSnackbar();

  // State

  // Effects

  // Actions
  const changeAudioDeviceId = (id) => {
    if (id == "none") {
      updateAudioDeviceId(id);
      return
    }
    
    let device = audioInputDevices?.find(ad => ad?.deviceId === id);

    if (
      id &&
      device
    ) {
      updateAudioDeviceId(id);
    } else {
      console.log('Could not find audio device to update to ', id);
      enqueueSnackbar('Could not find selected audio device', { variant: 'warning' });
      logToServer({
        page: window.location.href,
        action: 'Set Audio Device ID',
        error: 'No ID or could not find audio device in devices list',
        deviceId: id
      })
    }
  }

  // Render
  return (
    <Grid container className={classes.container}>
      <Grid item xs={12}>

        <Grid item className={cssClasses.select}>
          <Select
            id = 'audioInputDevices'
            label = 'Audio Input'
            value = {audioDeviceId}
            fullWidth
            variant="outlined"
            onChange = {adid => changeAudioDeviceId(adid.target.value)}
          >
            <MenuItem value={"none"} key={"MENNONE"}>
              NO AUDIO
            </MenuItem>
            {
              audioInputDevices.map((adev, i) => (
                <MenuItem value={adev.deviceId} key={'MEN'+i}>
                  {adev.label}
                </MenuItem>
              ))
            }
          </Select>
        </Grid>

        <Grid item className={cssClasses.select}>
          <Select
            id = 'audioChannels'
            label = 'Channels'
            value = {audioChannels}
            fullWidth
            variant="outlined"
            onChange = {ac => updateAudioChannels(ac.target.value)}
          >
            {
              audioChannelTypes.map((ch, i) => (
                <MenuItem value={ch.id} key={'SAC'+i}>{ch.label}</MenuItem>
              ))
            }
          </Select>
        </Grid>

        <Grid item xs={6} className={cssClasses.select}>
          <FormControlLabel
            label="Echo Cancellation"
            labelPlacement="end"
            control={
              <Switch
                checked={echoCancellation}
                id="echoCancellation"
                color="secondary"
                onChange={() => updateEchoCancellation(!echoCancellation)}
              />
            }
          />
        </Grid>

        <Grid item xs={6} className={cssClasses.select}>
          <FormControlLabel
            label="Noise Suppression"
            labelPlacement="end"
            control={
              <Switch
                checked={noiseSuppression}
                id="noiseSuppression"
                color="secondary"
                onChange={() => updateNoiseSuppression(!noiseSuppression)}
              />
            }
          />
        </Grid>

      </Grid>
    </Grid>
  );

}
