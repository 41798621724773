import React, { useEffect, useState } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import {
  Grid,
  Table,
  TableHead,
  TableBody,
  TableFooter,
  TableRow,
  TableCell,
  Typography,
  Button,
  Link,
  Hidden,
} from '@material-ui/core/';
import prettyKeys from '../../../utils/prettyKeys';
import { useSnackbar } from 'notistack';
import { isMobile } from '../../../utils';

const useStyles = makeStyles((theme) => createStyles({
  container: {
    padding: theme.spacing(6),
  },
  title: {
    backgroundColor: theme.palette.secondary.dark,
  },
  dense: {
    marginTop: 0,
    marginBottom: 0,
    paddingTop: 0,
    paddingBottom: 0,
    border: 'none',
  },
  selectButton: {
    whiteSpace: "nowrap",
    margin: theme.spacing(4),
    marginTop: 0,
  },
}));

export default function ConfirmationStep(props) {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const handleConfirm = async () => {
    try {
      props.setInProgress(true);
      let fetchData = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(props.actionData.postData)
      }

      let response = await fetch(process.env.REACT_APP_HOST_URL + props.actionData.url + props.uuid, fetchData);

      props.setInProgress(false);
      let result = await response.json();

      if (response.ok && result.type.toLowerCase() === 'success') {
        // Handle new orders or completed update
        if (result.checkout_url) {
          // New order created
          // Open popup with order payment url
          let checkoutWindow = window.open(result.checkout_url);
          if (!checkoutWindow) {
            // Set to error step
            props.changeStep(
              'Error',
              {
                error: {
                  0: `We could not open a new window to complete the checkout.  It is possible a pop-up blocker prevented us from opening a new page to complete the checkout process.
                Please follow the link below to checkout and finish adding users or time to your session:`,
              }
              },
              {
                actionText: 'Error',
                checkoutUrl:result.checkout_url,
              },
              false
            );
          } else {
            // Set to complete step
            props.changeStep(
              'Complete',
              {
                complete: {
                  0: `A new window has been opened to complete your order.  It may take a minute or two to process your order before the additional viewers or time are applied automatically.

                  You may close this window now.`,
                }
              },
              {
                actionText: 'Complete'
              },
              false
            )

            // setIsComplete(true);
          }

        } else {
          // Access modified
          enqueueSnackbar('Thank you for ' + props.actionData.actionText, { variant: 'info' });
          props.handleClose();
        }


      } else {
        throw new Error('failed - ' + result.type + ' - ' + result.message);

      }
    } catch (err) {
      props.setInProgress(false);
      props.handleClose();
      console.error('Error - Could not ' + props.actionData.actionText, err);
      enqueueSnackbar('We encountered an error ' + props.actionData.actionText + ' - ' + err, { variant: 'error' });
    };
  }

  const renderMobileTable = () => {
    let table = [];
    for (const [id, data] of Object.entries(props.displayData)) {
      for (const [key, value] of Object.entries(data)) {
        table.push(
          <Grid container className={props.cssClasses.tableHeader}>
            <Grid item xs={12} align="center" className={props.cssClasses.tableCell}>
              {prettyKeys(key)}
            </Grid>
          </Grid>
        )
        table.push(
          <Grid container className={props.cssClasses.tableBody}>
            <Grid item xs={12} align="center" className={props.cssClasses.tableCell}>
              {value}
            </Grid>
          </Grid>
        )
      }
    }
    return table;
  }

  return (
    <Grid container className={classes.container}>
      <Grid item xs={12}>

        <Hidden smDown>
          <Grid container className={props.cssClasses.tableHeader}>
            {
              Object.keys(props.displayData[Object.keys(props.displayData)[0]]).map(value => (
                <Grid item xs={2} align="center" className={props.cssClasses.tableCell}>
                  {prettyKeys(value)}
                </Grid>
              ))
            }
          </Grid>

          <Grid container className={props.cssClasses.tableBody}>
            {
              Object.values(props.displayData).map(pass => (
                  Object.values(pass).map(value => (
                      <Grid item xs={2} align="center" className={props.cssClasses.tableCell}>
                        {value}
                      </Grid>
                  ))
              ))
            }
          </Grid>
        </Hidden>

        <Hidden mdUp>
          {renderMobileTable()}
        </Hidden>

        <Grid container className={props.cssClasses.tableFooter}>

          <Grid item xs={12} align="right" className={props.cssClasses.tableCell}>
            <Button
              id = 'Confirm'
              onClick = {handleConfirm}
              variant="contained"
              color="primary"
              className={classes.selectButton}
            >
              Confirm
            </Button>
          </Grid>
        </Grid>

        <Grid item xs={12} align="right">
          By proceeding you agree to our
          <Link href="https://setstream.io/terms-and-conditions/" target="_blank">
            {" Terms"}
          </Link>

        </Grid>

      </Grid>
    </Grid>

  );

}
