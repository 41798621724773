import React from 'react';
import { Paper, Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import formatTimeStamp from '../../utils/formatTimeElapsedShort';
import Linkify from 'linkify-react';

const useStyles = makeStyles((theme) => ({
  remoteMetaData: {
    fontSize: '.6rem',
    color: theme.palette.grey[900],
  },
  remoteMessage: {
    backgroundColor: theme.palette.grey[200],
    opacity: .9,
    color: theme.palette.common.black,
    margin: theme.spacing(3, 32, 3, 0),
    padding: theme.spacing(2, 6),
    fontSize: '.7rem',
    borderRadius: '0 18px 18px 18px',
    wordWrap: 'break-word'
  },
  localMetaData: {
    fontSize: '.6rem',
    color: theme.palette.text.secondary,
  },
  localMessage: {
    backgroundColor: theme.palette.secondary.dark,
    opacity: .9,
    color: theme.palette.text.primary,
    margin: theme.spacing(3, 0, 3, 32),
    padding: theme.spacing(2, 6),
    fontSize: '.7rem',
    borderRadius: '18px 0 18px 18px',
    wordWrap: 'break-word'
  },
}));



export default function ChatBubble(props) {
  const classes = useStyles();

  return(
    <Grid cols={1}>
      <Grid item xs={12}>

        <Paper
          className={props.isLocal ? classes.localMessage : classes.remoteMessage}
        >
          <Grid container>

            <Grid item xs={10}>

              <Typography
                variant='body1'
                align='left'
              >
                <Linkify options={{target:'_blank', rel:'noreferrer'}}>
                  {props.message.message}
                </Linkify>
              </Typography>
            </Grid>
          </Grid>

          <Grid container>

            <Grid item xs={9}>
              <Typography
                variant='subtitle2'
                align='left'
                className={props.isLocal ? classes.localMetaData : classes.remoteMetaData}
              >
                {props.message.nickName}
              </Typography>

            </Grid>

            <Grid item xs={3}>

              <Typography
                variant='subtitle2'
                align='right'
                className={props.isLocal ? classes.localMetaData : classes.remoteMetaData}
              >
              {formatTimeStamp(props.message.timestamp)}
              </Typography>
            </Grid>
          </Grid>

        </Paper>
      </Grid>
    </Grid>
  )
}
