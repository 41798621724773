import React, { useEffect, useState } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import {
Grid,
Hidden,
Typography,
FormControl,
FormControlLabel,
Input,
InputLabel,
Select,
MenuItem,
Button,
Checkbox
} from '@material-ui/core/';
import { useSnackbar } from 'notistack';

import useCamera from '../../CameraProvider/useCamera'

const useStyles = makeStyles((theme) => createStyles({
  container: {
    padding: theme.spacing(2),
    border: '2px solid ' + theme.palette.secondary.dark,
  }
}));

interface Props {

}

export default function VideoControls(props: Props) {
  // Hooks
  const {
    enabled,
    label,
    videoInputDevices,
    videoDeviceId,
    updateVideoDeviceId,
    videoCodecs,
    videoCodec,
    updateVideoCodec,
    videoResolutions,
    videoWidth,
    updateVideoWidth,
    videoBitrates,
    videoBitrate,
    updateVideoBitrate,
    rtcPeerConnection,
    cssClasses
  } = useCamera();
  const classes = useStyles();
  const {enqueueSnackbar} = useSnackbar();

  // State

  // Effects

  // Actions
  const changeVideoDeviceId = (id) => {
    if (id === "none") {
      updateVideoDeviceId(id);
      return
    }

    let device = videoInputDevices?.find(vd => vd?.deviceId === id);

    if (
      id &&
      device
    ) {
      updateVideoDeviceId(id);
    } else {
      console.log('Could not find video device to update to ', id);
      enqueueSnackbar('Could not find selected video device', { variant: 'warning' });
    }
  }

  // Render
  return (
    <Grid container className={classes.container}>
      <Grid item xs={12}>

      <Grid item className={cssClasses.select}>
        <Select
          id = 'videoInputDevices'
          labelId = 'videoInputDevices'
          label = 'Video Devices'
          value = {videoDeviceId}
          fullWidth
          variant="outlined"
          onChange = {vi => changeVideoDeviceId(vi.target.value)}
        >
        {
          videoInputDevices?.map((vdev, i) => (
            vdev.deviceId !== "" ?
            (<MenuItem value={vdev.deviceId} key={'VDEV'+i}>
              {vdev.label}
            </MenuItem>) :
            (
              "Device Not Found"
            )
          ))
        }
        </Select>
      </Grid>

      <Grid item className={cssClasses.select}>
      <Select
      id = 'allowedCodecs'
      labelId = 'bitRate'
      label = 'Codec'
      value = {videoCodec}
      fullWidth
      variant="outlined"
      onChange = {fr => updateVideoCodec(fr.target.value)}
      >
      {
        videoCodecs?.map((vc, i) => (
          <MenuItem value={vc.codec} key={'VC'+i}>{vc.label}</MenuItem>
        ))
      }
      </Select>
      </Grid>

      <Grid item className={cssClasses.select}>
        <Select
          id = 'resolution'
          labelId = 'resolution'
          label = 'Resolution'
          value = {videoWidth}
          variant="outlined"
          fullWidth
          onChange = {fr => updateVideoWidth(fr.target.value)}
        >
          {
            videoResolutions?.map((vr, i) => (
              <MenuItem value={vr.width} key={'VR'+i}>{vr.label}</MenuItem>
            ))
          }
        </Select>
      </Grid>

      <Grid item className={cssClasses.select}>
        <Select
          id = 'bitrate'
          labelId = 'bitrate'
          label = 'Bitrate'
          value = {videoBitrate}
          variant="outlined"
          fullWidth
          onChange = {br => updateVideoBitrate(br.target.value)}
        >
        {
          videoBitrates?.map((br, i) => (
            <MenuItem value={br.bitrate} key={'SQM'+i}>{br.label}</MenuItem>
          ))
        }
        </Select>
      </Grid>

      </Grid>
    </Grid>
  );

}
