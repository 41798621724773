import React, { useEffect, useState } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import {
Grid,
Dialog,
DialogTitle,
DialogContent,
DialogActions,
Button,
Typography,
Backdrop,
CircularProgress,
} from '@material-ui/core/';
import { useSnackbar } from 'notistack';
import useDashboardAccount from '../Dashboard/Context/useDashboardAccount';

const useStyles = makeStyles((theme) => createStyles({
  container: {
    padding: theme.spacing(2),
  }
}));

export default function CustomerPortal(props) {
  //  Hooks
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const {
    customer_uuid
  } = useDashboardAccount();
  
  // State
  const [inProgress, setInProgress] = useState(false);
  const [popUpBlocked, setPopUpBlocked] = useState(false);
  const [portalUrl, setPortalUrl] = useState();

  const handleClose = () => {
    props.handleClose();
  }

  const getCustomerPortal = async () => {
    setInProgress(true);
    try {
      let fetchURL = process.env.REACT_APP_HOST_URL + '/api/stripe/portal/' + customer_uuid
      let fetchData = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          return_url: process.env.REACT_APP_PARENT_HOST_URL + '/my-account/projects/',
        })
      }
      let res = await fetch(fetchURL, fetchData);
      if (res.ok) {
        let response = await res.json();
        setPortalUrl(response.sessionURL);
        setInProgress(false);
        let open = window.open(response.sessionURL, {target: '_blank'});
        if (!open) {
          console.log('Could not open window ', open);
          setPopUpBlocked(true);
        } else {
          handleClose();
        }
      } else {
        console.error('Could not create portal session ', res.status);
        enqueueSnackbar('We encountered an issue.  Please try again or contact support to complete your transaction.', { variant: 'warning'})
        setInProgress(false);
      }
    } catch (error) {
      console.error('Network error creating portal ', error);
      enqueueSnackbar('We encountered an issue.  Please try again or contact support to complete your transaction.', { variant: 'warning'})
      setInProgress(false);
    }
  }

  return (
    <Dialog
      open={props.isOpen}
      onClose={handleClose}
    >
      <DialogTitle
        className={props.cssClasses.dtitle}
      >
        <Typography
          variant='h5'
        >
          Subscription Settings
        </Typography>
      </DialogTitle>

      <DialogContent>
        <Grid container className={props.cssClasses.settingsContainer}>
          <Grid item xs={12} align="center">
            <Button
              onClick={getCustomerPortal}
              color="primary"
              disabled={(inProgress || popUpBlocked)}
            >
              Modify Subscription
            </Button>
          </Grid>
          {
            !popUpBlocked &&
            <Grid item xs={12} align="left">
              <Typography>
                This will redirect you to a Stripe hosted account page where you can manage your subscription.
              </Typography>
              <Typography>
                You can return to this page when you are finished.
              </Typography>
            </Grid>
          }
          {
            popUpBlocked &&
            <Grid item xs={12} align="left">
              <Typography>
                Looks like the pop up window was blocked.  
              </Typography>
              <Button
                onClick={() => {window.open(portalUrl, {target:"_blank"})}}
              >
                Try Again
              </Button>
              <Typography>
                Or copy this link and paste in a new window:
              </Typography>
              <Typography style={{wordBreak:'break-all', padding:'12px'}}>
                {portalUrl}
              </Typography>
            </Grid>
          }
          {
            inProgress &&
            <Grid item xs={12} align="center">
              <CircularProgress color="primary" variant="indeterminate" />
            </Grid>
          }
        </Grid>
      </DialogContent>

      <DialogActions>
        <Button
          id = 'cancel'
          onClick = {handleClose}
          variant="contained"
          disabled={inProgress}
        >
          Cancel
        </Button>
      </DialogActions>

    </Dialog>
  );

}
