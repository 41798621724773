import React, { useRef } from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import {
Grid,
} from '@material-ui/core/';
// import { Sketch } from './sketches_types';
import SketchesControls from './Controlls/sketches_controls';
import SketchesCanvasLocal from './Canvas/sketches_canvas_local';
import SketchesCanvasRemote from './Canvas/sketches_canvas_remote';
import SketchesMarkerLocal from './Marker/sketches_marker_local';
import SketchesMarkerRemote from './Marker/sketches_marker_remote';
import Pusher, { Channel, PresenceChannel, Members } from 'pusher-js';
import ComponentErrorBoundary from '../../errors/component_error_boundary';
import SketchesProvider from './Context/SketchesProvider';

interface Props {
  enabled: boolean,
  disable: () => void,
  enable: () => void,
  cam_index: number,
  viewerUuid: string,
  room: string,
  pusher: Pusher,
  presenceRoom: PresenceChannel | undefined,
  members: Members | undefined,
  viewer_color: string,
  selectedCam: Number,
  showControls: boolean,
  // displayEl: React.RefObject<HTMLElement>,
}

const useStyles = makeStyles((theme) => createStyles({
  container: {
    position: 'absolute',
    top: 0,
    left: 0,
    height: '100%',
    // [theme.breakpoints.up('sm')]: {
    //   '&:hover > div': {
    //     visibility: 'visible'
    //   },
    // }
  },
  controls: {
    zIndex: 20
  },
  canvas: {
    zIndex: 19,
    pointerEvents: 'none',
  }
}));

export default function Sketches(props: Props) {
  // Hooks
  const classes = useStyles();

  // Refs
  const sbContainerRef = useRef<HTMLDivElement>(null);

  // Render
  return (
    <ComponentErrorBoundary
      section='Sketches'
      showErrors={true}
      errorHandler={props.disable}
      // retryHandler={props.enable}
    >
      {
        props.enabled &&
        <SketchesProvider
          {...props}
          displayEl={sbContainerRef}
        >
          <Grid
            container
            ref={sbContainerRef}
            className={classes.container}
          >
            <SketchesControls
              className={classes.controls}
              selectedCam={props.selectedCam}
              showControls={props.showControls}
            />
            <SketchesCanvasLocal
              className={classes.canvas}
            />
            <SketchesCanvasRemote
              className={classes.canvas}
            />
            <SketchesMarkerLocal
              className={classes.canvas}
              showControls={props.showControls}
            />
            <SketchesMarkerRemote
              className={classes.canvas}
            />
          </Grid>
        </SketchesProvider>
      }
    </ComponentErrorBoundary>
  );
}
