import React, { useEffect, useState, useRef } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import {
Grid,
Hidden,
Typography,
Backdrop,
CircularProgress,
} from '@material-ui/core/';
import { useSnackbar } from 'notistack';
import useWindowSize from '../../../hooks/useWindowSize';
import clearCanvas from '../Utils/clearCanvas';
import drawSketchHistory from '../Utils/drawSketchHistory';
import resizeElement from '../Utils/resizeElement';
import setupCanvas from '../Utils/setupCanvas';
import { Sketch, mEvent } from '../sketches_types';
import useSketches from '../Context/useSketches';
import dayjs from 'dayjs';

const useStyles = makeStyles((theme) => createStyles({
  container: {
    position: 'relative',
    width: '100%',
    height: '100%'
  },
  canvas: {
    width: '100%',
    height: '100%',
    position: 'absolute',
    top: 0,
    left: 0,
    touchAction: 'none',
    pointerEvents: 'none',
  }
}));

export default function SketchesCanvasRemote(props:any) {
  // Hooks
  const classes = useStyles();
  const {
    sketchHistory,
    canvasRefRemote,
    sbPusher,
    members,
    updateMembers,
    viewerUuid,
    cam_index,
    mergeSketches,
  } = useSketches();

  // State
  const [canvasContext, setCanvasContext] = useState<CanvasRenderingContext2D | null>();

  // Workaround handler for pusher bindings to get latest history
  const [toReDraw, setToReDraw] = useState<boolean>(false);

  useEffect(() => {
    if (toReDraw && canvasContext) {
      drawSketchHistory(canvasContext, sketchHistory, viewerUuid, 'drawing', [viewerUuid], members);
      setToReDraw(false);
    }
  }, [toReDraw]);

  // Draw the history
  useEffect(() => {
    if (canvasContext && sketchHistory) {
      drawSketchHistory(canvasContext, sketchHistory, viewerUuid, 'remote', [], members);
    }
  }, [canvasContext, sketchHistory, updateMembers]);

  useEffect(() => {
    if (!canvasContext && canvasRefRemote?.current?.getContext) {
      setCanvasContext(canvasRefRemote.current.getContext('2d'));
    }
  }, [canvasRefRemote]);

  // Update canvas pixel count
  useEffect(() => {
    if (canvasContext?.canvas) {
      // Size the canvas
      resizeElement(canvasContext.canvas);
    }
  }, [canvasContext]);

  useEffect(() => {
    if (sbPusher) {

      // Clear path when a remote drawing is being replaced
      sbPusher.bind('client-sb_new_drawing', (message:string) => {
        if (message !== viewerUuid) {
          clearCanvas(canvasContext as CanvasRenderingContext2D);
          setToReDraw(message === 'true' ? true : false);
        }
      });

      // Draw paths
      sbPusher.bind('client-sb_drawing', (message:Sketch) => {
        mergeSketches(
          [message], 
          true,
          dayjs()
        );
      });
    }
  }, [sbPusher]);

  // Render
  return (
    <canvas
      id={"sbr_" + cam_index}
      ref={canvasRefRemote}
      className={classes.canvas}
      width="1920"
      height="1080"
      style={{
        zIndex: 15
      }}
    >
    </canvas>
  );

}
