import React, { useEffect, useState } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import {
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Typography,
  Button,
  Backdrop,
  CircularProgress,
  Divider,
} from '@material-ui/core/';
import PurchasePass from '../../../Passes/Steps/purchase_pass_step';
import ConfirmationStep from '../../../Passes/Steps/confirmation_step';
import useDashboardAccount from '../../Context/useDashboardAccount';

const useStyles = makeStyles((theme) => createStyles({
  selectButton: {
    textAlign: "center",
    whiteSpace: "nowrap",
    padding: theme.spacing(2),
  },
  tab: {
    borderColor: theme.palette.primary.main,
    borderBottom: 'solid 4px',
    borderRadius: '8px',
  }
}));

export default function DashboardActivatePassDialog(props) {
  const classes = useStyles();
  const [inProgress, setInProgress] = useState(false);
  const [displayData, setDisplayData] = useState();
  const [actionData, setActionData] = useState();
  const [allowConfirm, setAllowConfirm] = useState(false);
  const {
    customer_uuid
  } = useDashboardAccount();

  // Effects
  useEffect(() => {
    if (props.actionData) {
      setActionData(props.actionData)
    }
    if (props.displayData) {
      setDisplayData(props.displayData)
    }
  }, [props.actionData, props.displayData])

  useEffect(() => {
    if (props.sessionId && props.pass_id) {
      setAllowConfirm(true);
    } else {
      setAllowConfirm(false);
    }
  }, [props.sessionId, props.pass_id])

  // Close the modal
  const handleClose = () => {
    props.handleClose();
  }

  // Set the 'Confirm' step to verify purchase
  const changeStep = (step, displayData, actionData, toReset) => {
    if(step.toLowerCase() === 'confirm') {
      setActionData(actionData);
      setDisplayData(displayData);
      setAllowConfirm(true);
    } else if (step.toLowerCase() === 'Complete') {
      setAllowConfirm(false);
    }
  }

  const getData = async () => {
    try {
      let fetchUrl = process.env.REACT_APP_HOST_URL + '/api/' + customer_uuid
      let fetchData = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: {

        }
      }
      let res = await fetch(fetchUrl, fetchData);
      if (res.ok) {
        let response = await res.json();
        setInProgress(false);
        // Store response in state

      } else {
        console.error('Could not retrieve data ', res.status);
      }  
    } catch (error) {
      console.error('Network error getting data ', error);
    }
  }

  return (
    <Dialog
      open={props.isOpen}
      onClose={handleClose}
      maxWidth={false}
      fullWidth
    >

      <DialogTitle
        className={props.cssClasses.dtitle}
      >
        Activate a Pass
      </DialogTitle>

      <DialogContent
        className={props.cssClasses.dcontent}
      >
        <Grid className={props.cssClasses.tableBody}>
          {
            actionData &&
            displayData &&
            <ConfirmationStep
              {...props}
              actionData={actionData}
              displayData={displayData}
              setInProgress={setInProgress}
              handleClose={handleClose}
              changeStep={changeStep}
            />
          }
        </Grid>
      </DialogContent>

      <DialogActions
        className={props.cssClasses.dactions}
      >
        <Button
          id = 'Close'
          onClick = {handleClose}
          variant="contained"
        >
          Cancel
        </Button>
      </DialogActions>

      <Backdrop open={inProgress} style={{zIndex:2001}}>
        <CircularProgress color="primary" variant="indeterminate" />
      </Backdrop>

    </Dialog>
  );

}
