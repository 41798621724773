import React from 'react';
import { makeStyles } from '@material-ui/core/styles';


// ************** APP ****************
export default function useCustomCSS() {
  const useStyles = makeStyles((theme) => ({
    '@global': {
      '*': {
        scrollbarWidth: '.7em'
      },
      '*::-webkit-scrollbar': {
        // background: 'rgba(220,220,220,.9)',
        backgroundColor: theme.palette.background.light,
        width: '.7em',
        height: '.7em',
        borderRadius: '4px',
      },
      '*::-webkit-scrollbar-track': {
        // background: 'rgba(220,220,220,.9)',
        backgroundColor: theme.palette.background.light,
        borderRadius: '4px',
      },
      '*::-webkit-scrollbar-thumb': {
        // background: '#a1a1a1',
        backgroundColor: theme.palette.shades.light,
        borderRadius: '4px',
        width: '70%',
        height: '70%',
        margin: 'auto',
      },
    },
    loginButton: {
      // color: theme.palette.text.primary,
      // position: 'fixed',
      // top: '6px',
      // right: '12px',
      zIndex: 2001,
      width: '26px',
      padding:'3px',
      minWidth:'26px',
      height:'32px',
      marginRight:'4px'
    },
    appBar: {
      backgroundColor: theme.palette.background.paper,
      color: theme.palette.common.white,
      zIndex: 999,
      marginBottom: theme.spacing(2),
    },
    logo: {
      maxWidth: '36px',
      maxHeight: '36px',
      position: 'fixed',
      [theme.breakpoints.down('sm')]: {
        position: 'relative'
      },
      top: theme.spacing(2),
      left: theme.spacing(2)
    },
    title: {
      // position: 'fixed',
      // top: '8px',
      // left: '36px'
      paddingLeft: '44px'
    },
    root: {
    },
    video: {
      backgroundColor: theme.palette.background.default,
      paddingTop: '55.4%',
      position: 'relative'
    },
    dirVideo: {
      backgroundColor: theme.palette.background.default,
      position: 'relative',
      width: '100%',
      maxWidth: 'calc((100vh - 104px) / (9 / 16))',
      height: 'calc(100vw * (9 / 16))',
      maxHeight: 'calc(100vh - 104px)',
      marginLeft: 'auto',
      marginRight: 'auto',
      marginTop: 0,
      marginBottom: 0,
    },
    videoPlayer: {
      backgroundColor: theme.palette.background.default,
      border: 0,
      overflow: 'auto',
      resize: 'both',
      width: '100%',
      position: 'absolute',
      top: 0
    },
    paper: {
      display: 'flex',
      flex: 1,
      flexDirection: 'column',
      alignItems: 'center',
    },
    titleBar: {
      backgroundColor: theme.palette.background.paper,
      borderRadius: '4px',
      width: '100%'
    },
    titleBarHighlight: {
      backgroundColor: theme.palette.background.paper,
      borderRadius: '4px',
      width: '100%',
      color: 'black'
    },
    projectInfo: {
      margin: '4px',
      padding: '4px',
      borderRadius: '4px',
      fontSize: '.7rem',
      flexGrow: 1
    },
    icon: {
      color: theme.palette.primary.main,
    },
    tableContainer: {
      maxHeight: 'calc(100vh - 270px)',
    },
    tableHeader: {
      backgroundColor: theme.palette.secondary.main,
      padding: theme.spacing(2),
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(4),
      borderRadius: theme.spacing(2)
    },
    tableBody: {
      width: '100%',
      background: theme.palette.background.paper,
    },
    tableRow: {
      width: '100%',
      borderBottom: '1px solid ' + theme.palette.background.light,
      padding: theme.spacing(4),
    },
    tableRowHighlighted: {
      width: '100%',
      border: '1px solid ' + theme.palette.warning.main,
      marginBottom: theme.spacing(12),
      padding: theme.spacing(4),
    },
    tableCell: {
      margin: 'auto',
      whiteSpace: 'normal'
      // whiteSpace: 'nowrap'
    },
    tableText: {
      wordBreak: 'break-all',
      minWidth: '100px'
    },
    tableFooter: {
      marginTop: theme.spacing(8),
      paddingTop: theme.spacing(8),
      borderTop: '1px solid ' + theme.palette.background.light
    },
    expansion: {
      borderRadius: '4px',
      margin: '8px',
      padding: '2px',
    },
    sectionTitle: {
      width: '100%',
      maxWidth: '100%',
      padding: '4px',
      marginBottom: '8px',
      backgroundColor: theme.palette.secondary.main,
      borderRadius: '4px',
    },
    sectionTitleHeadline: {
      color: theme.palette.secondary.main
    },
    avatar: {
      margin: 'auto',
      backgroundColor: theme.palette.primary.main,
    },
    goLive: {
      paddingLeft: '4px'
    },
    deleteButton: {
      backgroundColor: theme.palette.warning.main
    },
    select: {
      margin: '4px',
      padding: '4px',
      flex: '4',
      alignItems: 'center',
      display: 'flex',
      width: '100%',
      fontSize: '.8em'
    },
    selectLabel: {
      marginLeft: '14px',
      marginTop: '-4px'
    },
    selectButton: {
      margin: '4px',
      padding: '4px',
      flex: 2,
      alignItems: 'center',
      display: 'flex'
    },
    flex: {
      display: 'flex',
      margin: '8px',
      padding: '(8px, 8px, 8px, 0)',
    },
    form: {
      width: '100%',
      marginTop: '4px'
    },
    submit: {
      margin: '(12px, 0, 4px, 0)'
    },
    signInBox: {
      width: 'calc(100% - 24px)',
      maxWidth: '400px !important',
      justifyContent: 'center',
      alignContent: 'center'
    },
    signInPaper: {
      margin: "auto",
      padding: '8px',
      maxWidth: "400px"
    },
    signInTitle: {
      margin: 'auto',
      textAlign: 'center',
      paddingTop: theme.spacing(6)
    },
    chatWindow: {
      borderRadius: '4px',
      margin: '4px',
      transition: 'width 600ms',
    },
    chatPiece1: {
      height: 'auto',
      borderRadius: '4px',
      paddingLeft: '4px',
      paddingRight: '4px',
      [theme.breakpoints.down('sm')]: {
        margin: 0,
        backgroundColor: theme.palette.background.paper,
      }
    },
    chatPiece2: {
      height: '85%'
    },
    statusDisplay: {
      padding: '4px',
      color: theme.palette.text.primary,
      justifyContent: 'center',
      textAlign: 'center',
      width: '100%',
      borderRadius: '4px',
      backgroundColor: theme.palette.background.light,
    },
    passesDisplay: {
      padding: '4px',
      color: theme.palette.text.primary,
      justifyContent: 'center',
      textAlign: 'center',
      width: '100%',
      height: '80px',
      [theme.breakpoints.down('sm')]: {
        height: '124px',
      },
      borderRadius: '4px',
      backgroundColor: theme.palette.background.light,
    },
    statusForm: {
      padding: '2px',
      height: '100%',
      borderRadius: '4px'
    },
    passDisplay: {
      margin: 'auto'
    },
    mainColumn: {
      padding: '2px',
      display: 'flex',
      flexWrap: 'wrap',
      flexDirection: 'column',
    },
    chat: {
      display: 'flex',
      flexDirection: 'column',
      borderRadius: 6,
      height: '97%',
      width: '100%',
      padding: '(2px, 0, 2px, 0)'
    },
    topPadding: {
      flexShrink: 1,
      flexGrow: 1,
      minHeight: '2px'
    },
    scrollBox: {
      overflowY: 'auto',
      overflowX: 'auto',
      scrollBehaviour: 'smooth',
      padding: '4px',
      maxHeight: 'calc(100vh - 270px)'
    },
    messageList: {
      display: 'flex',
      padding: '(4px, 4px, 44px, 4px)',
      flexDirection: 'column',
      justifyContent: 'flex-end'
    },
    chatInput: {
      alignContent: 'flex-end',
      justifyContent: 'flex-end',
      alignSelf: 'flex-end',
      width: '100%',
      padding: '4px'
    },
    settingsContainer: {
      border: theme.spacing(6) + 'px solid ' + theme.palette.background.default,
      borderRadius: theme.spacing(1),
      padding: theme.spacing(6)
    },
    switcherButton: {
      padding:'3px',
      minWidth:'32px',
      height:'32px',
      marginRight:'4px'
    },
    dtitle: {
      margin: 'auto',
      borderRadius: theme.spacing(2),
    },
    dcontent: {
      backgroundColor: theme.palette.background.default,
      padding: theme.spacing(6),
      // margin: theme.spacing(6)
    },
    dactions: {
      borderRadius: theme.spacing(2)
    },
    popoverMenu: {
      border: '1px solid ' + theme.palette.secondary.dark,
      padding: theme.spacing(8),
    },
    padding: {
      padding: '8px'
    },
    alertText: {
      color: theme.palette.warning.main
    },
    copyIcon: {
      color: theme.palette.secondary.light,
    },
    notiSuccess: {
      backgroundColor: theme.palette.primary.dark
    },
    notiInfo: {
      backgroundColor: theme.palette.secondary.dark
    },
    notiError: {
      backgroundColor: theme.palette.error.main,
      color: theme.palette.text.dark
    },
    notiWarning: {
      backgroundColor: theme.palette.warning.main,
      color: theme.palette.text.dark
    },
    

  }));

  return useStyles();
}
