import React, { useEffect, useState } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import {
  Grid,
  Hidden,
  Typography,
  Card,
  CardMedia,
  Button
} from '@material-ui/core/';
import useCamera from '../CameraProvider/useCamera';
import calculateAverageQP from './utils/calculateAverageQP';
import useOutboundRtpStats from './Hooks/useOutboundRtpStats';
import useInboundRtpStats from './Hooks/useInboundRtpStats';
import useIceStats from './Hooks/useIceStats';
import useMediaSourceStats from './Hooks/useMediaSourceStats';
import { interval } from 'd3-timer';

const useStyles = makeStyles((theme) => createStyles({
  container: {
    padding: theme.spacing(4),
    border: '2px solid ' + theme.palette.secondary.main,
    "&:hover": {
      border: '2px solid ' + theme.palette.primary.main,
    },
    borderRadius: theme.spacing(2),
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  item: {
    padding: theme.spacing(3),
  },
  section: {
    margin: 'auto',
  },
  sectionTitle: {
    paddingRight: '12px',
    textDecoration: 'underline',
    color: theme.palette.text.secondary,
  },
  title: {
    color: theme.palette.text.secondary,
    borderBottom: "1px solid " + theme.palette.secondary.main
  }
}));

// Stats to monitor

// firCount
// higher values indicate network issues
// use firCount / framesSent

// pliCount
// indicates video playback issues 
// use pliCount / framesSent

// qpSum
// indicates video compression / quality 
// use qpSum / framesEncoded
// vp8 qp [1-127]
// h264 qp [0-51]
// vp9 qp ???
// av1 qp ???

// nackCount 
// indicates packet loss
// use nackCount / packetsSent



// Stats to display
// Network Quality
// Frame Rate
// Bitrate

export default function Stats() {
  // Hooks
  const {
    color,
    peerConnection,
    peerConnectionStatus,
  } = useCamera();
  
    // processIceStats,
    // currentRoundTripTime
    const iceStats = useIceStats();
  
    // processInboundRtp,
    // averageFir,
    // averagePli,
    // averageQp,
    // averageNack,
    // averageFps,
    // qualityLimitationReason,
    // network,
    // videoQuality,
    // fps,
    // bitrate,
    const inboundRtpStats = useInboundRtpStats();
  
    // processOutboundRtp,
    // averageFir,
    // averagePli,
    // averageQp,
    // averageNack,
    // averageFps,
    // qualityLimitationReason,
    // network,
    // videoQuality,
    // fps,
    // bitrate
    const outboundRtpStats = useOutboundRtpStats();
  
    const mediaSourceStats = useMediaSourceStats();

  const classes = useStyles();

  // State
  const [intervalId, setIntervalId] = useState<number | null>(null);
  const [intervalRate, setIntervalRate] = useState<number>(2000);

  // Internal Metrics
  const [streamStart, setStreamStart] = useState<Date | null>();
  const [streamEnd, setStreamEnd] = useState<Date | null>();

  // Effects
  useEffect(() => {
    // Only collect stats when we are connected
    if (peerConnectionStatus === "connected") {
      if (intervalId) { clearInterval(intervalId) 
      console.log("new int clear interval ", intervalId);
      
      }
      let interval = setInterval(iterateStats, intervalRate);
      setIntervalId(interval);
console.log("interval set ", interval, peerConnection);
      
    } else if (intervalId) { 
console.log("not connected clear interval ", intervalId);
      
      clearInterval(intervalId) 
      setIntervalId(null);
    }
    return () => {
console.log("cleanup clear interval ", intervalId);
      if (intervalId) { clearInterval(intervalId) }
    };
  }, [peerConnection, peerConnectionStatus, intervalRate]);

  // Actions

  const pauseStats = () => {
    if (intervalId) { clearInterval(intervalId) }
  }

  const resumeStats = () => {
    if (intervalId) { clearInterval(intervalId) }
      let interval = setInterval(iterateStats, intervalRate);
      setIntervalId(interval);
  }

  const iterateStats = async () => {
    try {

      let stats = await peerConnection.getStats();
      
      stats.forEach((stat) => {
        
        switch (stat.type) {
          case "candidate-pair":
            iceStats.processIceStats(stat);
            break;
          case "certificate":
            // ['id', 'timestamp', 'type', 'fingerprint', 'fingerprintAlgorithm', 'base64Certificate']
            // console.debug("certificate ", Object.keys(stat));
            break;
          case "codec":
            // ['id', 'timestamp', 'type', 'transportId', 'payloadType', 'mimeType', 'clockRate', 'channels']
            // console.debug("codec ", Object.keys(stat));
            break;
          case "csrc":
            // console.debug("csrc ", Object.keys(stat));
            break;
          case "data-channel":
            // console.debug("data-channel ", Object.keys(stat));
            break;
          case "inbound-rtp":
          case "remote-inbound-rtp":
            inboundRtpStats.processInboundRtpStats(stat);
            break;
          case "local-candidate":
            iceStats.processIceStats(stat);
            break;
          case "media-source":
            mediaSourceStats.processMediaSourceStats(stat);
            break;
          case "outbound-rtp":
          case "remote-outbound-rtp":
            outboundRtpStats.processOutboundRtpStats(stat);
            break;
          case "peer-connection":
            // console.debug("peer-connection ", Object.keys(stat));
            // ['id', 'timestamp', 'type', 'dataChannelsOpened', 'dataChannelsClosed']
            break;
          case "receiver":
            // console.debug("receiver ", Object.keys(stat));
            break;
          case "remote-candidate":
            iceStats.processIceStats(stat);
            break;
          case "sender":
            // console.debug("sender ", Object.keys(stat));
            break;
        }
      });

    } catch (error) {
      console.error("Could not get stats ", error);
    } 
  }


  // Render
  return (
    <Grid container className={classes.container}>
      <Grid item xs={12}>
        <Typography>
          Network: {outboundRtpStats?.averageFir}
        </Typography>
        <Typography>
          Network Interuptions: {outboundRtpStats?.averageNack}
        </Typography>
        <Typography>
          Dropped Frames: {outboundRtpStats?.averagePli}
        </Typography>
        <Typography>
          Video Quality: {outboundRtpStats?.averageQp}
        </Typography>
        <Typography>
          Frame Rate: {mediaSourceStats?.fps}
        </Typography>
        <Typography>
          Bitrate: {outboundRtpStats?.bitrate ? 
          Number(outboundRtpStats.bitrate / 1000000).toFixed(2) : 0} Mbps
        </Typography>
        <Typography>
          Quality Limitation: {outboundRtpStats?.qualityLimitationReason}
        </Typography>
      </Grid>
    </Grid>
  );

}
