import React, { useEffect, useState } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import {
Grid,
Typography,
Button,
Checkbox,
Paper,
Input,
Divider,
Hidden,
} from '@material-ui/core/';
import SelectPass from '../Elements/select_pass';
import getPassData from '../Utils/getPassData';
import createNewFormattedExpiration from '../../../utils/create_new_formatted_expiration';
import { useSnackbar } from 'notistack';
import formatExpiration from '../../../utils/format_expiration';
import ModifyScheduledPass from '../Elements/modify_scheduled_pass';
import dayjs from 'dayjs';

const useStyles = makeStyles((theme) => createStyles({
  container: {
    padding: theme.spacing(2),
  }
}));

const tableHeaders = [
  'Pass ID',
  'Pass Name',
  'Viewers',
  'Scheduled At',
  'New Schedule',
  'Activate',
  'Unschedule'
]

export default function ManagedScheduled(props) {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  // State
  const [passesToUpdate, setPassesToUpdate] = useState(0);
  const [displayData, setDisplayData] = useState({});
  const [actionData, setActionData] = useState({
    url:'/api/modify_scheduled/',
    postData: {},
    actionText: 'modify scheduled passes'
  });

  // Actions
  const ModifyScheduled = () => {
    props.changeStep('Confirm', displayData, actionData, false);
  }

  const modifyPass = (dispData, postData) => {
    // Ensure we have a pass to modify
    if (
      dispData &&
      postData &&
      postData.pass_id
    ) {
      // Update data for confirmation step
      let newDisplayData = displayData;
      let newActionData = actionData;

      newDisplayData[postData.pass_id] = dispData;
      newActionData.postData[postData.pass_id] = postData;
      setDisplayData(newDisplayData);
      setActionData(newActionData);
      setPassesToUpdate(Object.keys(newDisplayData).length);
    }
  }

  const dontModifyPass = (pass_id) => {
    if (
      displayData[pass_id] &&
      actionData.postData[pass_id]
    ) {
      // Update data for confirmation step
      let newDisplayData = displayData;
      let newActionData = actionData;

      delete newDisplayData[pass_id];
      delete newActionData.postData[pass_id];
      setDisplayData(newDisplayData);
      setActionData(newActionData);
      setPassesToUpdate(Object.keys(newDisplayData).length);
    }
  }

  return (
    <Grid container className={classes.container}>
      <Grid item xs={12}>

        {/* Desktop */}
        <Hidden smDown>
          <Grid container className={props.cssClasses.tableHeader}>

            <Grid item xs={2} align="center" className={props.cssClasses.tableCell}>
              Pass ID
            </Grid>

            <Grid item xs={3} align="center" className={props.cssClasses.tableCell}>
              Pass Name
            </Grid>

            <Grid item xs={1} align="center" className={props.cssClasses.tableCell}>
              Viewers
            </Grid>

            <Grid item xs={2} align="center" className={props.cssClasses.tableCell}>
              Will Begin
            </Grid>

            <Grid item xs={1} align="center" className={props.cssClasses.tableCell}>
              At
            </Grid>

            <Grid item xs={2} align="center" className={props.cssClasses.tableCell}>
              Will Expire
            </Grid>

            <Grid item xs={1} align="center" className={props.cssClasses.tableCell}>
              Modified
            </Grid>

          </Grid>

          <Grid container className={props.cssClasses.tableBody}>
            {
              props.scheduledPasses.map(pass => (
                <ModifyScheduledPass
                  {...props}
                  selectedPass={pass._id}
                  modifyPass={modifyPass}
                  dontModifyPass={dontModifyPass}
                />
              ))
            }
          </Grid>

          <Grid container className={props.cssClasses.tableBody}>
            <Grid item xs={12} align="right">
              <Button
                onClick={ModifyScheduled}
                color="primary"
                disabled={Object.keys(displayData).length === 0}
              >
                Modify {passesToUpdate} Scheduled {passesToUpdate === 1 ? 'Pass' : 'Passes'}
              </Button>
            </Grid>
          </Grid>
        </Hidden>

        {/* Mobile */}
        <Hidden mdUp>
          <Grid container className={props.cssClasses.tableHeader}>
            <Grid item xs={2} align="center" className={props.cssClasses.tableCell}>
            </Grid>
          </Grid>

          <Grid container className={props.cssClasses.tableBody}>
            {
              props.scheduledPasses.map(pass => (
                <ModifyScheduledPass
                  {...props}
                  selectedPass={pass._id}
                  modifyPass={modifyPass}
                  dontModifyPass={dontModifyPass}
                />
              ))
            }
          </Grid>

          <Grid container className={props.cssClasses.tableBody}>
            <Grid item xs={12} align="right">
              <Button
                onClick={ModifyScheduled}
                color="primary"
                disabled={Object.keys(displayData).length === 0}
              >
                Modify {passesToUpdate} Scheduled {passesToUpdate === 1 ? 'Pass' : 'Passes'}
              </Button>
            </Grid>
          </Grid>
        </Hidden>


      </Grid>
    </Grid>
  );

}
