import React, { useEffect, useState } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import {
  Grid,
  Hidden,
  Typography,
  Card,
  CardMedia
} from '@material-ui/core/';
import useCamera from '../CameraProvider/useCamera';
import project_access_passes from '../../../Passes/project_access_passes';

const useStyles = makeStyles((theme) => createStyles({
  container: {
  }
}));

export default function Status(props) {
  // Hooks
  const {
    color,
    peerConnection,
    peerConnectionStatus,
    logoLoop,
    videoSource
  } = useCamera();

  const classes = useStyles();

  // State

  // Effects
  useEffect(() => {

    return () => {
    };
  }, []);

  // Actions

  // Render
  return (
    <Grid container className={classes.container}>
      <Grid item xs={12}>
        <Typography
          display="inline"
          align="right"
        >
          {
            props.enabled &&
            "Connection: " + peerConnectionStatus
          }
          {
            !props.enabled &&
            "Disabled"
          }
        </Typography>
      </Grid>
    </Grid>
  );

}
