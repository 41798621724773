import React, { useEffect, useState } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import {
Grid,
Table,
TableHead,
TableBody,
TableRow,
TableCell,
Input,
Select,
MenuItem,
Button,
Checkbox,
Typography,
Link,
Avatar,
Hidden,
} from '@material-ui/core/';
import { useSnackbar } from 'notistack';
import calculatePassPrice from '../../../utils/calculatePassPrice';
import useHasActivePass from '../Hooks/use_has_active_pass';
import PurchasePassCoupon from './purchase_pass_coupon';

const useStyles = makeStyles((theme) => createStyles({
  container: {
    padding: theme.spacing(2),
  },
  strikethrough: {
    textDecoration: 'line-through',
    opacity: 0.4,
  },
  subTotal: {
    opacity: 0.8,
  },
  discounts: {
    opacity: 0.5,
  },
  total: {
    fontWeight: 'bold'
  },
}));


type CouponData = {
  id: string,
  code: string,
  amount: string,
  discount_type: string
}

export default function PurchasePass(props) {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const hasActivePass = useHasActivePass(props.activePasses);

  // Display
  const [viewers, setViewers] = useState(10);
  const [duration, setDuration] = useState(1);
  const [durationUnits, setDurationUnits] = useState('days');
  const [cost, setCost] = useState(0);
  const [partnerDiscount, setPartnerDiscount] = useState<Number>(0);
  const [couponDiscount, setCouponDiscount] = useState<Number>(0);
  const [total, setTotal] = useState<Number>(0);
  const [isPartner, setIsPartner] = useState(false);
  const [showSubMessage, setShowSubMessage] = useState(false);

  // Options
  const [activateNow, setActivateNow] = useState(false);
  const [qty, setQty] = useState(1);
  const [couponData, setCouponData] = useState<CouponData | undefined>();

  // Confirmation
  const [displayData, setDisplayData] = useState({});
  const [actionData, setActionData] = useState({});

  // Effects

  // Don't allow activating a pass if project has a currently active pass
  // useEffect(() => {
  //   setActivateNow(!hasActivePass);
  // }, [hasActivePass]);

  // // Start on monthly if coming from modify access step
  // useEffect(() => {
  //   if (props.displayData?.monthly) { setDurationUnits('months') }
  // }, []);

  // Update partner discount
  useEffect(() => {
    if ( durationUnits === 'months') {
      setIsPartner(false);
    } else {
      setIsPartner(props.isPartner)
    }
  }, [props.isPartner, durationUnits]);

  // Calculate new price
  useEffect(() => {
    let newPrice = calculatePassPrice(
      viewers,
      duration,
      durationUnits
    );

    setCost(newPrice);

    let newTotal = newPrice

    // Add partner discount
    let partnerDiscount = 0
    if (isPartner) { 
      partnerDiscount = newPrice - (newPrice * 0.8) 
    }
    setPartnerDiscount(partnerDiscount)

    newTotal = newTotal - partnerDiscount

    // Apply coupon
    let couponDiscount = 0
    if (couponData) {
      switch (couponData.discount_type) {
        case "percent":
          let percent = Number(couponData.amount) / 100
          
          if (!isNaN(percent)) {
            couponDiscount = (newTotal * percent).toFixed(2)
          }
          break;
        case "fixed_cart":
        case "fixed_product":
          couponDiscount = Number(couponData.amount).toFixed(2)
          break;
        }
    }
    setCouponDiscount(couponDiscount)

    newTotal = newTotal - couponDiscount

    // Verify
    if (newTotal < 0) { newTotal = 0 }
    // Format
    newTotal = newTotal.toFixed(2)
    setTotal(newTotal)


    let newDisplayData = {}
    newDisplayData[0] = {
      viewers: viewers,
      duration: duration + ' ' + durationUnits[0].toUpperCase() + durationUnits.slice(1),
      quantity: qty,
      price: "$" + (newTotal * qty).toFixed(2)
    }
    setDisplayData(newDisplayData);
    setActionData({
      url:'/api/purchase_pass/',
      postData: {
        viewers: viewers,
        duration: duration,
        durationUnits: durationUnits,
        quantity: qty,
        cost: (newTotal * qty).toFixed(2),
        toActivateNow: (activateNow && qty <= 1),
        couponId: couponData?.id
      },
      actionText: 'purchase pass'
    })
  }, [viewers, duration, durationUnits, qty, isPartner, couponData]);

  useEffect(() => {
    let data = actionData;
    if (data.postData && data.postData.toActivateNow) {
      data.postData.toActivateNow = activateNow;
      setActionData(data);
    }
  }, [activateNow]);

  // Auto switch pass type
  useEffect(() => {
    // Switch from 7 hours to 1 day automatically at 8 hours
    if (durationUnits === 'hours' && duration >= 8) {
      setDurationUnits('days');
      setDuration(1);
    }
    // Switch from 1 day to 7 hours automatically at 0 days
    if (durationUnits === 'days' && duration < 1) {
      setDurationUnits('hours');
      setDuration(7);
    }
    // Switch from 5 days to 1 month automatically at 5 days
    if (durationUnits === 'days' && (duration * qty) >= 5) {
      setShowSubMessage(true);
      // setDurationUnits('months');
      // setDuration(1);
    }
    // Don't show subscription settings for less than 5 days
    if (durationUnits === 'days' && (duration * qty) < 5) {
      setShowSubMessage(false);
    }
    // // Switch from 1 month to 4 days automatically at 0 months
    // if (durationUnits == 'months' && duration < 1) {
    //   setDurationUnits('days');
    //   setDuration(4);
    // }
  }, [duration, durationUnits, qty]);

  const updateViewerCount = (viewers) => {
    if (viewers > 1 && viewers < 100) {
      setViewers(viewers)
    } else if (viewers > 99) {
      setViewers(99)
    } else {
      setViewers(1)
    }
  }

  return (
    <Grid container className={classes.container}>
      <Grid item xs={12}>

        {/* Desktop */}
        <Hidden smDown>
          <Grid container className={props.cssClasses.tableHeader}>

            <Grid item xs={1} align="center" className={props.cssClasses.tableCell}>

            </Grid>

            <Grid item xs={1} align="center" className={props.cssClasses.tableCell}>
              # of Viewers
            </Grid>

            <Grid item xs={1} align="center" className={props.cssClasses.tableCell}>
              Duration
            </Grid>

            <Grid item xs={2} align="center" className={props.cssClasses.tableCell}>
              Pass Type
            </Grid>

            {
              props.modalAction === 'activate' &&
              <Grid item xs={1} align="center" className={props.cssClasses.tableCell}>
                Activate Now?
              </Grid>
            }

            <Grid item xs={2} align="right" className={props.cssClasses.tableCell}>
              Cost
            </Grid>

          </Grid>

          <Grid container className={props.cssClasses.tableBody}>

            <Grid item xs={1} align="center" className={props.cssClasses.tableCell}>
              <img src="/shop/Custom-Pass-128x128.png" height="60px" />
            </Grid>

            <Grid item xs={1} align="center" className={props.cssClasses.tableCell}>
              <Input
                type="number"
                value={viewers}
                onChange={e => updateViewerCount(Number(e.target.value))}
                label="Select Number of Viewers"
                required
                color="primary"
                style={{width:'60px', textAlign: 'right'}}
                inputProps={{
                  min: 1,
                  max: 99
                }}
              />
            </Grid>

            <Grid item xs={1} align="center" className={props.cssClasses.tableCell}>
              <Input
                type="number"
                value={duration}
                onChange={e => setDuration(Number(e.target.value))}
                label="Choose Pass Duration"
                required
                color="primary"
                style={{width:'60px', textAlign: 'right'}}
                inputProps={{
                  min: 0,
                }}
              />
            </Grid>

            <Grid item xs={2} align="center" className={props.cssClasses.tableCell}>
              <Select
                type="number"
                value={durationUnits}
                onChange={e => setDurationUnits(e.target.value)}
                label="Choose Pass Duration"
                required
                color="primary"
              >
                <MenuItem value="hours">Hours</MenuItem>
                <MenuItem value="days">Days</MenuItem>
              </Select>
            </Grid>

            {
              props.modalAction === 'activate' &&
              <Grid item xs={1} align="center" className={props.cssClasses.tableCell}>
                <Checkbox
                  type="checkbox"
                  checked={activateNow && qty <= 1}
                  onChange={() => setActivateNow(!activateNow)}
                  label="Activate Now?"
                  disabled={qty > 1 || hasActivePass}
                />
              </Grid>
            }

            <Grid item xs={2} align="right" className={props.cssClasses.tableCell}>
              <Typography className={classes.subTotal}>
                ${cost.toFixed(2)}
              </Typography>
            </Grid>

          </Grid>

          <Grid container><br /></Grid>

          <Grid container className={props.cssClasses.tableHeader}>

            <Grid item xs={5} align="left" className={props.cssClasses.tableCell}>
              Coupon
            </Grid>

            <Grid item xs={2} align="center" className={props.cssClasses.tableCell}>
              Qty.
            </Grid>

            <Grid item xs={3} align="right" className={props.cssClasses.tableCell}>
              Total
            </Grid>

            <Grid item xs={2} align="right" className={props.cssClasses.tableCell}>
              
            </Grid>

          </Grid>

          <Grid container className={props.cssClasses.tableBody}>


            <Grid item xs={5} align="left" className={props.cssClasses.tableCell}>
              <PurchasePassCoupon 
                {...props}
                setCouponData={setCouponData}
              />
            </Grid>

            <Grid item xs={2} align="center" className={props.cssClasses.tableCell}>
              <Input
                type="number"
                value={qty}
                onChange={e => setQty(Number(e.target.value))}
                label="Choose Quantity"
                required
                color="primary"
                style={{width:'60px', textAlign: 'right'}}
                inputProps={{
                  min: 0,
                }}
              />
            </Grid>

            <Grid item xs={3} align="right" className={props.cssClasses.tableCell}>
              <Typography className={classes.subTotal}>
                SubTotal: ${(cost * qty).toFixed(2)}
              </Typography>
              {
                isPartner &&
                <Typography className={classes.discounts}>
                  Partner: (- ${partnerDiscount * qty})
                </Typography>
              }
              {
                couponDiscount > 0 &&
                <Typography className={classes.discounts}>
                  Coupon: (- ${couponDiscount * qty})
                </Typography>
              }
              <Typography className={classes.total}>
                Total: ${(total * qty).toFixed(2)}
              </Typography>
            </Grid>

            <Grid item xs={2} align="right" className={props.cssClasses.tableCell}>
              <Button
                onClick={() => props.changeStep('Confirm', displayData, actionData, false)}
                variant="contained"
                color="primary"
              >
                Checkout
              </Button>
            </Grid>
          </Grid>
        </Hidden>

        {/* Mobile */}
        <Hidden mdUp>
          <Grid container className={props.cssClasses.tableHeader}>
            <Grid item xs={12} align="center" className={props.cssClasses.tableCell}>

            </Grid>
          </Grid>

          <Grid container className={props.cssClasses.tableBody}>
            <Grid item xs={12} align="center" className={props.cssClasses.tableCell}>
              <img src="/shop/Custom-Pass-128x128.png" height="60px" />
            </Grid>
          </Grid>

          <Grid container className={props.cssClasses.tableHeader}>
            <Grid item xs={12} align="center" className={props.cssClasses.tableCell}>
              # of Viewers
            </Grid>
          </Grid>

          <Grid container className={props.cssClasses.tableBody}>
            <Grid item xs={12} align="center" className={props.cssClasses.tableCell}>
              <Input
                type="number"
                value={viewers}
                onChange={e => setViewers(Number(e.target.value))}
                label="Select Number of Viewers"
                required
                color="primary"
                style={{width:'60px', textAlign: 'right'}}
                inputProps={{
                  min: 1,
                  max: 999
                }}
              />
            </Grid>
          </Grid>

          <Grid container className={props.cssClasses.tableHeader}>
            <Grid item xs={12} align="center" className={props.cssClasses.tableCell}>
              Duration
            </Grid>
          </Grid>

          <Grid container className={props.cssClasses.tableBody}>
            <Grid item xs={12} align="center" className={props.cssClasses.tableCell}>
              <Input
                type="number"
                value={duration}
                onChange={e => setDuration(Number(e.target.value))}
                label="Choose Pass Duration"
                required
                color="primary"
                style={{width:'60px', textAlign: 'right'}}
                inputProps={{
                  min: 0,
                }}
              />
            </Grid>
          </Grid>

          <Grid container className={props.cssClasses.tableHeader}>
            <Grid item xs={12} align="center" className={props.cssClasses.tableCell}>
              Pass Type
            </Grid>
          </Grid>

          <Grid container className={props.cssClasses.tableBody}>
            <Grid item xs={12} align="center" className={props.cssClasses.tableCell}>
              <Select
                type="number"
                value={durationUnits}
                onChange={e => setDurationUnits(e.target.value)}
                label="Choose Pass Duration"
                required
                color="primary"
              >
                <MenuItem value="hours">Hours</MenuItem>
                <MenuItem value="days">Days</MenuItem>
              </Select>
            </Grid>
          </Grid>

          <Grid container className={props.cssClasses.tableHeader}>
            {
              props.modalAction === 'activate' &&
              <Grid item xs={12} align="center" className={props.cssClasses.tableCell}>
                Activate Now?
              </Grid>
            }
          </Grid>

          <Grid container className={props.cssClasses.tableBody}>
            {
              props.modalAction === 'activate' &&
              <Grid item xs={12} align="center" className={props.cssClasses.tableCell}>
                <Checkbox
                  type="checkbox"
                  checked={activateNow && qty <= 1}
                  onChange={() => setActivateNow(!activateNow)}
                  label="Activate Now?"
                  disabled={qty > 1 || hasActivePass}
                />
              </Grid>
            }
          </Grid>

          <Grid container className={props.cssClasses.tableHeader}>
            <Grid item xs={12} align="center" className={props.cssClasses.tableCell}>
              Cost
            </Grid>
          </Grid>

          <Grid container className={props.cssClasses.tableBody}>
            <Grid item xs={12} align="right" className={props.cssClasses.tableCell}>
            <Typography className={classes.subTotal}>
                ${cost.toFixed(2)}
              </Typography>
            </Grid>
          </Grid>

          <Grid container className={props.cssClasses.tableHeader}>
            <Grid item xs={12} align="center" className={props.cssClasses.tableCell}>
              Qty.
            </Grid>
          </Grid>

          <Grid container className={props.cssClasses.tableBody}>
            <Grid item xs={12} align="center" className={props.cssClasses.tableCell}>
              <Input
                type="number"
                value={qty}
                onChange={e => setQty(Number(e.target.value))}
                label="Choose Quantity"
                required
                color="primary"
                style={{width:'60px', textAlign: 'right'}}
                inputProps={{
                  min: 0,
                }}
              />
            </Grid>
          </Grid>

          <Grid container className={props.cssClasses.tableHeader}>
            <Grid item xs={12} align="center" className={props.cssClasses.tableCell}>
              Coupon
            </Grid>
          </Grid>
          
          <Grid container className={props.cssClasses.tableBody}>
            <PurchasePassCoupon 
              {...props}
              setCouponData={setCouponData}
            />
          </Grid>

          <Grid container className={props.cssClasses.tableHeader}>
            <Grid item xs={12} align="center" className={props.cssClasses.tableCell}>
              Total
            </Grid>
          </Grid>

          <Grid container className={props.cssClasses.tableBody}>
            <Grid item xs={12} align="right" className={props.cssClasses.tableCell}>
              <Typography className={classes.subTotal}>
                SubTotal: ${(cost * qty).toFixed(2)}
              </Typography>
              {
                isPartner &&
                <Typography className={classes.discounts}>
                  Partner: (- ${partnerDiscount * qty})
                </Typography>
              }
              {
                couponDiscount > 0 &&
                <Typography className={classes.discounts}>
                  Coupon: (- ${couponDiscount * qty})
                </Typography>
              }
              <Typography className={classes.total}>
                Total: ${(total * qty).toFixed(2)}
              </Typography>
            </Grid>
          </Grid>

          <Grid container className={props.cssClasses.tableHeader}>
            <Grid item xs={12} align="center" className={props.cssClasses.tableCell}>

            </Grid>
          </Grid>

          <Grid container className={props.cssClasses.tableBody}>
            <Grid item xs={12} align="center" className={props.cssClasses.tableCell}>
              <Button
                onClick={() => props.changeStep('Confirm', displayData, actionData, false)}
                variant="contained"
                color="primary"
              >
                Checkout
              </Button>
            </Grid>
          </Grid>
        </Hidden>

        <Grid item xs={12} align="center" className={props.cssClasses.tableCell}>
          {
            showSubMessage &&
            <Typography>
              A monthly subscription would save you money.  Would you like to 
              <Link
                onClick={() => props.changeStep('Purchase Subscription', {}, {}, false)}
                >
                {' Purchase a Subscription '}
              </Link>
              instead?
            </Typography>
          }
        </Grid>

      </Grid>
    </Grid>
  );

}
