import React, { useEffect, useState } from 'react';
import { createStyles, Theme } from '@material-ui/core/styles';
import { useSnackbar } from 'notistack';
import {
  FormGroup,
  FormControl,
  FormControlLabel,
  Switch,
  Menu,
  MenuItem,
  IconButton,
  CircularProgress,
  Typography,
  Divider,
  Grid
} from '@material-ui/core/';
import SettingsIcon from '@material-ui/icons/Settings';

const axios = require('axios').default;

const useStyles = createStyles(theme => ({
}));

export default function RemoteParticipantVideo(props) {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const [settingsOpen, toggleSettingsOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const [isUpdating, toggleIsUpdating] = useState(false);

  // Create default camera permissions object
  let defaultPermissions = {
    textChat: true,
    videoChat: true,
    sketches: true,
    cams: {
      0: true,
      1: true,
      2: true,
      3: true
    }
  }

  if (
    props.viewer.permissions
  ) {
    defaultPermissions.textChat = typeof props.viewer.permissions.textChat === 'boolean' ? props.viewer.permissions.textChat : true;
    defaultPermissions.videoChat = typeof props.viewer.permissions.videoChat === 'boolean' ? props.viewer.permissions.videoChat : true;
    defaultPermissions.sketches = typeof props.viewer.permissions.sketches === 'boolean' ? props.viewer.permissions.sketches : true;
    if (props.viewer.permissions.cams) {
      defaultPermissions.cams = {
        0: typeof props.viewer.permissions.cams[0] === 'boolean' ? props.viewer.permissions.cams[0] : true,
        1: typeof props.viewer.permissions.cams[1] === 'boolean' ? props.viewer.permissions.cams[1] : true,
        2: typeof props.viewer.permissions.cams[2] === 'boolean' ? props.viewer.permissions.cams[2] : true,
        3: typeof props.viewer.permissions.cams[3] === 'boolean' ? props.viewer.permissions.cams[3] : true,
      }
    }
  }

  const [permissions, setPermissions] = useState(defaultPermissions);

  const updatePermissions = (type, cam_index, value) => {

    toggleIsUpdating(true);

    axios.post(process.env.REACT_APP_HOST_URL + '/api/permissions/' + props.publisher_uuid, {
      user_uuid: props.viewer.uuid,
      type: type,
      cam_index: cam_index ? cam_index : 0, // Must pass a number to pass data checks
      permission: value,
    })
    .then(result => {

      if (
        result &&
        result.data.type === 'success' &&
        result.data.new_permissions
      ) {
        let newPerms = {
          textChat: typeof result.data.new_permissions.textChat === 'boolean' ? result.data.new_permissions.textChat : true,
          videoChat: typeof result.data.new_permissions.videoChat === 'boolean' ? result.data.new_permissions.videoChat : true,
          sketches: typeof result.data.new_permissions.sketches === 'boolean' ? result.data.new_permissions.sketches : true,
          cams: {
            0: true,
            1: true,
            2: true,
            3: true
          }
        }
        if (result.data.new_permissions.cams) {
          newPerms.cams[0] = typeof result.data.new_permissions.cams[0] === 'boolean' ? result.data.new_permissions.cams[0] : true
          newPerms.cams[1] = typeof result.data.new_permissions.cams[1] === 'boolean' ? result.data.new_permissions.cams[1] : true
          newPerms.cams[2] = typeof result.data.new_permissions.cams[2] === 'boolean' ? result.data.new_permissions.cams[2] : true
          newPerms.cams[3] = typeof result.data.new_permissions.cams[3] === 'boolean' ? result.data.new_permissions.cams[3] : true
        }
        setPermissions(newPerms)
      } else {
        enqueueSnackbar("We could not update the permissions.  Please check them and try again. " + result.data.message, { variant: 'info' });
        console.error('Error - Could not update the permissions', result.data.message);
      }

      toggleIsUpdating(false);

    })
    .catch(err => {
      toggleIsUpdating(false);
      enqueueSnackbar("We could not update the permissions.  Please try again. " + err.message, { variant: 'error' });
      console.error('Error - Could not update permissions', err.message);
    });
  }
  const camera_labels = ['A','B','C','D'];
  const camera_colors = [
    'rgba(226, 29, 29, 1)',
    'rgba(50, 171, 223, 1)',
    'rgba(226, 226, 29, 1)',
    'rgba(71, 185, 105, 1)'
  ]

  let cam_switches = [];
  for (var i = 0; i < props.enabledCameras; i++) {
    let index = i;
    cam_switches.push(
      <Grid item xs={6} key={'g'+i} style={{paddingRight: '4px'}}>
        <FormControlLabel
          control={
            <Switch
              checked={permissions.cams[index]}
              onChange={() => updatePermissions('toggleCams', index, !permissions.cams[index])}
              size="small"
              disabled={isUpdating}
              style={{
                color: camera_colors[i]
              }}
            />
          }
          label={camera_labels[index]}
        />
      </Grid>
    );
  }

  return (
    <Grid container >
      <Grid item xs={6}>
        <Grid container>
          <Grid item style={{position: 'relative', left: '-25px'}}>
            {
              isUpdating &&
              <CircularProgress color="primary" size={20} />
            }
          </Grid>

          <Grid item xs={6}>
            <FormControlLabel
              control={
                <Switch
                  checked={permissions.textChat}
                  onChange={() => updatePermissions('toggleTextChat', 0, !permissions.textChat)}
                  name="textChat"
                  disabled={isUpdating}
                  size="small"
                  color = {permissions.textChat ? 'primary' : 'secondary'}
                />
              }
              label=" Text Chat"
            />
          </Grid>

          <Grid item xs={6}>
            <FormControlLabel
              control={
                <Switch
                  checked={permissions.sketches}
                  onChange={() => updatePermissions('toggleSketches', 0, !permissions.sketches)}
                  name="sketches"
                  disabled={isUpdating}
                  size="small"
                  color = {permissions.sketches ? 'primary' : 'secondary'}
                />
              }
              label=" Sketches"
            />
          </Grid>

          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Switch
                  checked={permissions.videoChat}
                  onChange={() => updatePermissions('toggleVideoChat', 0, !permissions.videoChat)}
                  name="videoChat"
                  size="small"
                  disabled={isUpdating}
                  color = {permissions.videoChat ? 'primary' : 'secondary'}
                />
              }
              label="Video Chat"
            />
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={6}>
        <Grid
          container
        >
          {cam_switches}
        </Grid>
      </Grid>
    </Grid>
  );

}
