import React, { useEffect, useState } from 'react';
import { createStyles, Theme } from '@material-ui/core/styles';
import {
LinearProgress,
Grid,
Typography,
Button,
} from '@material-ui/core/';

const useStyles = createStyles(theme => ({

}));

export default function RoomConnectingStep(props) {
  const classes = useStyles();

  useEffect(() => {

    return () => {
    };
  }, []);

  return (
    <Grid container justifyContent="center" style={{padding: '36px', textAlign:'center'}}>
      <Grid item xs={12}>
        <Typography>
          Please stand by while we connect you ......
        </Typography>
      </Grid>

      <Grid item xs={12} style={{padding: '36px'}}>
        <LinearProgress variant="indeterminate" color="primary" />
      </Grid>
    </Grid>
  );

}
