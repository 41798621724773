import React, { useEffect, useState, useRef } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import {
Grid,
Hidden,
Typography,
FormControl,
FormControlLabel,
Input,
InputLabel,
Select,
MenuItem,
Button,
Checkbox,
Popover,
} from '@material-ui/core/';
import { useSnackbar } from 'notistack';
import useCamera from '../../CameraProvider/useCamera';
import { BroadcastSources, broadcastSources } from '../../CameraProvider/Settings/broadcast_sources';

const useStyles = makeStyles((theme) => createStyles({
  container: {
    padding: 0,
    marginTop: theme.spacing(2),
    display: 'inline',
  },
  activeButton: {
    // borderBottom: '2px solid ' + theme.palette.background.light,
    borderRadius: "4px 4px 0px 0px",
  },
  inactiveButton: {
    // borderBottom: '2px solid ' + theme.palette.background.light,
    borderRadius: "4px 4px 0px 0px",
  }
}));

interface Props {

}

export default function BroadcastSource(props: Props) {
  // Hooks
  const {
    enabled,
    label,
    cssClasses
  } = useCamera();
  const classes = useStyles();
  const {enqueueSnackbar} = useSnackbar();

  // State
  const [selectedSource, setSelectedSource] = useState<number>(0);

  // Refs
  // Effects

  // Actions

  // Render
  return (
    <Grid 
      container 
      className={classes.container}
    >
      <Grid
        item
        xs={12}
      >
        <Button          
          disabled={!props.enabled}
          color={ selectedSource === 0 ? "secondary" : "default" }
          className={ selectedSource === 0 ? classes.activeButton : classes.inactiveButton }
          size="small"
        >
          Browser
        </Button>

        <Button
          disabled={!props.enabled}
          color={ selectedSource === 1 ? "secondary" : "default" }
          className={ selectedSource === 0 ? classes.activeButton : classes.inactiveButton }
          size="small"
        >
          OBS
        </Button>

        <Button
          disabled={!props.enabled}
          color={ selectedSource === 2 ? "secondary" : "default" }
          className={ selectedSource === 0 ? classes.activeButton : classes.inactiveButton }
          size="small"
        >
          RTMP
        </Button>
      </Grid>
    </Grid>
  );

}
