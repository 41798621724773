import React, { useEffect, useState } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import {
Grid,
Button,
Table,
TableHead,
TableBody,
TableRow,
TableCell,
Typography,
Link,
Hidden,
} from '@material-ui/core/';
import { useSnackbar } from 'notistack';
import SelectPass from '../Elements/select_pass';
import formatExpiration from '../../../utils/format_expiration';
import getPassData from '../Utils/getPassData';
import createNewFormattedExpiration from '../../../utils/create_new_formatted_expiration';
import useHasActivePass from '../Hooks/use_has_active_pass';

const useStyles = makeStyles((theme) => createStyles({
  container: {
    padding: theme.spacing(2),
  },
  infoHeader: {
    backgroundColor: theme.palette.background.light,
  },
}));

export default function ActivatePass(props) {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const hasActivePass = useHasActivePass(props.activePasses);

  // Passes
  const [selectedPass, setSelectedPass] = useState('');
  const [selectedPassData, setSelectedPassData] = useState();

  // Display
  const [newExpirationDisplay, setNewExpirationDisplay] = useState('-');
  const [maxViewers, setMaxViewers] = useState('-');

  // Confirmation
  const [displayData, setDisplayData] = useState({});
  const [actionData, setActionData] = useState({});

  // Effects

  // Update selected pass
  useEffect(() => {
    if (props.availablePasses && props.availablePasses.length > 0) {
      setSelectedPass(props.availablePasses[0]._id);
    }
  }, [props.availablePasses])

  // Update selected pass data
  useEffect(() => {
    setSelectedPassData(getPassData(props.availablePasses, selectedPass));
  }, [selectedPass]);

  // Update selected pass display info
  useEffect(() => {
    if (
      selectedPassData &&
      selectedPassData.active_duration &&
      selectedPassData.duration_units &&
      selectedPassData.pass_type &&
      selectedPassData.max_allowed_users
    ) {
      let newExpirationDisplay = createNewFormattedExpiration(selectedPassData, 0);
      setNewExpirationDisplay(newExpirationDisplay)
      setMaxViewers(selectedPassData.max_allowed_users);

      let newDisplayData = {}
      newDisplayData[selectedPass] = {
        passType: selectedPassData.pass_type,
        newExpiration: newExpirationDisplay,
        maxViewers: selectedPassData.max_allowed_users,
      }
      setDisplayData(newDisplayData);
      setActionData({
        url:'/api/access_passes/',
        postData: {
          pass_id: selectedPassData._id,
          to_schedule: false,
        },
        actionText:'activating a pass',
      });
    }

  }, [selectedPassData]);

  return (
    <Grid container className={classes.container}>

      <Grid item xs={12}>

        {/* Desktop */}
        <Hidden smDown>
          <Grid container className={props.cssClasses.tableHeader}>

            <Grid item xs={3} align="center" className={props.cssClasses.tableCell}>
              Pass to Activate
            </Grid>

            <Grid item xs={3} align="center" className={props.cssClasses.tableCell}>
              Will Expire
            </Grid>

            <Grid item xs={3} align="center" className={props.cssClasses.tableCell}>
              Viewers
            </Grid>

            <Grid item xs={3} align="center" className={props.cssClasses.tableCell}>
              Activate
            </Grid>

          </Grid>

          <Grid container className={props.cssClasses.tableBody}>

            <Grid item xs={3} align="center" className={props.cssClasses.tableCell}>
              <SelectPass
                passes={props.availablePasses}
                cssClasses={props.cssClasses}
                selectedPass={selectedPass}
                setSelectedPass={setSelectedPass}
              />
            </Grid>

            <Grid item xs={3} align="center" className={props.cssClasses.tableCell}>
              <Typography>{newExpirationDisplay}</Typography>
            </Grid>

            <Grid item xs={3} align="center" className={props.cssClasses.tableCell}>
              <Typography>{maxViewers}</Typography>
            </Grid>

            <Grid item xs={3} align="center" className={props.cssClasses.tableCell}>
              <Button
                onClick={() => props.changeStep('Confirm', displayData, actionData, false)}
                variant="contained"
                color="primary"
              >
                Activate
              </Button>
            </Grid>

          </Grid>
        </Hidden>

        {/* Mobile */}
        <Hidden mdUp>

          <Grid container className={props.cssClasses.tableHeader}>
            <Grid item xs={12} align="center" className={props.cssClasses.tableCell}>
              Pass to Activate
            </Grid>
          </Grid>

          <Grid container className={props.cssClasses.tableBody}>
            <Grid item xs={12} align="center" className={props.cssClasses.tableCell}>
              <SelectPass
                passes={props.availablePasses}
                cssClasses={props.cssClasses}
                selectedPass={selectedPass}
                setSelectedPass={setSelectedPass}
              />
            </Grid>
          </Grid>

          <Grid container className={props.cssClasses.tableHeader}>
            <Grid item xs={12} align="center" className={props.cssClasses.tableCell}>
              Will Expire
            </Grid>
          </Grid>

          <Grid container className={props.cssClasses.tableBody}>
            <Grid item xs={12} align="center" className={props.cssClasses.tableCell}>
              <Typography>{newExpirationDisplay}</Typography>
            </Grid>
          </Grid>

          <Grid container className={props.cssClasses.tableHeader}>
            <Grid item xs={12} align="center" className={props.cssClasses.tableCell}>
              Viewers
            </Grid>
          </Grid>

          <Grid container className={props.cssClasses.tableBody}>
            <Grid item xs={12} align="center" className={props.cssClasses.tableCell}>
              <Typography>{maxViewers}</Typography>
            </Grid>
          </Grid>

          <Grid container className={props.cssClasses.tableHeader}>
            <Grid item xs={12} align="center" className={props.cssClasses.tableCell}>
              Activate
            </Grid>
          </Grid>

          <Grid container className={props.cssClasses.tableBody}>
            <Grid item xs={12} align="center" className={props.cssClasses.tableCell}>
              <Button
                onClick={() => props.changeStep('Confirm', displayData, actionData, false)}
                variant="contained"
                color="primary"
              >
                Activate
              </Button>
            </Grid>
          </Grid>
        </Hidden>
      </Grid>

      <Grid
        item
        xs={12}
        sx={{
        border: '4px solid',
        borderColor: 'warning.main'
      }}
      >

        {
          hasActivePass &&
          <Typography
            align="center"
            gutterBottom={true}
          >
            YOU CURRENTLY HAVE AN ACTIVE PASS.
          </Typography>
        }
        {
          hasActivePass &&
          <Typography
            align="center"
            gutterBottom={true}
          >
            Continuing will END the current pass and activate a new pass with the maximum number of allowed viewers and expiration set by the new pass.
          </Typography>
        }
        {
          hasActivePass &&
          <Typography
            align="center"
            gutterBottom={true}
          >
            Would you like to
            <Link
              onClick={() => props.changeStep('Schedule Pass', {}, {}, true)}
            >
              {' schedule a pass '}
            </Link>
             instead?
          </Typography>
        }
      </Grid>

    </Grid>
  );

}
