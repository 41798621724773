import React, { useEffect, useState, useRef, useCallback } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import {
  Grid, 
  Typography, 
  Menu,
  Button,
  MenuItem,
  useTheme,
} from '@material-ui/core/';
import WarningIcon from '@material-ui/icons/Warning';
import useCustomBranding from './Context/useCustomBranding';
import useDarkTheme from '../../themes/useDarkTheme';

// Color handling
import { HexColorInput, RgbColorPicker } from "react-colorful";
import { Colord, colord, RgbColor } from 'colord';

export interface BrandColorOptionProps {
  secondary?: boolean,
}

// Defaults to the primary color unless the secondary prop is passed
export default function BrandColorOption(props: BrandColorOptionProps) {
  // Hooks
  const useStyles = makeStyles((theme) => createStyles({
    container: {
      padding: theme.spacing(2),
    },
    colorBox: {
      width: "128px",
      borderRadius: theme.spacing(4),
      backgroundColor: props.secondary ? theme.palette.secondary.main : theme.palette.primary.main
    },
    menu: {
      padding: theme.spacing(12),
      border: theme.spacing(1),
      borderRadius: theme.spacing(4)
    },
    menuItem: {
      padding: theme.spacing(4),
    },
    colorWarning: {
      padding: theme.spacing(4),
      backgroundColor: theme.palette.background.paper,
      borderRadius: theme.spacing(4)
    }
  }));

  const classes = useStyles();
  const theme = useTheme();
  const darkTheme = useDarkTheme()
  const { 
    brandPrimaryColor, 
    setBrandPrimaryColor, 
    storedBrandPrimaryColor,
    brandSecondaryColor, 
    setBrandSecondaryColor,
    storedBrandSecondaryColor,
  } = useCustomBranding()
  
  const [pickedColor, setPickedColor] = useState<Colord | null>(null)
  const [showPicker, setShowPicker] = useState<boolean>(false)
  const [colorWarning, setColorWarning] = useState<string | null>(null)
  var pickerDebounce: ReturnType<typeof setTimeout>;

  const colorRef = useRef()

  useEffect(() => {
    if (!pickedColor) { return }
    if (props.secondary) { return }
    if (pickedColor.isEqual(getDefaultColor()) && getBrandColor()) {
      let brandColor = getBrandColor()
      if (brandColor.isValid()) {
        setPickedColor(brandColor)
      }
    }
  }, [brandPrimaryColor]);

  useEffect(() => {
    if (!pickedColor) { return }
    if (!props.secondary) { return }
    if (pickedColor.isEqual(getDefaultColor()) && getBrandColor()) {
      let brandColor = getBrandColor()
      if (brandColor.isValid()) {
        setPickedColor(brandColor)
      }
    }
  }, [brandSecondaryColor]);

  useEffect(() => {
    if (!pickedColor) { setPickedColor(getDefaultColor()); return }
    if (
      pickedColor?.isEqual(getDefaultColor()) &&
      !getBrandColor()
    ) { return }

    let color = colord(pickedColor)
    if (!color.isValid) { return }

    if (color.brightness() > 0.6) {
      setColorWarning("Primary color is too Light. Text may be hard to read.")
    } else if (color.brightness() < 0.1) {
      setColorWarning("Primary color is too Dark. Text may be hard to read.")
    } else if (colorWarning) {
      setColorWarning(null)
    }

  }, [pickedColor]);

  useEffect(() => {
    if (!pickedColor) { return }
    if (!showPicker && !pickedColor.isEqual(getDefaultColor())) {
      let color = colord(pickedColor)
      if (!color.isValid()) { return }
      if (props.secondary) {
        setBrandSecondaryColor(color.toRgbString())
      } else {
        setBrandPrimaryColor(color.toRgbString())
      }
    }
  }, [showPicker]);

  const getDefaultColor: () => Colord = useCallback(() => {
    if (props.secondary) {
      return colord(darkTheme.palette.secondary.main)
    } else {
      return colord(darkTheme.palette.primary.main)
    }
  }, [props.secondary, darkTheme])

  const getBrandColor: () => Colord = useCallback(() => {
    if (props.secondary) {
      return colord(brandSecondaryColor)
    } else {
      return colord(brandPrimaryColor)
    }
  }, [brandPrimaryColor, brandSecondaryColor])

  const getBrandColorString: () => (string | null) = useCallback(() => {
    if (props.secondary) {
      let color = colord(brandSecondaryColor)
      if (
        !color.isValid() ||
        color.isEqual(getDefaultColor())
      ) { return "Default" }
      return color.toHex()
    } else {
      let color = colord(brandPrimaryColor)
      if (
        !color.isValid() ||
        color.isEqual(getDefaultColor())
      ) { return "Default" }
      return color.toHex()
    }
  }, [brandPrimaryColor, brandSecondaryColor])

  const getColorLabel: () => string = useCallback(() => {
    if (props.secondary) {
      return "Secondary"
    } else {
      return "Primary"
    }
  }, [props.secondary])

  const debounceUpdatePickedColor = useCallback((newColor) => {
    let color = colord(newColor)
    if (!color.isValid()) { return }
    if (!pickedColor || !color.isEqual(pickedColor)) {
      setPickedColor(color)
    }
  }, [pickedColor])

  const updatePickedColor: (newColor: (RgbColor | string)) => void = (newColor) => {
    clearTimeout(pickerDebounce)
    pickerDebounce = setTimeout(() => debounceUpdatePickedColor(newColor), 15)
  }

  const resetColor: () => void = () => {
    setPickedColor(getDefaultColor())
    if (props.secondary) { setBrandSecondaryColor(getDefaultColor().toRgbString()) }
    else { setBrandPrimaryColor(getDefaultColor().toRgbString()) }
  }

  // Render
  return (
    <Grid className={classes.container}>

      <Grid 
        item 
        className={classes.colorBox} 
        style={{ backgroundColor: pickedColor?.toRgbString() }} 
        ref={colorRef}
        onClick={() => setShowPicker(true)}
      >
        <Typography>{getColorLabel()} Color</Typography>
        <Typography>{getBrandColorString()}</Typography>
      </Grid>

      {
        colorWarning &&
        <Grid 
          item
          xs={12}
          className={classes.colorWarning}
          style={{ 
            maxWidth: '212px',
          }}
        >
          <Typography color="error" >
            <WarningIcon />
            {colorWarning}
          </Typography>
        </Grid>
      }

      <Menu
        open={showPicker}
        onClose={() => setShowPicker(false)}
        anchorEl={colorRef.current}
      >
        <Grid 
          container 
          direction='column' 
          className={classes.menu}
          style={{ backgroundColor: pickedColor?.toRgbString() }}
          alignItems='center'
        >
          <Grid item xs={12} className={classes.menuItem}>
            <Typography>
              Choose {getColorLabel()} Color
            </Typography>
          </Grid>

          <Grid item xs={12} className={classes.menuItem}>
            <RgbColorPicker
              color={pickedColor?.toRgb()}
              onChange={updatePickedColor}
            />
          </Grid>

          <Grid item xs={12} className={classes.menuItem}>
            Hex:
            <HexColorInput
              color={pickedColor?.toHex()}
              onChange={updatePickedColor}
              // prefixed
            />
          </Grid>

          <Grid item xs={12} className={classes.menuItem}>
            {pickedColor?.toRgbString().toUpperCase()}
          </Grid>

          <Grid item xs={12} className={classes.menuItem}>
            <Button
              variant="outlined"
              // color="primary"
              onClick={resetColor}
            >
              Reset
            </Button>
          </Grid>

          <Grid 
            item
            xs={12}
            className={classes.colorWarning}
            style={{ 
              maxWidth: '212px',
            }}
          >
            {
              colorWarning &&
              <Typography color="error" >
                <WarningIcon />
                {colorWarning}
              </Typography>
            }
          </Grid>
        </Grid>
      </Menu>
    </Grid>
  )
}
