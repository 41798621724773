import React, { useEffect, useState } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import {
Grid,
Hidden,
Typography,
} from '@material-ui/core/';
import { useSnackbar } from 'notistack';
import formatExpiration from '../../../../utils/format_expiration';
import useDashboardAccount from '../../Context/useDashboardAccount';

const useStyles = makeStyles((theme) => createStyles({
  container: {
    padding: theme.spacing(2),
  }
}));

export default function ActivePassesList(props) {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const { activePasses } = useDashboardAccount();

  return (
    <Grid container className={classes.container}>
      <Grid item xs={12} className={props.cssClasses.tableBody}>

        {/* Desktop */}
        <Hidden smDown>
          <Grid container className={props.cssClasses.tableHeader}>
            <Grid item xs={2} align="center" className={props.cssClasses.tableCell}>
              <Typography>
                Pass
              </Typography>
            </Grid>

            <Grid item xs={1} align="center" className={props.cssClasses.tableCell}>
              <Typography>
                Viewers
              </Typography>
            </Grid>

            <Grid item xs={2} align="center" className={props.cssClasses.tableCell}>
              <Typography>
                Purchased
              </Typography>
            </Grid>

            <Grid item xs={2} align="center" className={props.cssClasses.tableCell}>
              <Typography>
                Began
              </Typography>
            </Grid>

            <Grid item xs={2} align="center" className={props.cssClasses.tableCell}>
              <Typography>
                Used on Project
              </Typography>
            </Grid>

            <Grid item xs={3} align="center" className={props.cssClasses.tableCell}>
              <Typography>
                Pass ID
              </Typography>
            </Grid>
          </Grid>

          {
            activePasses?.map((pass, i) => (
              <Grid container key={'pass1'+i} className={props.cssClasses.tableRow}>
                <Grid item xs={2} key={'pass2'+i} align="center" className={props.cssClasses.tableCell}>
                  <Typography key={'pass3'+i}>
                    {pass.pass_type}
                  </Typography>
                </Grid>

                <Grid item xs={1} key={'pass4'+i} align="center" className={props.cssClasses.tableCell}>
                  <Typography key={'pass5'+i}>
                    {pass.max_allowed_users}
                  </Typography>
                </Grid>

                <Grid item xs={2} key={'pass4'+i} align="center" className={props.cssClasses.tableCell}>
                  <Typography key={'pass7'+i}>
                    {formatExpiration(pass.purchased_on)}
                  </Typography>
                  <Typography key={'pass7A'+i}>
                    Order #: {pass.order_id}
                  </Typography>
                </Grid>

                <Grid item xs={2} key={'pass6'+i} align="center" className={props.cssClasses.tableCell}>
                  <Typography key={'pass5'+i}>
                    {formatExpiration(pass.begins_on)}
                  </Typography>
                </Grid>

                <Grid item xs={2} key={'pass8'+i} align="center" className={props.cssClasses.tableCell}>
                  {pass.session_name}
                </Grid>

                <Grid item xs={3} key={'pass11'+i} align="center" className={props.cssClasses.tableCell}>
                  <Typography key={'pass12'+i}>
                    {pass._id}
                  </Typography>
                </Grid>
                
              </Grid>
            ))
          }
        </Hidden>

        {/* Mobile */}
          {
            activePasses &&
            <Hidden mdUp>
              {activePasses.map((pass, i) => (
                <Grid container key={'pass-4'+i} className={props.cssClasses.tableRow}>
                  <Grid container key={'pass-3'+i} className={props.cssClasses.tableHeader}>
                    <Grid item xs={12} key={'pass-2'+i} className={props.cssClasses.tableCell}>
                    <Typography key={'pass-1'+i}>
                      Pass
                    </Typography>
                    </Grid>
                  </Grid>
                  
                  <Grid item xs={6} key={'pass0'+i} align="center" className={props.cssClasses.tableCell}>
                    <Typography key={'pass1'+i}>
                      Viewers
                    </Typography>
                  </Grid>
                  <Grid item xs={6} key={'pass2'+i} align="center" className={props.cssClasses.tableCell}>
                    <Typography key={'pass3'+i}>
                      Begins
                    </Typography>
                  </Grid>
                  <Grid item xs={6} key={'pass4'+i} align="center" className={props.cssClasses.tableCell}>
                    <Typography key={'pass5'+i}>
                      {pass.max_allowed_users}
                    </Typography>
                  </Grid>
                  <Grid item xs={6} key={'pass4'+i} align="center" className={props.cssClasses.tableCell}>
                    <Typography key={'pass4A'+i}>
                      {formatExpiration(pass.begins_on)}
                    </Typography>
                  </Grid>

                  <Grid item xs={12} key={'pass5'+i} className={classes.spacer}></Grid>

                  <Grid item xs={6} key={'pass5A'+i} align="center" className={props.cssClasses.tableCell}>
                    <Typography key={'pass5B'+i}>
                      Purchased
                    </Typography>
                  </Grid>
                  <Grid item xs={6} key={'pass6'+i} align="center" className={props.cssClasses.tableCell}>
                    <Typography key={'pass7'+i}>
                      {formatExpiration(pass.purchased_on)}
                    </Typography>
                    <Typography key={'pass7A'+i}>
                      Order #: {pass.order_id}
                    </Typography>
                  </Grid>

                  <Grid item xs={12} key={'pass7B'+i} className={classes.spacer}></Grid>

                  <Grid item xs={12} key={'pass8'+i} align="center" className={props.cssClasses.tableCell}>
                    {pass.session_name}
                  </Grid>

                  <Grid item xs={12} key={'pass8A'+i} className={classes.spacer}></Grid>

                  <Grid item xs={12} key={'pass9'+i} align="center" className={props.cssClasses.tableCell}>
                    <Typography key={'pass10'+i}>
                      Pass ID
                    </Typography>
                  </Grid>
                  <Grid item xs={12} key={'pass11'+i} align="center" className={props.cssClasses.tableCell}>
                    <Typography key={'pass12'+i}>
                      {pass._id}
                    </Typography>
                  </Grid>

                  <Grid item xs={12} className={classes.spacer}></Grid>

                </Grid>
              ))}
          </Hidden>
        }

      </Grid>
    </Grid>
  );

}
