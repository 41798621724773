import React, { useEffect, useState } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { useSnackbar } from 'notistack';
import {
Dialog,
DialogTitle,
DialogContent,
DialogContentText,
DialogActions,
TextField,
Input,
Button,
Table,
TableContainer,
TableHead,
TableBody,
TableRow,
TableCell,
FormControlLabel,
Switch,
Select,
MenuItem,
Backdrop,
CircularProgress,
Grid,
} from '@material-ui/core/';
import axios from 'axios';

const useStyles = makeStyles((theme) => createStyles({
  redButton: {
    backgroundColor: theme.palette.warning.main,
    '&:hover': {
      backgroundColor: theme.palette.warning.dark
    }
  }
}));

export default function UserSearchDialog(props) {
  const classes = useStyles();

  return (
    <Dialog
      open={props.isOpen}
      onClose={props.toggleOpenSearchDialog}
    >
      <DialogTitle id="form-dialog-title">
        Search for user
      </DialogTitle>

      <DialogContent>
        <DialogContentText>
          Update me
        </DialogContentText>

        <TableContainer>
          <Table size="small">
            <TableHead>

              <TableRow>
                <TableCell>
                  Woo ID
                </TableCell>

                <TableCell>
                  User Name
                </TableCell>

                <TableCell>
                  Email
                </TableCell>

                <TableCell>
                  First Name
                </TableCell>

                <TableCell>
                  Last Name
                </TableCell>

              </TableRow>

            </TableHead>
            <TableBody>
              {
                props.users.map((user, i) => (
                  <TableRow key={'row'+i}>
                    <TableCell key={'id'+i}>
                      <Button
                        onClick={() => props.getSearchedUser(user.id)}
                        type="text"
                        color="primary"
                      >
                        {user.id}
                      </Button>
                    </TableCell>

                    <TableCell key={'un'+i}>
                      <Button
                        onClick={() => props.getSearchedUser(user.id)}
                        type="text"
                        color="primary"
                      >
                        {user.username}
                      </Button>
                    </TableCell>

                    <TableCell key={'em'+i}>
                      <Button
                        onClick={() => props.getSearchedUser(user.id)}
                        type="text"
                        color="primary"
                      >
                        {user.email}
                      </Button>
                    </TableCell>

                    <TableCell key={'fn'+i}>
                      <Button
                        onClick={() => props.getSearchedUser(user.id)}
                        type="text"
                        color="primary"
                      >
                        {user.first_name}
                      </Button>
                    </TableCell>

                    <TableCell key={'ln'+i}>
                      <Button
                        onClick={() => props.getSearchedUser(user.id)}
                        type="text"
                        color="primary"
                      >
                        {user.last_name}
                      </Button>
                    </TableCell>
                  </TableRow>
                ))
              }
            </TableBody>
          </Table>
        </TableContainer>

      </DialogContent>

      <DialogActions>
        <Button
          variant="contained"
          color="primary"
          onClick={props.toggleOpenSearchDialog}
        >
          Cancel
        </Button>
      </DialogActions>

      <Backdrop open={props.inProgress}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </Dialog>

  );

}
