import React, { useEffect, useState, createContext } from 'react';
import { useTheme, createTheme, ThemeProvider } from '@material-ui/core';
import { colord, RgbaColor } from 'colord';

export const BrandingContext = createContext({});

export function BrandingProvider(props) {
  let parsedURL = window.location.pathname.split('/');
  var urlType;
  var urlUuid;
  if (parsedURL.length > 1 && parsedURL[1] !== "") {
    urlType = parsedURL[1]
  }
  if (parsedURL.length > 2 && parsedURL[2] !== "") {
    urlUuid = parsedURL[2];
  }

  const [inProgress, setInProgress] = useState<Boolean>(false)
  const [customerUuid, setCustomerUuid] = useState<String | undefined>()
  
  // Used in App
  const [brandName, setBrandName] = useState<String | undefined>(process.env.REACT_APP_NAME)
  const [brandLogoUrl, setBrandLogoUrl] = useState<String | undefined>()
  const [brandPrimaryColor, setBrandPrimaryColor] = useState<RgbaColor | undefined>()
  const [brandSecondaryColor, setBrandSecondaryColor] = useState<RgbaColor | undefined>()
  
  // Saved in DB
  const [storedBrandName, setStoredBrandName] = useState<String | undefined>(process.env.REACT_APP_NAME)
  const [storedBrandPrimaryColor, setStoredBrandPrimaryColor] = useState<RgbaColor | undefined>()
  const [storedBrandSecondaryColor, setStoredBrandSecondaryColor] = useState<RgbaColor | undefined>()

  const appTheme = useTheme()
  const [brandTheme, setBrandTheme] = useState<Theme>(createTheme(appTheme))

  // Fetch branding and logo on load
  useEffect(() => {
    fetchBranding(urlUuid)
  }, []);

  // Update Colors
  useEffect(() => {
    let primary = {
      main: appTheme.palette.primary.main,
      dark: appTheme.palette.primary.dark,
      light: appTheme.palette.primary.light,
    }

    let secondary = {
      main: appTheme.palette.secondary.main,
      dark: appTheme.palette.secondary.dark,
      light: appTheme.palette.secondary.light,
    }

    if (
      brandPrimaryColor
    ) {
      primary.main = brandPrimaryColor
      primary.dark = colord(brandPrimaryColor).darken(0.15).toRgbString()
      primary.light = colord(brandPrimaryColor).lighten(0.2).toRgbString()
    }

    if (
      brandSecondaryColor
    ) {
      secondary.main = brandSecondaryColor
      secondary.dark = colord(brandSecondaryColor).darken(0.15).toRgbString()
      secondary.light = colord(brandSecondaryColor).lighten(0.2).toRgbString()
    }

      let newBrandTheme = createTheme({
        ...appTheme,
        palette: {
          ...appTheme.palette,
          primary: primary,
          secondary: secondary
        }
      })
      setBrandTheme(newBrandTheme)
  }, [brandPrimaryColor, brandSecondaryColor])

  // Actions
  const fetchBranding = async (userUuid) => {
    if (!urlType || !userUuid) { return }
    setInProgress(true)
    
    // Fail silently if we do not have custom branding
    try {
      let res = await fetch(process.env.REACT_APP_HOST_URL + '/api/custom_branding/' + urlType + '/' + userUuid);
      if (res.ok) {
        let response = await res.json()

        if (response.customer_uuid) { 
          setCustomerUuid(response.customer_uuid)
        }
        if (response.brandName) { 
          setBrandName(response.brandName) 
          setStoredBrandName(response.brandName)
          document.title = response.brandName
        }
        if (response.brandLogoUrl) {
          setBrandLogoUrl(process.env.REACT_APP_STATIC_URL + '/' + response.brandLogoUrl)
          let favi = document.getElementById("favicon")
          if (favi) {
            favi.href = process.env.REACT_APP_STATIC_URL + '/' + response.brandLogoUrl
          }
        }
        if (response.brandPrimaryColor) { 
          setBrandPrimaryColor(colord(response.brandPrimaryColor).toRgbString())
          setStoredBrandPrimaryColor(colord(response.brandPrimaryColor).toRgbString()) 
        }
        if (response.brandSecondaryColor) { 
          setBrandSecondaryColor(colord(response.brandSecondaryColor).toRgbString()) 
          setStoredBrandSecondaryColor(colord(response.brandSecondaryColor).toRgbString()) 
        }
      } 
    } catch (error) {
    } finally { setInProgress(false) }
  }

  return (
    <BrandingContext.Provider 
      value={{
        brandName,
        setBrandName,
        storedBrandName,
        brandLogoUrl,
        brandPrimaryColor,
        setBrandPrimaryColor,
        storedBrandPrimaryColor,
        brandSecondaryColor,
        setBrandSecondaryColor,
        storedBrandSecondaryColor,
        brandTheme,
        customerUuid,
        fetchBranding
      }}
    >
      <ThemeProvider theme={brandTheme}>
        {props.children}
      </ThemeProvider>
    </BrandingContext.Provider>
  );
}
