import React, { Component } from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import { AppBar, Badge, Card, CardMedia } from '@material-ui/core';
import Chat from '../Chat/chat';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import Cookies from 'js-cookie';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { Container } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import { withSnackbar } from 'notistack';
import { createTheme } from '@material-ui/core/styles';
import Pusher from 'pusher-js/with-encryption';
import VolumeUp from '@material-ui/icons/VolumeUp';
import logoLoop from '../../videos/LogoLoop_Icon.mp4';
import BorderStyleIcon from '@material-ui/icons/BorderStyle';
import AspectRatioIcon from '@material-ui/icons/AspectRatio';
import ScreenFull from 'screenfull';
import { isMobile } from '../../utils';
import Hidden from '@material-ui/core/Hidden';
import Link from '@material-ui/core/Link';
import BottomDrawer from '../Elements/BottomDrawer';
import AudioOutputSelect from '../Project/audio_output_select';
import VC from '../Twilio/VC.tsx';
import logToServer from '../../utils/log_to_server';
import Sketches from '../Sketches/sketches';
import GestureIcon from '@material-ui/icons/Gesture';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import CancelIcon from '@material-ui/icons/Cancel';
import { isTouchCapable } from '../../utils/isTouchCapable';
import PlayCircleOutlineOutlined from '@material-ui/icons/PlayCircleOutlineOutlined';
import { PictureInPicture } from '@material-ui/icons';
import ViewerWaitingRoom from './ViewerWaitingRoom';
import updateSdpAudioParameters from '../Project/Camera/CameraProvider/Utils/updateSdpAudioParameters';
import BrandLogo from '../Branding/BrandLogo';

const axios = require('axios').default;

const useStyles = ((theme) => ({
  root: {
    display: 'flex',
    marginTop: theme.spacing(1),
    [theme.breakpoints.down('sm')]: {
      paddingBottom: '36px'
    }
  },
  content: {
    margin:0,
    flex:1,
  },
  mainPieces: {
    width: 'calc(100vw - 316px)',
    maxWidth: 'calc(100vw - 316px)',
    margin:'auto 308px auto 8px',
    height: 'calc(100vh - 256px)',
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      maxWidth: '100%',
      width: '100%',
      height: 'auto',
      margin: 'auto',
    },
    transition: 'width 600ms, max-width 600ms, margin 600ms',
  },
  mainPiecesNoChat: {
    width: 'calc(100vw - 6px)',
    maxWidth: 'calc(100vw - 6px)',
    margin:'auto',
    height: 'calc(100vh - 256px)',
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      maxWidth: '100%',
      width: '100%',
      height: 'auto',
    },
    transition: 'width 600ms, max-width 600ms, margin 600ms',
  },
  video: {
    // backgroundColor: theme.palette.grey[900],
    backgroundColor: theme.palette.background.paper,
    margin: theme.spacing(2),
    paddingTop: "55.4%",
    position: 'relative'
  },
  videoPlayer: {
    // backgroundColor: theme.palette.grey[900],
    backgroundColor: theme.palette.background.paper,
    border: 0,
    overflow: 'auto',
    resize: 'both',
    width: '100%',
    position: 'absolute',
    top: 0,
  },
  videoCard: {
    [theme.breakpoints.up('sm')]: {
      '&:hover div': {
        visibility: 'visible'
      },
    },
    backgroundColor: theme.palette.background.paper,
    paddingRight: '1px'
  },
  videoCardMobile: {
    backgroundColor: theme.palette.background.paper,
    paddingRight: '1px'
  },
  "@media (orientation: landscape)": {
    videoDisplay: {
      display:"flex",
      flexWrap:"wrap",
      justifyContent: 'center',
      margin:'auto',
      maxWidth:'100%',
      maxHeight: 'calc(100vh - 256px)',
      [theme.breakpoints.down('sm')]: {
        height: 'auto'
      },
      transition: 'width 600ms, max-width 600ms, height 600ms, flex 600ms',
    },
    videoDisplayNoVC: {
      display:"flex",
      flexWrap:"wrap",
      justifyContent: 'center',
      margin:'auto',
      maxWidth:'100%',
      maxHeight: 'calc(100vh - 56px)',
      [theme.breakpoints.down('sm')]: {
        height: 'auto'
      },
      transition: 'width 600ms, max-width 600ms, height 600ms, flex 600ms',
    },
    videoDisplayOne: {
      width: 'calc((100vh - 260px) * (16 / 9))',
      maxWidth: '100%',
      // transition: 'width 600ms, max-width 600ms, flex 600ms',
    },
    videoDisplayOneNoVC: {
      width: 'calc((100vh - 60px) * (16 / 9))',
      maxWidth: '100%',
      // transition: 'width 600ms, max-width 600ms, flex 600ms',
    },
    videoDisplayTwo: {
      width: '50%',
      maxWidth:'calc((100vh - 260px) * (16 / 9))',
      // transition: 'width 600ms, max-width 600ms, flex 600ms',
    },
    videoDisplayTwoNoVC:{
      width: '50%',
      maxWidth:'calc((100vh - 60px) * (16 / 9))',
      // transition: 'width 600ms, max-width 600ms, flex 600ms',
    },
    videoDisplayQuad: {
      width: 'calc((100vh - 260px) * (16 / 18))',
      maxWidth: '50%',
      // transition: 'width 600ms, max-width 600ms, flex 600ms',
    },
    videoDisplayQuadNoVC: {
      width: 'calc((100vh - 60px) * (16 / 18))',
      maxWidth: '50%',
      // transition: 'width 600ms, max-width 600ms, flex 600ms',
    },
  },
  "@media (orientation: portrait)": {
    videoDisplay: {
      display:"flex",
      flexWrap:"wrap",
      justifyContent: 'center',
      margin:'auto',
      maxWidth:'calc((100vh - 260px) * (16 / 9))',
      maxHeight: 'calc(100vh - 256px)',
      [theme.breakpoints.down('sm')]: {
        height: 'auto',
        maxHeight: "fit-content"
      },
      transition: 'width 600ms, max-width 600ms, height 600ms, flex 600ms',
    },
    videoDisplayNoVC: {
      display:"flex",
      flexWrap:"wrap",
      justifyContent: 'center',
      margin:'auto',
      maxWidth:'calc((100vh - 60px) * (16 / 9))',
      maxHeight: 'calc(100vh - 56px)',
      [theme.breakpoints.down('sm')]: {
        height: 'auto',
        maxHeight: "fit-content"
      },
      transition: 'width 600ms, max-width 600ms height 600ms, flex 600ms',
    },
    videoDisplayOne: {
      width: '100%',
      maxWidth: '100%',
      // transition: 'width 600ms, max-width 600ms, flex 600ms',
    },
    videoDisplayOneNoVC: {
      width: '100%',
      maxWidth: '100%',
      // transition: 'width 600ms, max-width 600ms, flex 600ms',
    },
    videoDisplayTwo: {
      width: '100%',
      maxWidth:'calc((100vh - 260px) * (16 / 18))',
      // transition: 'width 600ms, max-width 600ms, flex 600ms',
    },
    videoDisplayTwoNoVC:{
      width: '100%',
      maxWidth:'calc((100vh - 60px) * (16 / 18))',
      // transition: 'width 600ms, max-width 600ms, flex 600ms',
    },
    videoDisplayQuad: {
      width: '50%',
      [theme.breakpoints.down('xs')]: {
        width: '100%'
      },
      maxWidth: '100%',
      // transition: 'width 600ms, max-width 600ms, flex 600ms',
    },
    videoDisplayQuadNoVC: {
      width: '50%',
      [theme.breakpoints.down('xs')]: {
        width: '100%'
      },
      maxWidth: '100%',
      // transition: 'width 600ms, max-width 600ms, flex 600ms',
    },
  },
  videoControlsHidden: {
    display:'flex',
    position: 'absolute',
    zIndex: 20,
    top: 0,
    left: 0,
    width: '100%',
    opacity: 1.0,
    visibility: 'collapse',
  },
  videoControlsShown: {
    display:'flex',
    position: 'absolute',
    zIndex: 20,
    top: 0,
    left: 0,
    width: '100%',
    opacity: 1.0,
    visibility: 'visible',
  },
  videoControlsMobile: {
    display: 'flex',
    width: '100%'
  },
  videoControlsMobileFooter: {
    top: 'auto',
    bottom: 0,
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.common.white,
    zIndex: 999,
    marginTop: theme.spacing(2),
    boxShadow: "0px -6px 6px -3px rgb(0 0 0 / 20%), 0px 10px 14px 1px rgb(0 0 0 / 14%), 0px 4px 18px 3px rgb(0 0 0 / 12%)",
  },
  videoControlText: {
    display: 'block',
  },
  switcherControls: {
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: '2px',
    marginBottom: '4px',
    width: '100%',
    textAlign: 'center',
    position: 'fixed',
    top: '4px',
    left: 0,
    paddingRight: '300px',
    zIndex: 1001,
    display: 'flex',
    justifyContent: 'center',
    [theme.breakpoints.down('sm')]: {
      position: 'relative',
      padding: 0,
      zIndex: 1,
      top: 0
    },
    transition: 'padding 600ms'
  },
  switcherControlsNoChat: {
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: '2px',
    marginBottom: '4px',
    width: '100%',
    textAlign: 'center',
    position: 'fixed',
    top: '4px',
    left: 0,
    zIndex: 1001,
    display: 'flex',
    justifyContent: 'center',
    [theme.breakpoints.down('sm')]: {
      position: 'relative',
      padding: 0,
      zIndex: 1,
      top: 0
    },
    transition: 'padding 600ms'
  },
  iconButton: {
    width: '32px',
    height: '32px',
    padding: '4px'
  },
  paper: {
    margin: 0,
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: 'auto',
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  chatWindow: {
    height: '100%',
    position: 'fixed',
    right: 0,
    [theme.breakpoints.down('sm')]: {
      position: 'relative'
    },
    transition: 'width 600ms',
  },
  chatWindowNoText: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    padding: theme.spacing(0, 0, 1, 0),
    maxHeight: 'calc(100vh - 56px)',
    position: 'fixed',
    zIndex: 1200,
    right: 0,
    [theme.breakpoints.down('sm')]: {
      position: 'relative'
    },
    width: '0px',
    transition: 'width 600ms',
  },
  chatPiece2: {
    display: 'flex',
    flex: 1
  },
  videoChat: {
    display:'flex',
    position: 'relative',
    bottom: 0,
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      position: 'relative'
    }
  },
  videoChatHidden: {
    display: 'none'
  },
  signInBox: {
    width: 'calc(100% - 24px)',
    maxWidth: '400px !important',
    justifyContent: 'center',
    alignContent: 'center'
  },
  signInPaper: {
    margin: 'auto',
    marginTop: theme.spacing(24),
    padding: theme.spacing(12),
  },
  signInLogoBox: {
    margin: "auto",
    maxWidth: "50px",
    maxHeight: "50px",
  },
  signInTitle: {
    paddingTop: theme.spacing(6),
    margin: 'auto',
    textAlign: 'center'
  },
  statusDisplay: {
    padding: theme.spacing(2),
    backgroundColor: '#3D3D3D',
    justifyContent: 'center',
    textAlign: 'center',
    width: '100%',
    borderRadius: '4px',
    color: '#FFFFFF'
  },
  sectionTitle: {
    width: '100%',
    maxWidth: '100%',
    padding: '8px',
    backgroundColor: '#333333',
    borderRadius: '4px',
    color: '#ffffff'
  },
}));

class ClientViewer extends Component {
  constructor(props) {
    super(props)
    let subscriber_uuid;
    let user_uuid;
    let cookies = false;
    let isChatOpen = true;
    let isVCOpen = !isMobile;
    let useCodeAuth = false;
    let useHashAuth = false;
    let wrHash;
    let nickName = localStorage.getItem('ss_nickname')

    let parsedURL = window.location.pathname.split('/');
    
    switch (parsedURL?.length) {
      case 5:
        // Waiting Room with embedded code
        useHashAuth = true;

        if (
          parsedURL[4] &&
          parsedURL[4] !== ''
        ) {
          wrHash = parsedURL[4]
        }
      // Fall through to case 3 to share the code
      case 3:
        // Waiting Room with seperate code
        useCodeAuth = true
        subscriber_uuid = parsedURL[2]
        props.setRoom(parsedURL[2])

        let storedId = localStorage.getItem(parsedURL[2] + "_wr")
        if (storedId && storedId !== '') {
          user_uuid = storedId
          props.setViewerId(storedId) // Used for logging out
          cookies = Cookies.get(storedId);
        }
        break;

      case 4:
        // Email Invite 
        if (parsedURL[3] !== '' && parsedURL[3] !== 'wr') {
          subscriber_uuid = parsedURL[2];
          props.setRoom(parsedURL[2])

          user_uuid = parsedURL[3];
          props.setViewerId(parsedURL[3]); // Used for logging out
          cookies = Cookies.get(parsedURL[3]);

          let storedTxtDrawer = localStorage.getItem(parsedURL[3] + "_txtdrawer");
          let storedVCDrawer = localStorage.getItem(parsedURL[3] + "_vcdrawer");
          isChatOpen = (storedTxtDrawer !== null && storedTxtDrawer === 'false') ? false : true;
          isVCOpen =  (storedVCDrawer !== null && storedVCDrawer === 'true') ? true : false;
        }
        break;
      default:
        console.log('Incorrect URL');
        logToServer({
          section: 'Village',
          action: 'Incorrect URL',
          location: window.location.href,
          parsedURL: parsedURL
        });
        break;
    }

    this.state = {
      isLoading: false,
      isLoggedIn: false,
      cookies: cookies,
      useCodeAuth: useCodeAuth,
      useHashAuth: useHashAuth,
      wrHash: wrHash,
      is_active: false,
      pusher: null,
      channel: null,
      tracker: null,
      presenceRoom: null,
      members: null,
      nickName: nickName ? nickName : '',
      email: '',
      user_uuid: user_uuid,
      subscriber_uuid: subscriber_uuid,
      subscriber_password: '',
      status: 'Welcome!',
      rtcUrl: null,
      rtcJwt: null,
      rtcToken: null,
      rtcAccountID: null,
      iceServers: null,
      notify_is_allowed: false,
      quadIsFullScreen: false,
      unreadChats: 0,
      show_multicam_borders: true,
      showSketches: true,
      shownCameras: 1,
      sinkId: 'default',
      isVCOpen: isVCOpen,
      isChatOpen: isChatOpen,
      selectedCam: 0,
      showSketchControls: false,
      camera_labels: [
        'A',
        'B',
        'C',
        'D'
      ],
      camera_colors: [
        'rgba(226, 29, 29, 1)',
        'rgba(50, 171, 223, 1)',
        'rgba(226, 226, 29, 1)',
        'rgba(71, 185, 105, 1)',
      ],
      viewer_color: '#911d1d',
      cameras: [
        {
          label: 'A',
          color: 'rgba(226, 29, 29, 1)',
          enabled: true,
          videoSource: React.createRef(),
          videoBox: React.createRef(),
          videoStream: new MediaStream(),
          rtcStreamName: null,
          rtcConnection: null,
          isConnected: false,
          isStreamActive: false,
          poppedOut: false,
          supportsPip: false,
          fullScreen: false,
        }
      ],
      permissions: {
        textChat: true,
        videoChat: true,
        sketches: true,
        cams: {
          0: true,
          1: true,
          2: true,
          3: true
        }
      }
    }

    this.toggleChatDrawer = this.toggleChatDrawer.bind(this);
    this.toggleVCDrawer = this.toggleVCDrawer.bind(this);
    this.setSinkId = this.setSinkId.bind(this);
    this.unMuteACam = this.unMuteACam.bind(this);
    this.updateLoggedInStatus = this.updateLoggedInStatus.bind(this);
    this.updateNickName = this.updateNickName.bind(this);
    this.updateState = this.updateState.bind(this);

    // Debounce window resize
    let resizeTimer;
  }

  // Update state
  setFormState(event) {
    const name = event.target.name;
    let value;
    if (event.target.type === 'checkbox') {
      value = event.target.checked;
    } else {
      value = event.target.value;
    }
    this.setState(
      {...this.state, [name]: value}
    );
  }

  // Update cameras array
  updateCameras(cam_index, cam_key, cam_value) {
    if (this.state.cameras[cam_index]) {
      let new_cameras = this.state.cameras;
      new_cameras[cam_index][cam_key] = cam_value;
      this.setState({
        cameras: new_cameras
      });

      this.props.setSwitcherControls(this.switcherPieces(this.props.cssClasses));
    }
  }

  // Update Camera Audio Out
  setSinkId(sinkId) {
    if (
      this.state.cameras[0]?.videoSource?.current &&
      typeof this.state.cameras[0].videoSource.current.setSinkId === 'function'
    ) {
      this.state.cameras[0].videoSource.current.setSinkId(sinkId);
    }
    this.setState({
      sinkId: sinkId
    }, () => {
      this.props.setSwitcherControls(this.switcherPieces(this.props.cssClasses));
    })
  }

  // Form submission
  async submitForm(e) {
    e.preventDefault();
    this.setState({
      isLoading: true
    });

    let postData = {
      subscriber_uuid: this.state.subscriber_uuid,
      subscriber_password: this.state.subscriber_password,
      user_uuid: this.state.user_uuid
    }
    let fetchData = {
      method: 'POST',
      headers:{
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(postData)
    }

    try {
      let res = await fetch(process.env.REACT_APP_HOST_URL + '/api/vvlogin', fetchData);
      let result = await res.json();
      this.setState({
        isLoading: false
      });
      if (result?.type) {
        switch (result.type.toLowerCase()) {
          case 'success':
            this.props.enqueueSnackbar('Welcome ' + this.state.nickName, { variant: 'success', key: 'logged_in' });

            this.updateLoggedInStatus()
            break;
          case 'inactive':
            this.props.enqueueSnackbar(result.message, { variant: 'info' });
            this.setState({
              is_active: false,
            });
            console.log('Session is not active ', result.message);
            break;
          case 'error':
            console.log('error occured ', result.message);
            this.props.enqueueSnackbar(result.message, {variant: 'error'});
            break;
        }
      } else {
        throw new Error(result.message)
      }
    } catch (e) {
      console.log('Error sign in: ', e);
      this.props.enqueueSnackbar('Something went wrong. ' + e, {variant: 'error'});
    } finally {
      // Remove password from state
      this.setState({
        subscriber_password: '',
        isLoading: false
      });
    }

  }

  // Pusher connection
  createPusher() {
    // Create Pusher Connection
    let pusher = new Pusher(process.env.REACT_APP_PUSHER_KEY, {
      forceTLS: true,
      cluster: 'us3',
      userAuthentication: {
        endpoint: '/api/pusher/id/'+ this.state.subscriber_uuid + '/' + this.state.user_uuid
      },
      channelAuthorization: {
        endpoint: '/api/pusher/auth/'+ this.state.subscriber_uuid + '/' + this.state.user_uuid
      }
    });
    // pusher.signin()

    this.setState({
      pusher: pusher
    });

    // Detect connection errors
    pusher.connection.bind('error', (error) => {
      if (
        error?.data?.code !== 1006 &&
        error?.data?.code !== 4009
      ) {
        console.error('Socket Connection error ', error?.data);
        // logToServer({
        //   section: 'Project',
        //   action: 'Pusher Error',
        //   page: window.location.href,
        //   message: error?.data?.message,
        //   code: error?.data?.code,
        //   error: error
        // });
      }
    });

    // Subscribe to project chat channel
    var channel = pusher.subscribe('private-encrypted-' + this.state.subscriber_uuid);
    this.setState({
      channel: channel
    });

    // On Success
    channel.bind('pusher:subscription_succeeded', success => {
      // console.log('Chat subscribe success ');
      // Get current permissions on reconnect
      axios.get(process.env.REACT_APP_HOST_URL + '/api/vv_permissions/' + this.state.user_uuid)
      .then(res => {
        switch (res.data.type) {
          case 'success':
            if (res.data.user_permissions) {
              let [permissions,previous_enabled] = this.updatePermissions(res.data.user_permissions, res.data.enabled_cameras);
              // Update camera displays
              let cameras = this.state.cameras
              cameras.forEach((cam, i) => {
                cameras[i].enabled = typeof previous_enabled[i] === 'boolean' ? previous_enabled[i] : cam.enabled;
                // Change camera display if permissions changed
                if (previous_enabled[i] !== cam.enabled) {
                  this.switchDisplay(i, false);
                }
              });
              let enabledCameras = cameras.filter(cam => cam.enabled);
              this.setState({
                cameras:cameras,
                shownCameras:enabledCameras.length,
              });

            }
            break;
          case 'unauthorized':
            Cookies.remove(this.state.user_uuid, { domain: process.env.REACT_APP_DOMAIN, path: '/' });
            this.props.enqueueSnackbar(res.data.message, { variant: 'warning' });
            window.location.reload(false);
            break;
          default:

        }
      })
      .catch(err => {
        logToServer({
          section: 'Village',
          action: 'Error getting permissions',
          message: err.message,
          name: err.name,
          stack: err.stack,
        });
        console.error('Error - ', err);
      });
    });

    // Receive status message update
    channel.bind('status', message => {

      this.setState({
        status: message.status
      });

      // Notify user
      if (window.Notification) {
        try {
          new Notification(
            "New Status: " + message.status,
            {
              body: message.status,
              icon: "https://setstream.io/wp-content/uploads/2020/07/setstream_brand_Icon_512_01.png",
              badge: "https://setstream.io/wp-content/uploads/2020/07/setstream_brand_logo_color_512_01.png"
            }
          );
        }
        catch (e) {
          console.log('Could not set notification ', e);
        }
      }
      // console.log('STATUS received ', message);
    });

    // Receive activation call
    channel.bind('activate', message => {
      this.getRtcInfo();
      // console.log('Activating session ', message);
    });

    // Refresh the page if a pass or subscription expires or is removed
    channel.bind('remove_subscription', message => {
      window.location.reload(false);
    });

    // Receive multicam enables
    channel.bind('camera_enables', message => {

      // First close existing connections
      this.state.cameras.forEach((camera, i) => {
        if (camera.rtcConnection) {
          this.close_rtc_conn(i);
          // console.log('Closed RTC Conn ', i);
        }
      });

      // Create array of available cameras
      let cameras = [];
      let previousEnabled;
      try {
        previousEnabled = JSON.parse(localStorage.getItem(this.state.user_uuid + '_cams'));
      } catch (e) {
        console.error(' Error parsing local storage for %s', this.state.user_uuid+"_cams");
      }
      // Create blank object if nothing is return from localStorage
      if (!previousEnabled) {
        previousEnabled = {}
      }

      // Add camera data
      for (let i=0; i<message.enabled_cameras; i++) {
        // Enable cameras in localStorage
        previousEnabled[i] = this.state.permissions.cams[i];

        // Create available cameras array
        cameras.push(
          {
            label: this.state.camera_labels[i],
            color: this.state.camera_colors[i],
            enabled: previousEnabled[i],
            videoSource: React.createRef(),
            videoBox: React.createRef(),
            videoStream: new MediaStream(),
            rtcStreamName: null,
            rtcConnection: null,
            isConnected: false,
            isStreamActive: false,
            poppedOut: false,
            supportsPip: false,
            fullScreen: false,
          }
        );

      }

      // Enable all cameras by default
      localStorage.setItem(this.state.user_uuid + '_cams', JSON.stringify(previousEnabled));

      let enabledCameras = cameras.filter(cam => cam.enabled);

      // Store enabled cameras and create connections
      this.setState({
        cameras: cameras,
        shownCameras: enabledCameras.length
      }, () => {
        // Restart connection process
        this.getRtcInfo();
        this.bordersCheck();
        // console.log('Enabled Cameras ', message.enabled_cameras);
      });

    })

    // Reply to heartbeat message
    channel.bind('heartbeat', message => {
      axios.post(process.env.REACT_APP_HOST_URL + '/api/heartbeat/' + this.state.user_uuid, {
        room: this.state.subscriber_uuid,
        uuid: this.state.user_uuid,
      })
      .catch(err => {
        console.error('Heartbeat Error - ', err);
      });
    });

    // Subscribe to individual user room
    var soloroom = pusher.subscribe('private-' + this.state.subscriber_uuid + '@' + this.state.user_uuid);
    this.setState({
      soloroom: soloroom
    });

    // On Success
    soloroom.bind('pusher:subscription_succeeded', success => {
      // console.log('Soloroom subscribe success ');
    });

    // Receive logout message
    soloroom.bind('log_out', message => {
      this.props.enqueueSnackbar(message, { variant: "warning" })
      axios.post(process.env.REACT_APP_HOST_URL + '/api/vv_logout/' + this.state.user_uuid)
      .then(result => {
        Cookies.remove(this.state.user_uuid, {  domain: process.env.REACT_APP_DOMAIN });
        localStorage.removeItem(this.state.user_uuid + '_wr')
        window.location.reload(false);
        console.log('I have been logged out ', message);
        logToServer({
          section: 'Village',
          action: 'Logged Out via pusher Error',
          pusherMessage: message,
        });
      })
      .catch(err => {
        Cookies.remove(this.state.user_uuid, {  domain: process.env.REACT_APP_DOMAIN });
        localStorage.removeItem(this.state.user_uuid + '_wr')
        window.location.reload(false);
        console.log('There was an error ', err);
        logToServer({
          section: 'Village',
          action: 'Logged Out via pusher Error',
          message: err.message,
          name: err.name,
          stack: err.stack,
          pusherMessage: message,
        });
      });
    });

    // Update user permissions
    soloroom.bind('update_permissions', message => {

      // Get existing camera enables
      let cameras = this.state.cameras;

      // Create new permissions
      let permissions = this.state.permissions;

      switch (message.type) {
        case 'toggleTextChat':
          if (typeof message.permission === 'boolean') {
            permissions.textChat = message.permission;
          }
          this.setState({
            isChatOpen: message.permission
          })

          break;

        case 'toggleVideoChat':
          if (typeof message.permission === 'boolean') {
            permissions.videoChat = message.permission
          }
          this.setState({
            isVCOpen: message.permission
          })
          break;

        case 'toggleSketches':
          if (typeof message.permission === 'boolean') {
            permissions.sketches = message.permission
          }
          break;

        case 'toggleCams':
          if (typeof message.permission === 'boolean' && typeof message.cam_index === 'number') {
            permissions.cams[message.cam_index] = message.permission;
            if (cameras[message.cam_index]) {
              cameras[message.cam_index].enabled = message.permission;
              this.switchDisplay(message.cam_index, false);
            }
          }
          break;
      }

      let enabledCameras = cameras.filter(cam => cam.enabled);

      // Store
      this.setState({
        permissions: permissions,
        cameras: cameras,
        shownCameras: enabledCameras.length
      }, () => {
        switch (message.type) {
          case 'toggleTextChat':
            break;
          case 'toggleVideoChat':
            break;
          case 'toggleCams':
            if (typeof message.cam_index === 'number') {
              if (message.permission) { // Allow access
                // console.log('Subscribing to ', this.state.cameras[message.cam_index]);
                this.getSubscribe(message.cam_index);
                this.bordersCheck();
              } else { // Deny access
                this.close_rtc_conn(message.cam_index);
                this.bordersCheck();
              }
            }
            break;
        }
      });
    });

    // Presence Channel
    var presenceRoom = pusher.subscribe('presence-' + this.state.subscriber_uuid);
    this.setState({
      presenceRoom: presenceRoom
    })

    // Presence Events
    presenceRoom.bind('pusher:subscription_succeeded', (members) => {
      this.setState({
        members: members
      });
    });

    presenceRoom.bind('pusher:member_added', (member) => {
      this.setState({
        members: presenceRoom.members
      });
    });

    presenceRoom.bind('pusher:member_removed', (member) => {
      this.setState({
        members: presenceRoom.members
      });
    });
    
  }

  // RTC Info
  // This api call begins the entire connection process
  getRtcInfo() {
    let cookie = Cookies.get(this.state.user_uuid);
    if (cookie) {
      this.setState({
        isLoading: true
      });

      // Get sub token
      axios.get(process.env.REACT_APP_HOST_URL + '/api/vvlogin/' + this.state.user_uuid)
      .then(res => {
        this.setState({
          isLoading: false
        });

        switch (res.data.type) {
          case 'success':

            let [permissions, previous_select] = this.updatePermissions(res.data.user_permissions, res.data.enabled_cameras);

            // Create array of available cameras
            let cameras = [];
            // Add camera data
            for (let i=0; i<res.data.enabled_cameras; i++) {
              cameras.push(
                {
                  label: this.state.camera_labels[i],
                  color: this.state.camera_colors[i],
                  enabled: previous_select[i],
                  videoSource: React.createRef(),
                  videoBox: React.createRef(),
                  videoStream: new MediaStream(),
                  rtcStreamName: null,
                  rtcConnection: null,
                  isConnected: false,
                  isStreamActive: false,
                  poppedOut: false,
                  supportsPip: false,
                  fullScreen: false,
                }
              )
            }

            let enabledCameras = cameras.filter(cam => cam.enabled);

            // Store
            this.setState({
              cameras: cameras,
              shownCameras: enabledCameras.length,
              permissions: permissions,
            }, () => {
              this.bordersCheck();
            })

            // Update each camera record and create a peer connection and video event listeners
            cameras.forEach((camera, cam_index) => {
              // if (camera.allowed) {
                // Find this cameras stream name
                let streamName = res.data.streams.filter(stream => ( stream.streamName.split('_')[0] === cameras[cam_index].label ));
                if (streamName.length > 0 && streamName[0].streamName) {
                  cameras[cam_index].rtcStreamName = streamName[0].streamName
                } else {
                  console.error('Could not find stream name ', streamName);
                }

                // Get html elements
                let video_display = document.getElementById('display_' + cam_index);
                cameras[cam_index].video_display = video_display;

                // ***** Add video player event listeners *****

                // Volume / mute changes
                video_display.addEventListener('volumechange', e => {
                  // Force an update to display muted status
                  this.forceUpdate();
                });

                // Video is playing
                video_display.addEventListener('play', e => {

                  // Check if PIP is supported
                  let pip = this.state.cameras[cam_index]?.supportsPip

                  // Check for chromium / gecko pip
                  if (document.pictureInPictureEnabled) {
                    pip = document.pictureInPictureEnabled ? true : false;
                  }

                  // Check for webkit Support iOS
                  if (video_display.webkitSupportsPresentationMode) {
                    pip = video_display.webkitSupportsPresentationMode('picture-in-picture') ? true : pip;
                  }

                  // Store
                  this.updateCameras(cam_index, 'supportsPip', pip);
                });

                video_display.addEventListener('pause', async (e) => {
                  // Restart playing automatically
                  try {
                    await video_display.play();
                  } catch (error) { /* Ignore */ }
                });

                // Chromium Picture in Picture activated
                video_display.addEventListener('enterpictureinpicture', e => {
                  this.updateCameras(cam_index, 'poppedOut', true);
                });

                // Chromium Left Picture in picture
                video_display.addEventListener('leavepictureinpicture', e => {
                  this.updateCameras(cam_index, 'poppedOut', false);
                })

                // Webkit Picture in Picture - NOT WORKING
                video_display.addEventListener('onwebkitpresentationmode', e => {

                  switch (video_display.webkitPresentationMode) {
                    case 'picture-in-picture':
                      this.updateCameras(cam_index, 'poppedOut', true);
                      break;
                    default:
                      this.updateCameras(cam_index, 'poppedOut', false);
                  }
                })

                // Entering Full Screen - Chromium
                video_display.addEventListener('onfullscreenchange', async (e) => {
                  // Keep video playing on fullscreen change
                  if (video_display.paused) {
                    try {
                      await video_display.play();
                    } catch (error) { /* Ignore */ }
                  }
                  // Store fullscreen status
                  this.updatePicture(cam_index, 'fullscreen', document.fullScreenElement)
                });

                // Entering Full Screen Webkit
                video_display.addEventListener('onwebkitfullscreenchange', async (e) => {
                  // Keep video playing on fullscreen change
                  if (video_display.paused) {
                    try {
                      await video_display.play();
                    } catch (error) { /* Ignore */ }
                  }
                  // Store fullscreen status
                  this.updatePicture(cam_index, 'fullscreen', video_display.webkitIsFullScreen);
                });


                // Metadata loaded
                video_display.addEventListener('loadedmetadata', e => {
                  // console.log('Cam ', cam_index, ' Metadata loaded ');
                })
              // }
            });

            // Store then create connections
            this.setState({
              status: res.data.status,
              is_active: true,
              rtcToken: res.data.token,
              rtcAccountID: res.data.accountId,
              cameras: cameras,
              viewer_color: res.data.color
            },
            () => {

              // Create Pusher connection if it doesn't exist
              if (!this.state.pusher) {
                this.createPusher();
              }

              // Set camera sizes
              this.switchDisplay(res.data.enabled_cameras - 1, false);

              // Create RTC Peer Connections
              this.state.cameras.forEach((camera, i) =>{
                if (this.state.cameras[i].enabled) {
                  this.getSubscribe(i);
                }
              });
            });
            break;

          case 'inactive':
            // Set viewer color even when inactive.  This ensures that marker and sketch colors
            // are correct when a viewer logs in before a session is active
            if (res.data?.color) {
              this.setState({
                viewer_color: res.data.color
              })
            }
            this.props.enqueueSnackbar(res.data.message, { variant: 'warning' });
            console.log('Web No active pass');
            break;

          case 'logged_out':
            // Cookies.remove(this.state.user_uuid, { domain: process.env.REACT_APP_DOMAIN, path: '/' });
            logToServer({
              section: 'Village',
              action: 'Logged Out when getting RTC Info',
              data: res.data,
            });
            this.props.enqueueSnackbar(res.data.message, { variant: 'warning' });
            // window.location.reload(false);

            break;
          case 'unauthorized': 
            this.props.enqueueSnackbar(res.data.message, { variant: 'warning' })
            window.location.reload(false)
            break; 

          case 'error':
            logToServer({
              section: 'Village',
              action: 'Error when getting RTC Info',
              data: res.data,
            });
            this.props.enqueueSnackbar(res.data.message, { variant: 'error' });
            console.error(res.data.message);
            break;
        }

      })
      .catch(err => {
        this.setState({
          isLoading: false
        });
        logToServer({
          section: 'Village',
          action: 'Network Error when getting RTC Info',
          message: err.message,
          name: err.name,
          stack: err.stack,
        });
        this.props.enqueueSnackbar('Looks like we had an issue connecting. -RTC URL Error-  Please refresh the page or contact support.', { variant: 'error' });
        console.error('Error - Could not get rtc URL', err);
      });
    } else {
      this.props.enqueueSnackbar('Please authenticate', { variatnt: 'warning'});
      console.log('Please authenticate');
    }
  }

  getSubscribe(cam_index) {
    if (!this.state.cameras[cam_index]) { 
      console.log("Camera ", cam_index, " does not exist.  Not connecting")
      return 
    }

    // Cancel existing auto reconnect attempts since we are trying to connect
    if (this.state.cameras[cam_index]?.reconnectTimer) {
      clearTimeout(this.state.cameras[cam_index].reconnectTimer)
      this.updateCameras(cam_index, "reconnectTimer", null)
    }

    if (this.state.permissions.cams[cam_index]) {
      let opts = {
        url: 'https://director.millicast.com/api/director/subscribe',
        method: 'POST',
        headers: {
          Authorization: 'Bearer ' + this.state.rtcToken,
          'Content-Type': 'application/json'
        },
        data: {
          "streamAccountId": this.state.rtcAccountID,
          "streamName": this.state.cameras[cam_index]?.rtcStreamName,
          "unauthorizedSubscribe": false,
        }
      }

      // Get subscribe info and connect
      axios(opts)
        .then(list => {
          if (list.data.status === 'success') {
            if (this.state.iceServers) {
              this.setState({
                rtcUrl: list.data.data.wsUrl,
                rtcJwt: list.data.data.jwt
              },
              () => {
                this.connectRTC(cam_index);
                // console.log('Connecting RTC for Cam ', this.state.cameras[cam_index]?.label);
              });
            } else {
                this.getIceServers()
                .then(ice => {
                  this.setState({
                    rtcUrl: list.data.data.wsUrl,
                    rtcJwt: list.data.data.jwt,
                    iceServers: ice
                  },
                  () => {
                    this.connectRTC(cam_index);
                    // console.log('Connecting RTC for Cam ', this.state.cameras[cam_index]?.label);
                  })
                })
                .catch(err => {
                  logToServer({
                    section: 'Village',
                    action: 'Error getting ICE Info',
                    data: list.data,
                    message: err.message,
                    name: err.name,
                    stack: err.stack
                  });
                  this.props.enqueueSnackbar("Looks like we had an issue - VVICE")
                  console.error('ICE Error - Could not get ICE servers ', err);
                });
            }
          } else {
            logToServer({
              section: 'Village',
              action: 'Bad Response when getting ICE Info',
              data: list.data,
            });
            this.props.enqueueSnackbar("Looks like we had an issue - VV2SI")
            console.error('Could not get VV2 sub info ', list.data.type);
          }
        })
        .catch(err => {
          if (err && err.response && err.response.status && err.response.status === 400) {
            // Stream has not begun.  Check back in 3 sec.
            if (this.state.cameras[cam_index]?.reconnectTimer) {
              clearTimeout(this.state.cameras[cam_index].reconnectTimer)
            }
            let timeout = setTimeout(() => {this.getSubscribe(cam_index)}, 3000);
            this.updateCameras(cam_index, "reconnectTimer", timeout)
          } else {
            // An error occured.  Check back in 3 sec in case it resolves itself
            // setTimeout(() => {this.getSubscribe(cam_index)}, 3000);
            logToServer({
              section: 'Village',
              action: 'Error connecting',
              message: err.message,
              name: err.name,
              stack: err.stack
            });
            console.error('Error - Could not get connect info', err);
            this.props.enqueueSnackbar('Connection failed ' + err.message + ' Please refresh the page and try again.', { variant: 'error' })
          }
        });
    } else {
      console.log('Permission denied for cam ', cam_index);
    }
  }

  // *************************************************************************

  getIceServers() {
    return new Promise(function(resolve, reject) {
      let iceServers = [];
      axios.put('https://turn.millicast.com/webrtc/_turn')
      .then(ice => {
        if (ice.status === 200) {
          if (ice.data.s !== 'ok') {
            console.log('ICE return is not OK ', ice.data);
            resolve(iceServers);
          }
          let list = ice.data.v.iceServers;
          list.forEach((cred, i) => {
            let v = cred.url;
            if (!!v) {
              cred.urls = v;
              delete cred.url;
            }
            iceServers.push(cred);
          });
          resolve(iceServers)
        } else {
          console.log('Could not retreive ICE servers ', ice);
          resolve(iceServers);
        }
      })
      .catch(err => {
        logToServer({
          section: 'Village',
          action: 'Error getting ICE servers',
          message: err.message,
          name: err.name,
          stack: err.stack
        });
        console.log('Error - Could not get ICE servers', err);
        resolve(iceServers);
      });
    });
  }

  connectRTC(cam_index) {
    // Only connect if we have the correct info
    if (
      this.state.rtcUrl &&
      this.state.rtcJwt &&
      this.state.cameras[cam_index]?.rtcStreamName &&
      this.state.iceServers
    ) {
      // console.log('Creating a connection for Cam ', this.state.cameras[cam_index]?.label);

      // Close existing connections first
      if (this.state.cameras[cam_index]?.rtcConnection) {
        this.close_rtc_conn(cam_index);
      }

      // Create remote connection
      let rtcConf = {
        iceServers : this.state.iceServers,
        rtcpMuxPolicy : "require",
        bundlePolicy: "max-bundle"
      };
      let pc = new RTCPeerConnection(rtcConf);

      // Add received tracks to video ref
      pc.ontrack = (event) => {
        // console.log('New track for Cam ', this.state.cameras[cam_index]?.label, ' - ', event.streams);
        if (this.state.cameras[cam_index]?.videoSource.current !== null) { // Check to prevent error when stream is not present
          this.state.cameras[cam_index].videoSource.current.srcObject = event.streams[0];
        } else {
          // console.log('Skipped srcObject for Cam ', this.state.cameras[cam_index]?.label);
        }
      }

      // Create Signalling connection
      let ws = new WebSocket(this.state.rtcUrl + '?token=' + this.state.rtcJwt);

      // Store WS reference
      this.updateCameras(cam_index, 'ws', ws);

      // WebSocket is connected
      ws.onopen = () => {
        // console.log('Opened WS connection for Cam ', this.state.cameras[cam_index]?.label);

        //if this is supported
        if (pc.addTransceiver) {
          // console.log('transceiver!  for Cam ', this.state.cameras[cam_index]?.label);

          //Create all the receiver tracks
          pc.addTransceiver("audio",{
            direction : "recvonly",
            // streams : [stream]
            streams : [this.state.cameras[cam_index]?.videoStream]
          });
          pc.addTransceiver("video",{
            direction : "recvonly",
            // streams : [stream]
            streams : [this.state.cameras[cam_index]?.videoStream]
          });
        } else {
          console.error('transceiver not supported');
          this.props.enqueueSnackbar("Transceiver not found, your browser may be out of date.", { variant: 'error' });
        }

        // Create RTC offer
        pc.createOffer({
          offerToReceiveAudio: true,
          offerToReceiveVideo: true
        })
        .then(desc => {
          // console.log('Offer created for Cam ', this.state.cameras[cam_index]?.label, desc.sdp);
          
          // Allow stereo
          desc.sdp = updateSdpAudioParameters(desc.sdp, 2)

          pc.setLocalDescription(desc)
          .then(() => {
            // console.log('Local description set for Cam ', this.state.cameras[cam_index]?.label);
            //set required information for media server.
            let data    = {
              streamId:  this.state.cameras[cam_index]?.rtcStreamName,
              sdp:   desc.sdp,
            }
            //create payload
            let payload = {
              type:    "cmd",
              transId: Math.random() * 10000,
              name:    'view',
              data:    data
            }

            // Send answer
            ws.send(JSON.stringify(payload));

            // Store connection
            this.updateCameras(cam_index, 'rtcConnection', pc);

          })
          .catch(err => {
            logToServer({
              section: 'Village',
              action: 'Error setting local description',
              cam_index: cam_index,
              message: err.message,
              name: err.name,
              stack: err.stack
            });
            this.props.enqueueSnackbar('Looks like we had an issue connecting Cam ' + this.state.cameras[cam_index]?.label + err, { variant: 'error' });
            console.error('Error - Could not set local description for Cam ', this.state.cameras[cam_index]?.label, ' - ', err);
          });

        })
        .catch(err => {
          logToServer({
            section: 'Village',
            action: 'Error creating local offer',
            cam_index: cam_index,
            message: err.message,
            name: err.name,
            stack: err.stack
          });
          this.props.enqueueSnackbar('Looks like we had an issue connecting for Cam ' + this.state.cameras[cam_index]?.label + err, { variant: 'error' });
          console.error('Error - Could not create offer for Cam ', this.state.cameras[cam_index]?.label, ' - ', err);
        });
      }

      // Listen for response
      ws.addEventListener('message', evt => {
        // console.log('Received Socket message ');

        let msg = JSON.parse(evt.data);
        switch (msg.type) {
          case 'response':
            let data = msg.data;
            let answer = new RTCSessionDescription({
            type: 'answer',
            sdp:  data.sdp
            })

            pc.setRemoteDescription(answer)
            .then(d => {
              // Store connection
              this.updateCameras(cam_index, 'rtcConnection', pc);

              // RTC Connection state change events (no firefox support)
              pc.addEventListener("connectionstatechange", e => {
                // console.log('Cam %s RTC Connection State Changed to %s', cam_index, pc.connectionState);

                if (pc.connectionState === 'failed') {
                  let timeout = setTimeout(() => {this.getSubscribe(cam_index)}, 1000);
                  this.updateCameras(cam_index, "reconnectTimer", timeout)
                }

                if (pc.connectionState === 'connected' && cam_index === 0 && !isMobile) {
                  this.props.enqueueSnackbar('Audio is muted.', {
                    action: (key) => this.unmuteAudioButton(key),
                    autoHideDuration: 10000,
                    variant: 'Info',
                  });
                }
              })

              // console.log('Remote description set for Cam ', this.state.cameras[cam_index]?.label);

            })
            .catch(err => {
              logToServer({
                section: 'Village',
                action: 'Error connecting',
                message: err.message,
                name: err.name,
                stack: err.stack
              });
              console.error('Error - ', err);
              this.props.enqueueSnackbar('Looks like we had an issue connecting. ' + err, { variant: 'error' });
              console.error('Could not set remote description for Cam ', this.state.cameras[cam_index]?.label, ' - ', err);
            });

          break;
        case 'event':
          switch (msg.name) {
            case 'active':
              // RTC Connection is active
              console.log('RTC Connection Active for Cam ', cam_index);
              this.updateCameras(cam_index, "isStreamActive", true);
              // this.props.enqueueSnackbar('Connection Active', { variant: 'info' });
              break;
            case 'inactive':
              // RTC Connection is inactive
              console.log('RTC Connection Inactive for Cam ', cam_index);
              this.updateCameras(cam_index, "isStreamActive", false);

              // // Display our logo when stream is paused
              // if (this.state.cameras[cam_index]?.videoSource?.current) {
              //   this.state.cameras[cam_index]?.videoSource.current.srcObject = undefined;
              // }
              break;
            default:
              console.log('RTC Other state is ', msg);

          }
          break;
        default:
          // console.log('WS message for Cam ', this.state.cameras[cam_index]?.label, ' - ', msg);
      }

      });

      // Listen for WebSocket Closed events
      ws.addEventListener('close', evt => {
        // Turning off a multi cam display closes a socket connection. Don't resubscribe unless we had an issue
        // this.getSubscribe(cam_index);
        // console.log('WS closed for Cam ', cam_index);
      });

      ws.addEventListener('error', evt => {
        console.log('WS ERROR for Cam ', cam_index, '\n', evt);
        // Try reconnecting
        this.getSubscribe(cam_index);

      })

    } else {
      console.error('RTC No information to connect with for Cam ', cam_index);
    }
    // console.log('Creating RTC connection for Cam ', cam_index);
  }

  // *************************************************************************

  updatePermissions(newPermissions, enabledCameras) {
    let previous_select;
    try {
      previous_select = JSON.parse(localStorage.getItem(this.state.user_uuid + '_cams'));
    } catch (e) {
      console.error(' Error parsing local storage for %s', this.state.user_uuid+"_cams");
    }

    if (!previous_select) {
      previous_select = {}
      for (let i=0; i<enabledCameras; i++) {
        previous_select[i] = true
      }
    }

    // Create permissions
    let permissions = this.state.permissions;
    let isChatOpen = this.state.isChatOpen;
    let isVCOpen = this.state.isVCOpen;

    // Override defaults if permissions exist - This provides backwards compatability
    if (newPermissions) {
      // Text Chat
      permissions.textChat = typeof newPermissions.textChat === 'boolean' ? newPermissions.textChat : true;
      if (
        typeof newPermissions.textChat === 'boolean' && !newPermissions.textChat
      ) {
          isChatOpen = newPermissions.textChat;
      }

      // Video Chat
      permissions.videoChat = typeof newPermissions.videoChat === 'boolean' ? newPermissions.videoChat : true;
      if (
        typeof newPermissions.videoChat === 'boolean' && !newPermissions.videoChat
      ) {
          isVCOpen = newPermissions.videoChat;
      }

      // Sketches
      permissions.sketches = typeof newPermissions.sketches === 'boolean' ? newPermissions.sketches : true;

      // Cameras
      if (newPermissions.cams) {
        for (var i = 0; i < 4; i++) {
          permissions.cams[i] = typeof newPermissions.cams[i] === 'boolean' ? newPermissions.cams[i] : true;

          if (
            typeof newPermissions.cams[i] === 'boolean' && !newPermissions.cams[i]
          ) {
            // Disable cameras without permission
            previous_select[i] = permissions.cams[i]
          }
        }
      }
    }

    // Store
    this.setState({
      permissions: permissions,
      isChatOpen: isChatOpen,
      isVCOpen: isVCOpen,
    });

    // Return permissions and camera selection
    return ([permissions,previous_select])
  }

  // Picture in Picture handler
  popOutVideo(cam_index) {
    let player = this.state.cameras[cam_index]?.video_display;
    if (!player) {
      player = document.getElementById('display_' + cam_index);
      // Store html element
      this.updateCameras(cam_index, 'video_display', player);
    }

    // Chromium based
    if (document.pictureInPictureEnabled && !player.disablePictureInPicture) {
      if (document.pictureInPictureElement) {
        document.exitPictureInPicture()
        .catch(err => {
          console.error('Error - Could not close PIP window', err);
        });
      } else {
        player.requestPictureInPicture()
        .catch(err => {
          console.error('Error - Could not request PIP', err);
        });
      }
    }
    // Webkit based
    else if (
      player.webkitSupportsPresentationMode &&
      player.webkitSupportsPresentationMode('picture-in-picture') &&
      typeof(player.webkitSetPresentationMode) === "function"
    ) {
      // Check PIP state
      let out = player.webkitPresentationMode === "inline" ? true : false;
      // Change PIP state
      player.webkitSetPresentationMode(player.webkitPresentationMode === "picture-in-picture" ? "inline" : "picture-in-picture");
      // Store
      this.updateCameras(cam_index, 'poppedOut', out);
    }
  }

  // Full screen handler
  goFullScreen(cam_index) {
    // Set body to full height before going to fullscreen ( prevents white bar on Safari )
    document.body.style['height'] = '100%';

    let player;
    // Check if we should use the individual player or the container
    if (cam_index < 4) {
      // Get video display element
      player = this.state.cameras[cam_index]?.video_display;
      if (!player) {
        player = document.getElementById('display_' + cam_index);
        // Store html element
        this.updateCameras(cam_index, 'video_display', player);
      }
    } else {
      // Use container for fullscreen
      player = document.getElementById('video_displays');
    }


    // Try screenfull first
    if (ScreenFull.isEnabled) {
      if (cam_index < 4) {
        ScreenFull.toggle(player); // Request single element
      } else {
          ScreenFull.toggle(); // Entire document fullscreen
      }
    } else {

      // Chromium in full screen
      if (document.fullScreenElement === true) {
        document.exitFullscreen()
        .then(result => {
          // Store fullscreen state if index is not quad split
          if (cam_index < 4) {
            this.updateCameras(cam_index, 'fullscreen', false);
          }
        })
        .catch(err => {
          console.error('Error - Could not exit full screen', err);
        });

      // Webkit in full screen
      } else if (player.webkitIsFullScreen === true) {
        // Exit fullscreen
        player.webkitExitFullscreen();
        // Store fullscreen state if index is not quad split
        if (cam_index < 4) {
          this.updateCameras(cam_index, 'fullscreen', player.webkitIsFullScreen);
        }

      // Chromium out of full screen
      } else if (typeof(player.requestFullscreen) === "function") {
        // Enter fullscreen
        player.requestFullscreen()
        .then(result => {
          // Store fullscreen state if index is not quad split
          if (cam_index < 4) {
            this.updateCameras(cam_index, 'fullscreen', document.fullscreenElement);
          }
        })
        .catch(err => {
          console.error('Error - Could not request Full screen', err);
        });

      // Webkit out of full screen
      } else if (typeof(player.webkitEnterFullscreen) === "function") {
        // Enter fullscreen
        player.webkitEnterFullscreen();
        // Store fullscreen state if index is not quad split
        if (cam_index < 4) {
          this.updateCameras(cam_index, 'fullscreen', player.webkitIsFullScreen);
        }
      } else if (typeof(player.webkitSupportsPresentationMode) === 'function') {
        player.webkitSetPresentationMode('fullscreen')
      }
    }
  }

  // Mute / Un Mute button actions
  muteVideo(cam_index) {

    // Toggle mute state of video display
    if (this.state.cameras[cam_index]?.video_display) {
      if (this.state.cameras[cam_index]?.video_display.muted) {
        // Change element mute state
        this.state.cameras[cam_index].video_display.muted = false;
      } else {
        // Change element mute state
        this.state.cameras[cam_index].video_display.muted = true;
      }
      this.forceUpdate();
    }
  }

  // Play the viewer if paused
  async playViewer(cam_index) {
    if (this.state.cameras[cam_index]?.video_display?.paused) {
      try {
        await this.state.cameras[cam_index]?.video_display.play();
      } catch (error) { /* Ignore */ }
    }
  }

  // Switch video displays
  switchDisplay(cam_index, to_connect) {

    // Individual Camera Selectors
    if (cam_index < 4) {

      // Check the number of enabled cameras
      let shownCameras = this.state.cameras.filter(camera => camera.enabled);
      let numberOfCameras = shownCameras.length;

      // If track is enabled close rtc connection before hiding element
      if (this.state.cameras[cam_index]?.rtcConnection && this.state.cameras[cam_index]?.enabled) {
        // Disconnect unless it's A cam and the only cam enabled
        if (cam_index !== 0 || (cam_index === 0 && numberOfCameras !== 1)) {
          // Close RTC Connection
          this.close_rtc_conn(cam_index);
        }

      // Create new rtc connection before turning on display
      } else if (this.state.iceServers && !this.state.cameras[cam_index]?.rtcConnection && !this.state.cameras[cam_index]?.enabled && to_connect) {
          this.getSubscribe(cam_index);
        }

      // Turn a viewer on or off
      if (to_connect) {
        this.updateCameras(cam_index, 'enabled', this.state.cameras[cam_index]?.enabled ? false : true);
      }

      // Get updated list of cameras to set correct sizing for - Watch out for timing bug is setState takes too long
      shownCameras = this.state.cameras.filter(camera => camera.enabled);
      numberOfCameras = shownCameras.length;

      // Store current number of displayed cameras in local storage
      let store_cam_select = {}
      this.state.cameras.forEach((camera, i) => {
        store_cam_select[i] = this.state.cameras[i].enabled
      })
      localStorage.setItem(this.state.user_uuid + '_cams', JSON.stringify(store_cam_select));

      // Set display order
      switch (numberOfCameras) {
        case 0:
        // Always show A Camera

          // Display A Camera
          this.updateCameras(0, 'enabled', true);

          // Reconnect if needed
          if (!this.state.cameras[cam_index]?.rtcConnection && to_connect) {
            this.getSubscribe(0);
          }

          // Set A camera order and size to fit
          this.state.cameras[0].video_display.parentNode.parentNode.style.order = 0;
          this.setState({
            shownCameras: 1
          });
          break;

        case 1:
        // 1 Camera - Reset order and sizing

          // Set camera order to default and size to fit
          shownCameras.forEach((camera, i) => {
            camera.video_display.parentNode.parentNode.style.order = i;
          });
          this.setState({
            shownCameras: 1
          });
          break;

        case 2:
        // 2 Cameras - Stack top and bottom

          // Set camera order to default and size to fit vertical height
          shownCameras.forEach((camera, i) => {
            camera.video_display.parentNode.parentNode.style.order = i;
          });
          this.setState({
            shownCameras: 2
          });
          break;

        case 3:
        case 4:
        // 4 Cameras - Reset order and sizing

          // Set camera order to default and sizing to 50%
          shownCameras.forEach((camera, i) => {
            // Reset order
            camera.video_display.parentNode.parentNode.style.order = i;
          });
          this.setState({
            shownCameras: 4
          });

          break;
      }

    // Quad Split
    } else if (cam_index === 4) {
      // Turn all viewers on (Quad Split)
      let new_cameras = [];

      this.state.cameras.forEach((camera, i) => {
        // Show all cameras
        camera.enabled = true;
        // Reset order
        camera.video_display.parentNode.parentNode.style.order = i;
        new_cameras.push(camera);
      });
      this.setState({
        cameras: new_cameras,
        shownCameras: 4
      });
    }

    // Scroll to video display
    // document.getElementById('video_displays').scrollIntoView();
  }

  // Toggle video element borders
  toggleBorders() {
    localStorage.setItem(this.state.user_uuid + '_borders', !this.state.show_multicam_borders);
    this.setState({
      show_multicam_borders: !this.state.show_multicam_borders
    });

  }

  // Stop RTC Peer Connection Tracks when not displayed
  close_rtc_conn(cam_index) {
    // If the connection exists then close it
    if (this.state.cameras[cam_index]?.rtcConnection) {
      this.state.cameras[cam_index].rtcConnection.close();
      // delete this.state.cameras[cam_index]?.rtcConnection;
      this.updateCameras(cam_index, 'rtcConnection', null);
    }

    // If we have a WebSocket, close it
    if (this.state.cameras[cam_index]?.ws) {
      this.state.cameras[cam_index].ws.close();
      this.updateCameras(cam_index, 'ws', null);
    }

    if (
      this.state.cameras[cam_index]?.videoDisplay?.current?.srcObject
    ) {
      // this.state.cameras[cam_index].videoDisplay.current.srcObject = undefined;
      // this.state.cameras[cam_index].videoDisplay.current.src = logoLoop;
    }

  }

  // Fix for Safari using callbacks instead of promises for Notification permissions
  checkNotificationPromise() {
    try {
      Notification.requestPermission().then();
    } catch(e) {
      return false;
    }
    return true;
  }

  // Control the display of borders
  bordersCheck() {
    let showBorders = localStorage.getItem(this.state.user_uuid + '_borders');
    showBorders = (showBorders === "true"); // Convert to Boolean
    if (showBorders === null) { showBorders = true }
    if (this.state.cameras.length === 1) { showBorders = false }
    this.setState({
      show_multicam_borders: showBorders
    });
  }

  // Toggle Communications
  toggleChatDrawer() {
    localStorage.setItem(this.state.user_uuid + '_txtdrawer', !this.state.isChatOpen);
    this.setState({
      isChatOpen: !this.state.isChatOpen
    });
  }

  toggleVCDrawer() {
    localStorage.setItem(this.state.user_uuid + '_vcdrawer', !this.state.isVCOpen);
    this.setState({
      isVCOpen: !this.state.isVCOpen
    });
  }

  toggleSelectedCam(cam_index) {
    if (this.state.selectedCam !== cam_index) {
      this.setState({
        selectedCam: cam_index,
        showSketchControls: true
      })
    } else {
      this.setState({
        showSketchControls: !this.state.showSketchControls
      })
    }
  }

  updateLoggedInStatus() {
    if (!this.state.user_uuid) {
      let storedId = localStorage.getItem(this.state.subscriber_uuid + '_wr')
      if (storedId && storedId != '') {
        let cookies = Cookies.get(storedId)
        if (cookies) {
          this.setState({
            user_uuid: storedId,
            cookies: cookies,
          }, () => {
            // Create wss connection
            if (!this.state.pusher) {
              this.createPusher();
            }
            // Setup streaming
            this.getRtcInfo();
          })
        } else {
          this.setState({
            user_uuid: storedId
          })
        }
        this.props.setViewerId(storedId)
      }
    } else {
      let cookies = Cookies.get(this.state.user_uuid)
      if (cookies) {
        this.setState({
          cookies: cookies,
        });
        // Create wss connection
        if (!this.state.pusher) {
          this.createPusher();
        }
        // Setup streaming
        this.getRtcInfo();
      }
    }
  }

  async updateNickName(nickName) {
    console.log("Updating NickName ", nickName)
    try {
      this.setState({nickName: nickName})
      localStorage.setItem("ss_nickname", nickName)

      let fetchUrl = process.env.REACT_APP_HOST_URL + '/api/chat/nickName/' + this.state.user_uuid
      let fetchData = {
        method: 'POST',
        headers: {
          'Content-Type':'application/json'
        },
        body: JSON.stringify({
          nickName: nickName,
          uuid: this.state.user_uuid
        })
      }
      let res = await fetch(fetchUrl, fetchData);
      if (res.ok) {
        let response = await res.json();
      } else {
        console.error('Could not update nickName ', res.status);
      }  
    } catch (error) {
      console.error('Network error updating nickName ', error);
    }
  }

  updateState(key, value) {
    if (key && value) {
      let newState = {}
      newState[key] = value
      this.setState(newState)
      // Store user id in props also
      if (key === 'user_uuid') {
        this.props.setViewerId(value)
      }
    }
  }

  // Lifecycle

  componentDidMount() {
    // Set viewport height and monitor changes
    document.documentElement.style.setProperty('--vh', `${window.innerHeight}px`);
    window.addEventListener('resize', () => {
      // Rate limit resize events with debounce
      clearTimeout(this.resizeTimer);
      this.resizeTimer = setTimeout(() => {
        document.documentElement.style.setProperty('--vh', `${window.innerHeight}px`);
      }, 500)
    })

    if (this.state.cookies) {

      // Check PIP ability for each viewer
      this.state.cameras.forEach((camera, cam_index) => {
        // Check for chromium / gecko PIP support
        let pip = document.pictureInPictureEnabled ? true : false;
        // Get html video display element
        let video = document.getElementById('display_' + cam_index);
        // Store video display element
        this.updateCameras(cam_index, 'video_display', video);

        // Check for webkit iOS PIP suppport
        if (video && video.webkitSupportsPresentationMode) {
          pip = video.webkitSupportsPresentationMode('picture-in-picture') ? true : pip;
        }

        // Store pip support
        this.updateCameras(cam_index, 'supportsPip', pip);
      });

      // Show / Hide camera borders
      this.bordersCheck();

      // Create Websockets
      this.createPusher();

      // Get connection info and connect
      this.getRtcInfo();

      // Listen for page focus events
      document.addEventListener('visibilitychange', () => {

        // Only handle visibility on mobile to save battery
        if (isMobile) {
          if (document.visibilityState === 'hidden') {
            this.state.cameras.forEach((camera, cam_index) => {
              if (camera.rtcConnection && !this.state.cameras[cam_index]?.poppedOut) {
                this.close_rtc_conn(cam_index);
              }
            });
          } else {
            this.state.cameras.forEach((camera, cam_index) => {
              if ( // Only reconnect if we are not connected
                !camera.rtcConnection ||
                (camera.rtcConnection &&
                camera.rtcConnection.connectionState !== 'connected')
              ) {
                this.getSubscribe(cam_index);
              }
            });
          }
        }

      });

      // Get permission for notifications
      if (window.Notification && Notification.permission) {
        switch (Notification.permission) {
          case 'granted':
            this.setState({
              notify_is_allowed: true
            });
            // console.log('Notifications permission granted');
            break;
          case 'denied':
            console.log('Notifications permission denied');
            break;
          case 'default':
            if(typeof Notification.requestPermission === 'function') {
              if (this.checkNotificationPromise()) {
                Notification.requestPermission()
                .then(permission => {
                  if (permission === "granted") {
                    this.setState({
                      notify_is_allowed: true
                    });
                  }
                })
                .catch(err => {
                  console.error('Error - Could not get permission for notifications', err);
                });
              } else {
                Notification.requestPermission(permission => {
                  if (permission === "granted") {
                    this.setState({
                      notify_is_allowed: true
                    });
                  }
                });
              }
            }
            break;
        }
      }
    }

  }

  componentWillUnmount() {
    // Cleanup resize listener
    window.removeEventListener('resize', () => {
      document.documentElement.style.setProperty('--vh', `${window.innerHeight}px`);
    });

    // Cleanup RTC Connections
    this.state.cameras.forEach((camera, i) => {
      console.log('Unmount Closing ', i);
      this.close_rtc_conn(i);
    })
  }

  // Unmute button for notification
  unmuteAudioButton(key) {
    return (
      <>
        <Button
          onClick={this.unMuteACam}
          variant="contained"
          color="primary"
        >
          Un Mute
        </Button>

        <IconButton
          onClick={() => this.props.closeSnackbar(key)}
        >
          <CancelIcon />
        </IconButton>
      </>
  )}

  unMuteACam() {
    this.props.closeSnackbar();
    this.muteVideo(0);
  }

  // Return status display
  statusPieces(classes) {
    return (
      <Grid style={{paddingLeft:'4px', paddingRight:'4px', width:'100%'}}>
        {
          this.state.cookies ?
          <Typography variant="body1" className={classes.statusDisplay} key={'KKFOISL'}>
            {this.state.status}
          </Typography>
          :
          <Typography variant="body1" className={classes.statusDisplay}>
            Welcome!
          </Typography>
        }
      </Grid>
    )
  }

  // Return Chat display
  chatPieces(classes) {
    if (this.state.cookies && this.state.channel) {
      return (
        <Chat
          nickName={this.state.nickName}
          updateNickName={this.updateNickName}
          room={this.state.subscriber_uuid}
          uuid={this.state.user_uuid}
          role='subscriber'
          pusher={this.state.pusher}
          channel={this.state.channel}
          isVCOpen={this.state.isVCOpen}
          isChatOpen={this.state.isChatOpen}
          isAllowed={this.state.permissions.textChat}
          setIsChatOpen={this.toggleChatDrawer}
          withActiveSpeaker={this.state.withActiveSpeaker}
          isViewer={true}
          setChatControls={this.props.setChatControls}
        >
          {this.statusPieces(classes)}
        </Chat>
      )
    } else {
      return
    }
  }

  // Create viewer
  viewerPieces(classes, cam_index) {
    if (this.state.cookies) {
      return (
        <Grid
          item
          className={
            this.state.shownCameras === 1 ?
              (this.state.isVCOpen ? classes.videoDisplayOne : classes.videoDisplayOneNoVC) :
              this.state.shownCameras === 2 ?
                (this.state.isVCOpen ? classes.videoDisplayTwo : classes.videoDisplayTwoNoVC) :
                this.state.shownCameras > 2 ?
                  (this.state.isVCOpen ? classes.videoDisplayQuad : classes.videoDisplayQuadNoVC) :
                  classes.videoDisplayOne
          }
          style={{
            display: this.state.cameras[cam_index]?.enabled ? 'block' : 'none',
            order: cam_index,
            margin: 'none',
            padding: '0px 1px'
          }}
          key={'VPIKSL'+cam_index}
          onClick={e => {this.toggleSelectedCam(cam_index)}}
        >
          <Card
            elevation={4}
            className={isTouchCapable ? classes.videoCardMobile : classes.videoCard}
            ref={this.state.cameras[cam_index]?.videoBox}
            style={{
              width:"100%",
              paddingTop:"56.25%",
              position:"relative",
              borderStyle: 'solid',
              borderWidth: '2px',
              borderColor: (isTouchCapable && this.state.selectedCam === cam_index) ? 
                '#b3b3b3 #b3b3b3 ' + this.state.cameras[cam_index]?.color + ' #b3b3b3' :
                (this.state.show_multicam_borders ? this.state.cameras[cam_index]?.color : '#000000'),
            }}
          >
            <CardMedia
              component = 'video'
              id = {'display_' + cam_index}
              ref = {this.state.cameras[cam_index]?.videoSource}
              // controls
              autoPlay
              muted
              loop
              poster = ''
              autopictureinpicture='true'
              playsInline
              src = {logoLoop}
              style={{'height':'100%', 'position':'absolute', 'top':0, 'left':0}}
            />
              <Hidden smDown>
                <Grid
                  style={{
                    background: `linear-gradient(
                      0deg,
                      rgba(21,21,21,0) 0%,
                      rgba(21,21,21,0.6) 30%,
                      rgba(21,21,21,1) 80%
                    )`,
                    boxShadow: '-4px 4px 12px -2px rgba(0,0,0,0.5)',
                  }}
                  className={
                    (isTouchCapable &&
                    this.state.showSketchControls &&
                    this.state.selectedCam === cam_index) ?
                    classes.videoControlsShown : classes.videoControlsHidden
                  }
                >
                  {this.viewerControlPieces(classes, cam_index)}
                </Grid>
              </Hidden>
            {
              this.state.permissions.sketches &&
              <Sketches
                enabled={this.state.showSketches}
                disable={() => this.setState({showSketches: false})}
                enable={() => this.setState({showSketches: true})}
                cam_index={cam_index}
                viewerUuid={this.state.user_uuid}
                room={this.state.subscriber_uuid}
                pusher={this.state.pusher}
                presenceRoom={this.state.presenceRoom}
                members={this.state.members}
                viewer_color={this.state.viewer_color}
                selectedCam={this.state.selectedCam}
                showControls={this.state.showSketchControls}
              />
            }
            {
              this.state.cameras[cam_index]?.isStreamActive &&
              this.state.cameras[cam_index]?.videoSource?.current?.paused &&
              <Grid
                style={{
                  position: 'absolute',
                  bottom: 0,
                  left: 0,
                }}
              >
                <IconButton
                  onClick={() => this.playViewer(cam_index)}
                >
                  <PlayCircleOutlineOutlined />
                </IconButton>
              </Grid>
            }
          </Card>
        </Grid>
      )
    }
  }

  // Create viewer controls
  viewerControlPieces(classes, cam_index) {
    if (this.state.cookies) {
      return (
       
      <Grid
        className={classes.videoControlsMobile}
        style={{
          // borderTop: ((isTouchCapable && this.state.selectedCam === cam_index) ? '2px' : '0px')
          //   + ' solid ' + this.state.cameras[cam_index]?.color
          borderTop: '2px solid ' + this.state.cameras[cam_index]?.color
        }}
      >
        <Grid>
          <Grid item>
            <Tooltip
              title={
                <>
                    {"Connection: " + this.state.cameras[cam_index]?.rtcConnection?.connectionState?.toUpperCase()}
                  <br />
                    {"Stream: " + (this.state.cameras[cam_index]?.isStreamActive ? "LIVE" : "NOT LIVE")}
                </>
              }
              placement="bottom"
            >
              <Badge
                variant="dot"
                overlap="circular"
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
                }}
                color={
                  this.state.cameras[cam_index]?.rtcConnection?.connectionState === "connected" ?
                  "primary" :
                  (  
                    (this.state.cameras[cam_index]?.rtcConnection?.connectionState === "new" ||
                    this.state.cameras[cam_index]?.rtcConnection?.connectionState === "connecting") ?
                    "secondary" : (
                      this.state.cameras[cam_index]?.rtcConnection?.connectionState === "failed" ?
                      "error" : 
                      "default" // Defaults for "disconnected" & "closed"
                    )  
                  )
                }
              >
                <Typography
                  variant="h5"
                  style={{
                    color: this.state.cameras[cam_index]?.color,
                    paddingLeft: '6px',
                    marginRight:'8px'
                  }}
                >
                  {this.state.cameras[cam_index]?.label}
                </Typography>
              </Badge>
            </Tooltip>
          </Grid>
        </Grid>

        <Grid>
          {
            this.state.cameras[cam_index]?.rtcConnection?.connectionState &&
            this.state.cameras[cam_index]?.rtcConnection?.connectionState !== "failed" &&
            this.state.cameras[cam_index]?.rtcConnection?.connectionState !== "disconnected" &&
            this.state.cameras[cam_index]?.rtcConnection?.connectionState !== "closed" &&
            <Grid item>
              <Typography
                style={{
                  'color': this.state.cameras[cam_index]?.isStreamActive ? '#238e4d' : '#787878',
                  'padding': '6px',
                }}
              >
              {this.state.cameras[cam_index]?.isStreamActive ? 
                'LIVE'
              : 
                <span 
                  style={{
                    textDecoration: "line-through",
                    textDecorationThickness: "1px",
                    textDecorationStyle: "double",
                  }}
                >
                  LIVE
                </span>
              }
              </Typography>
            </Grid>
          }
        </Grid>

        <Grid>
          {
            (this.state.cameras[cam_index]?.rtcConnection?.connectionState === "failed" ||
            this.state.cameras[cam_index]?.rtcConnection?.connectionState === "disconnected" ||
            this.state.cameras[cam_index]?.rtcConnection?.connectionState === "closed" ||
            !this.state.cameras[cam_index]?.rtcConnection?.connectionState) &&
            <Grid item>
              <Button
                onClick={() => this.getSubscribe(cam_index)}
                variant="contained"
                color="primary"
              >
                RECONNECT
              </Button>
            </Grid>
          }
        </Grid>

        <Grid style={{'flex':'1', 'textAlign':'center'}}></Grid>

        <Grid>
          <Grid item>
            {
              this.state.cameras[cam_index]?.supportsPip ?
              <Tooltip
                title={
                  this.state.cameras[cam_index]?.poppedOut ? "Pop In" : "Pop Out"
                }
                placement="bottom"
                arrow
              >
                <IconButton
                  onClick={e => this.popOutVideo(cam_index)}
                  color = {this.state.cameras[cam_index]?.poppedOut ? "primary" : "secondary"}
                  className={classes.iconButton}
                >
                  <PictureInPicture />
                </IconButton>
              </Tooltip>
              :
              null
            }
          </Grid>
        </Grid>

        <Grid>
          <Grid item>
            {
              this.state.cameras[cam_index]?.video_display ?
              <Tooltip
                title={
                  this.state.cameras[cam_index]?.fullScreen ? "Windowed" : "Full Screen"
                }
                placement="bottom"
                arrow
              >
                <IconButton
                  onClick={e => this.goFullScreen(cam_index)}
                  color = {this.state.cameras[cam_index]?.fullScreen ? "primary" : "secondary"}
                  className={classes.iconButton}
                >
                  <AspectRatioIcon />
                </IconButton>
              </Tooltip>
              :
              null
            }
          </Grid>
        </Grid>

        <Grid>
          <Tooltip
            title={
              this.state.cameras[cam_index]?.video_display?.muted === true ?
              "MUTED"
              :
              "UNMUTED"
            }
            placement="bottom"
            arrow
          >
            <IconButton
              onClick={e => this.muteVideo(cam_index)}
              className={classes.iconButton}
            >
              {
                this.state.cameras[cam_index]?.video_display ?
                  (this.state.cameras[cam_index]?.video_display.muted === true ?
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      width="24"
                      height="24"
                    >
                      <path
                        fill="none"
                        d="M0 0h24v24H0z"
                        id="muteOffIcon"
                      />
                      <path
                        d="M5.889 16H2a1 1 0 0 1-1-1V9a1 1 0 0 1 1-1h3.889l5.294-4.332a.5.5 0 0 1 .817.387v15.89a.5.5 0 0 1-.817.387L5.89 16zm14.525-4l3.536 3.536-1.414 1.414L19 13.414l-3.536 3.536-1.414-1.414L17.586 12 14.05 8.464l1.414-1.414L19 10.586l3.536-3.536 1.414 1.414L20.414 12z"
                        fill="rgba(255,0,0,1)"
                      />
                    </svg>
                    :
                    <VolumeUp
                      id="muteIcon"
                      variant="filled"
                      style={{'color':'rgb(0,255,0,1)'}}
                    />
                  )
                  :
                  null
                }
              </IconButton>
            </Tooltip>
          </Grid>
        </Grid>
      )
    }
  }

  // Create Switcher controls
  switcherPieces(classes) {
    if (this.state.cookies) {
      let buttons = [];

      // Border toggle
      if (this.state.cameras.length > 1) {
        buttons.push(
          <Grid item style={{'display':'inline'}} key={'SDKLIOFE'}>
            <Tooltip title="Toggle Borders">
              <Button
                onClick = {e => this.toggleBorders()}
                color = {this.state.show_multicam_borders ? "primary" : "secondary"}
                variant = "contained"
                style={{
                  padding:'3px',
                  minWidth:'32px',
                  height:'32px',
                  marginRight:'4px'
                }}
              >
                <BorderStyleIcon
                  style={{'fontSize': '1.6rem'}}
                />
              </Button>
            </Tooltip>
          </Grid>
        )
      }

      // Create camera switcher buttons
      if (this.state.cameras.length > 0) {
        this.state.cameras.forEach((camera, cam_index) => {
          if (this.state.permissions.cams[cam_index]) {
            buttons.push(
              <Grid item style={{'display':'inline'}} key={'scfe'+cam_index}>
                <Tooltip
                  title={
                    <>
                      {"Toggle " + camera.label + " Camera Display"}
                      <br />
                      {"Connection: " + camera.rtcConnection?.connectionState?.toUpperCase()}
                      <br />
                      {"Stream: " + (camera.isStreamActive ? "LIVE" : "INACTIVE")}
                    </>
                  }
                  placement="bottom"
                >
                  <Badge
                    variant="dot"
                    invisible={camera.isStreamActive ? false : true}
                    overlap="circular"
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'left',
                    }}
                    color="primary"
                  >
                    <Button
                      onClick={e => this.switchDisplay(cam_index, true)}
                      value={cam_index}
                      style={{
                        backgroundColor: camera.color,
                        padding:0,
                        opacity: camera.enabled ? 1.0 : 0.35,
                        padding:'3px',
                        minWidth:'32px',
                        height:'32px',
                        marginRight:'4px'
                      }}
                      variant="contained"
                    >
                      <Typography
                        variant="h4"
                        style={{
                          fontWeight: '800',
                        }}
                      >
                        {camera.label}
                      </Typography>
                    </Button>
                  </Badge>
                </Tooltip>
              </Grid>
            )
          }
        });
      }

      // full screen
      if (ScreenFull.isEnabled) {
        buttons.push(
          <Grid item style={{'display':'inline'}} key={'SHIEOF'}>
            <Tooltip title="Go Fullscreen">
              <Button
                onClick={e => this.goFullScreen(4)}
                color={"secondary"}
                variant="contained"
                style={{
                  padding:'3px',
                  minWidth:'32px',
                  height:'32px',
                  marginRight:'4px'
                }}
              >
                <AspectRatioIcon
                  style={{'fontSize': '1.6rem'}}
               />
              </Button>
            </Tooltip>
          </Grid>
        );
      }

      // Audio Out
      buttons.push(
        <Grid item style={{'display':'inline'}} key={'wieojgriow'}>
          <AudioOutputSelect
            activeSinkId={this.state.sinkId}
            setActiveSinkId={this.setSinkId}
            style={{
              padding:'3px',
              minWidth:'32px',
              height:'32px',
              marginRight:'4px'
            }}
          />
        </Grid>
      )

      // Sketches
      if (this.state.permissions.sketches) {
        buttons.push(
          <Grid item style={{'display':'inline'}} key={'woicneus'}>
            <Tooltip title="Toggle Sketches">
              <Button
                onClick = {e => this.setState({ showSketches: !this.state.showSketches })}
                color = {this.state.showSketches ? "primary" : "default"}
                variant = "contained"
                style={{
                  padding:'3px',
                  minWidth:'32px',
                  height:'32px',
                  marginRight:'4px'
                }}
              >
                <GestureIcon
                  style={{'fontSize': '1.8rem'}}
                />
              </Button>
            </Tooltip>
          </Grid>
        )
      }

      return (
        // <Grid>
          buttons
        // </Grid>
      )
    } else {
      return null;
    }
  }

  // Return main display
  mainPieces(classes) {
    // Check if user is logged in
    if (this.state.cookies) {

      // Create video displays
      let viewers = [];
      for (let i = 0; i < (this.state.cameras.length === 0 ? 1 : this.state.cameras.length); i++) {
        viewers.push(this.viewerPieces(classes, i));
      }

      // Return video displays
      return (
        <Grid
          item
          className={this.state.isChatOpen ? classes.mainPieces : classes.mainPiecesNoChat}
        >
          <Grid
            item
            xs={12}
            id="video_displays"
            className={this.state.isVCOpen ? classes.videoDisplay : classes.videoDisplayNoVC}
            >
            {viewers}
          </Grid>
        </Grid>
      )

    // User is not logged in
    } else  {
      if (this.state.useCodeAuth || this.state.useHashAuth) {
        return (
            <ViewerWaitingRoom
              cssClasses={this.props.cssClasses}
              updateLoggedInStatus={this.updateLoggedInStatus}
              updateState={this.updateState}
              room={this.state.subscriber_uuid}
              user_uuid={this.state.user_uuid}
              nickName={this.state.nickName}
              wrHash={this.state.wrHash}
            />
        )
      } else {
        // Return the password form
        return (
          <Paper className={classes.signInPaper}>
          <Container className={classes.signInBox}>
            <Grid item xs={12} align="center">
              <Grid className={classes.signInLogoBox}>
                <BrandLogo
                  size="medium"
                  showName={false}
                />
              </Grid>
            </Grid>
            <Typography className={classes.signInTitle} variant="h5">
              Sign in
            </Typography>

            <form
            className={classes.form}
            onSubmit={(e) => this.submitForm(e)}
            >
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                name="subscriber_password"
                label="Password"
                type="password"
                id="password"
                value={this.state.subscriber_password}
                onChange = {(e) => this.setFormState(e)}
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
              >
                Sign In
              </Button>

              <Grid container className={this.props.cssClasses.tableFooter}>
                <Grid item xs={12} align="right" className={this.props.cssClasses.tableCell}>
                  By signing in you agree to our<br />
                  <Link href="https://setstream.io/terms-and-conditions/" target="_blank">
                    {" Terms and Conditions"}
                  </Link>
                </Grid>
              </Grid>

              </form>
            </Container>
          </Paper>
        )
      }
    }
  }

  render() {
    const { classes } = this.props;
    return (
        <Box id="content" className={classes.content}>

          <Grid
            container
            component="main"
            className={classes.root}
          >
            <CssBaseline />

            {/* <Hidden mdUp>
              <Grid item xs={12} style={{textAlign:'center'}}>
                {this.switcherPieces(this.props.cssClasses)}
              </Grid>
              {this.statusPieces(classes)}
            </Hidden> */}

            {/* <Grid
              item
              className={this.state.isChatOpen ? classes.mainPieces : classes.mainPiecesNoChat}
            > */}
              {this.mainPieces(classes)}
            {/* </Grid> */}


            {
              <Grid item className={this.state.isChatOpen ? classes.chatWindow : classes.chatWindowNoText}>
                {this.chatPieces(classes)}
              </Grid>
            }

            {
              this.state.cookies &&
              <Grid
                item
                xs={12}
                id="videoChat"
                className={classes.videoChat}
              >
                <Hidden smDown>
                  <BottomDrawer
                    isVCOpen={this.state.isVCOpen}
                    isChatOpen={this.state.isChatOpen}
                    setIsOpen={this.toggleVCDrawer}
                    isAllowed={this.state.permissions.videoChat}
                  >
                    <VC 
                      isAllowed={this.state.permissions.videoChat} 
                      userUuid={this.state.user_uuid}
                      nickName={this.state.nickName}
                      updateNickName={this.updateNickName}
                    />
                  </BottomDrawer>
                </Hidden>

                {/* Show VC Inline */}
                <Hidden mdUp>
                  <VC 
                    isAllowed={this.state.permissions.videoChat} 
                    userUuid={this.state.user_uuid}
                    nickName={this.state.nickName}
                    updateNickName={this.updateNickName}
                  />
                </Hidden>

                {/* Mobile Video Controls */}
                <Hidden mdUp>
                  <AppBar
                    className={classes.videoControlsMobileFooter}
                    position="fixed"
                    elevation={10}
                  >
                    {this.viewerControlPieces(classes, this.state.selectedCam)}
                  </AppBar>
                </Hidden>

              </Grid>
            }

          </Grid>

          <Backdrop open={this.state.isLoading} style={{zIndex:2001}}>
            <CircularProgress color="primary" variant="indeterminate" />
          </Backdrop>
        </Box>
    );
  }
}

export default withStyles(useStyles)(withSnackbar((ClientViewer)));
