import React, { useEffect, useState } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import {
Grid,
Input,
Typography,
Button,
Link,
Select,
MenuItem,
Checkbox,
Hidden,
} from '@material-ui/core/';
import SelectPass from '../Elements/select_pass';
import formatExpiration from '../../../utils/format_expiration';
import calculatePassPrice from '../../../utils/calculatePassPrice';
import getPassData from '../Utils/getPassData';
import useMaxViewers from '../Hooks/use_max_viewers';
import useHasScheduledPass from '../Hooks/use_has_scheduled_pass';
import useIsActivePassHourly from '../Hooks/useIsActivePassHourly';
import { useSnackbar } from 'notistack';
import createNewExpiration from '../../../utils/create_new_expiration';
import createNewFormattedExpiration from '../../../utils/create_new_formatted_expiration';
// Date and Time
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import duration from 'dayjs/plugin/duration';
dayjs.extend(utc);
dayjs.extend(duration);

const useStyles = makeStyles((theme) => createStyles({
  container: {
    padding: theme.spacing(2),
    textAlign: 'center',
  },
  messages: {
    marginTop: theme.spacing(12),
    borderTop: 'solid 1px ' + theme.palette.background.light,
    padding: theme.spacing(4)
  },
  greenBorder: {
    border: '2px solid ' + theme.palette.primary.main,
  },
  redBorder: {
    border: '2px solid ' + theme.palette.warning.main,
  },
  strikethrough: {
    textDecoration: 'line-through',
    opacity: 0.4,
  },
  subTotal: {
    opacity: 0.8,
  },
  discounts: {
    opacity: 0.5,
  },
  total: {
    fontWeight: 'bold'
  }
}));

export default function ModifyAccess(props) {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [allowCheckout, setAllowCheckout] = useState(false);

  // Expiration
  const now = new Date();
  const nowDate = now.toISOString().slice(0,10);
  const [startDate, setStartDate] = useState(nowDate);
  const [startTime, setStartTime] = useState(now.toLocaleTimeString([],{
    hour12: false,
    hour: '2-digit',
    minute: '2-digit'
  }));
  const [newExpirationDisplay, setNewExpirationDisplay] = useState('-');
  const [currentExpirationDisplay, setCurrentExpirationDisplay] = useState('?');

  // Upgrade Pass Data
  const [newViewers, setNewViewers] = useState(props.addViewers ? 1 : 0);
  const [duration, setDuration] = useState(props.addTime ? 1 : 0);
  const [durationUnits, setDurationUnits] = useState('hours');

  // Current Pass
  const [selectedPass, setSelectedPass] = useState('');
  const [selectedPassData, setSelectedPassData] = useState();
  const hasScheduledPass = useHasScheduledPass();
  const isActivePassHourly = useIsActivePassHourly(props.activePasses);

  // Display
  const maxViewers = useMaxViewers({
    activeSubs: [],
    activePasses: props.activePasses
  })
  const [isPartner, setIsPartner] = useState(false);
  const [partnerDiscount, setPartnerDiscount] = useState(0);
  const [proRateDiscount, setProRateDiscount] = useState(0);
  const [subTotal, setSubTotal] = useState(0);
  const [total, setTotal] = useState(0);
  const [overlapWarning, setOverlapWarning] = useState(null);
  const [monthlyWarning, setMonthlyWarning] = useState(false);

  // Confirmation
  const [displayData, setDisplayData] = useState({});
  const [actionData, setActionData] = useState({});

  // Effects

  // Automatically select the first active pass
  useEffect(() => {
    if (props.activePasses.length > 0 && props.activePasses[0] && props.activePasses[0].expires_on) {
      setSelectedPass(props.activePasses[0]._id);
      let newStart = new Date(props.activePasses[0].expires_on);
      setStartTime(newStart.toLocaleTimeString([], {
        hour12: false,
        hour: '2-digit',
        minute: '2-digit'
      }));
    }
  }, [props.activePasses]);

  // Change pass selection
  useEffect(() => {
    setSelectedPassData(getPassData(props.activePasses, selectedPass))
  }, [selectedPass]);

  // Update partner discount
  useEffect(() => {
    if (durationUnits === 'months') {
      setIsPartner(false);
    } else {
      setIsPartner(props.isPartner)
    }
  }, [props.isPartner, durationUnits]);

  // Set Add Time Defaults
  useEffect(() => {
    if (
      selectedPassData &&
      selectedPassData.active_duration &&
      selectedPassData.duration_units
    ) {
      let active_duration = selectedPassData.active_duration;

      // Update minutes to hours - Backwards compatability
      if (selectedPassData.duration_units === 'minutes') {
        active_duration = (selectedPassData.active_duration / 60);
      }

      // Set Duration
      if (props.scheduledPasses.length > 0) {
        let nextScheduled = props.scheduledPasses.reduce((p,c) => (
          dayjs(c.begins_on).isBefore(p.begins_on) ? c : p
        ));

        // Don't overlap scheduled passes
        setDuration(
          ( // Don't overlap scheduled passes
            dayjs(selectedPassData.expires_on)
              .isAfter(dayjs(nextScheduled.begins_on)) &&
              props.addTime
          ) ?
            dayjs(nextScheduled.begins_on).diff(dayjs(selectedPassData.expires_on), 'hour') :
            props.addTime ? active_duration : 0
        );

      } else { // Default to the same duration as selected pass
        setDuration(props.addTime ? active_duration : 0);
      }

      // Convert Minutes to default as Hours
      if (selectedPassData.duration_units === 'minutes') {
        setDurationUnits('hours');
      } else {
        setDurationUnits(selectedPassData.duration_units);
      }
    }
  }, [selectedPassData]);

  // Auto switch pass type
  useEffect(() => {
    // Only update durations is adding time
    if (props.addTime) {
      // Switch from 7 hours to 1 day automatically at 8 hours
      if (durationUnits === 'hours' && duration >= 8) {
        setDurationUnits('days');
        setDuration(1);
        // Reset monthly warning
        if (monthlyWarning) { setMonthlyWarning(null) }
      }
      // Switch from 1 day to 7 hours automatically at 0 days
      if (durationUnits === 'days' && duration < 1) {
        setDurationUnits('hours');
        setDuration(7);
        // Reset monthly warning
        if (monthlyWarning) { setMonthlyWarning(null) }
      }
      // Don't allow 0 hours
      if (durationUnits === 'hours' && duration === 0) {
        setDuration(1);
      }
      // Notify user of monthly passes
      if (durationUnits === 'days' && duration > 5) {
        setMonthlyWarning(true);
      } else {
        setMonthlyWarning(false);
      }

      // Allow checkout
      if ((props.addTime && duration > 0 && overlapWarning === null)) {
        setAllowCheckout(true);
      } else {
        setAllowCheckout(false);
      }

      // Reset overlap warning
      if (overlapWarning) { setOverlapWarning(null) }
    }
  }, [duration, durationUnits, overlapWarning]);

  // Update expiration and cost displays
  useEffect(() => {
    // Only process if pass data exists
    if (
      selectedPassData &&
      selectedPassData.active_duration &&
      selectedPassData.duration_units &&
      selectedPassData.expires_on
    ) {

      // Reduce duration if expiration overlaps scheduled pass
      if (props.scheduledPasses.length > 0) {
        let nextScheduled = props.scheduledPasses.reduce((p,c) => (
          dayjs(c.begins_on).isBefore(p.begins_on) ? c : p
        ));

        if (
          dayjs(selectedPassData.expires_on)
            .isAfter(dayjs(nextScheduled.begins_on))
        ) {
          setOverlapWarning('Adding ' + duration + ' ' + durationUnits + ' will overlap with scheduled pass beginning on ' + dayjs(nextScheduled.begins_on).local().format('MM/DD YYYY, hh:mm A'));

          let timeDiff = dayjs(nextScheduled.begins_on).diff(dayjs(selectedPassData.expires_on), 'hour')
          setDurationUnits('hours');
          setDuration(timeDiff > 0 ? timeDiff : 1);
        }
      }

      // Expiration
      setCurrentExpirationDisplay(formatExpiration(selectedPassData.expires_on));
      setNewExpirationDisplay(
        createNewFormattedExpiration({
            active_duration: duration,
            duration_units: durationUnits
          },
          (new Date(selectedPassData.expires_on) - Date.now())
        )
      );

      // Cost
      let newPrice = 0;
      let proRateAmount = 0;

      // Adding Viewers
      if (props.addViewers && newViewers > 0) {
        let newViewersCost = calculatePassPrice(
          (maxViewers + newViewers),
          selectedPassData.active_duration,
          selectedPassData.duration_units
        )
        let existingViewersCost = calculatePassPrice(
          selectedPassData.max_allowed_users,
          selectedPassData.active_duration,
          selectedPassData.duration_units
        )
        let addedViewersCost = Number(newViewersCost - existingViewersCost);

        // Pro Rate viewer upgrades
          let durationLeft = dayjs(selectedPassData.expires_on)
            .diff(dayjs(), 'hour');
          let totalDuration = dayjs
            .duration(
              selectedPassData.active_duration,
              selectedPassData.duration_units,
            )
            .asHours();
          let proRateDiscount = durationLeft / totalDuration;

          // Update price and pro rate if applicable
          newPrice = addedViewersCost

          if (proRateDiscount > 0 && proRateDiscount <= 1) {
            proRateAmount = newPrice - (newPrice * proRateDiscount);
            setProRateDiscount(proRateAmount);
            newPrice = newPrice * proRateDiscount;
          }
      }

      // Adding Time
      if (props.addTime && duration > 0) {
        newPrice += calculatePassPrice(
          (maxViewers + newViewers),
          duration,
          durationUnits
        );
      }

      if (!Number.isNaN(newPrice)) {
        // Add partner discount
        let partnerPrice = Number(newPrice);
        if (isPartner) {
          setPartnerDiscount(Number(newPrice) * 0.2);
          partnerPrice = partnerPrice * 0.8;
        }

        // Store Prices
        setSubTotal(newPrice + proRateAmount)
        setTotal(partnerPrice)

        // Confirmation Data
        let newDisplayData = {}
        newDisplayData[selectedPass] = {
          currentPass: selectedPassData.pass_type,
          newViewers: maxViewers + newViewers,
          additionalTime: duration + ' ' + durationUnits[0].toUpperCase() + durationUnits.slice(1),
          price: '$' + partnerPrice.toFixed(2)
        }
        setDisplayData(newDisplayData);
        setActionData({
          url:'/api/modify_access/',
          postData: {
            pass_to_update: selectedPass,
            additional_time: duration,
            additional_time_units: durationUnits,
            additional_viewers: newViewers,
            price: partnerPrice,
          },
          actionText: 'adding viewers and time'
        });

        // Allow checkout
        if (
          ((props.addViewers && newViewers > 0) ||
          (props.addTime && duration > 0)) &&
          overlapWarning === null
        ) {
          setAllowCheckout(true);
        } else {
          setAllowCheckout(false);
        }
      }
    }
  }, [newViewers, duration, durationUnits, selectedPassData, isPartner, overlapWarning]);

  return (
    <Grid container className={classes.container}>
      <Grid item xs={12}>

        {/* Desktop */}
        <Hidden smDown>
          <Grid
            container
            className={props.cssClasses.tableHeader}
          >
            <Grid item xs={2} align="center" className={props.cssClasses.tableCell}>
              Current Expiration
            </Grid>
            <Grid item xs={1} align="center" className={props.cssClasses.tableCell}>
              Current Viewers
            </Grid>
            {
              props.addViewers &&
              <Grid item xs={props.addTime ? 1 : 2} align="center" className={props.cssClasses.tableCell}>
                How Many?
              </Grid>
            }
            {
              props.addViewers &&
              <Grid item xs={props.addTime ? 1 : 2} align="center" className={props.cssClasses.tableCell}>
                New Viewers
              </Grid>
            }
            {
              props.addTime &&
              <Grid item xs={props.addViewers ? 1 : 2} align="center" className={props.cssClasses.tableCell}>
                Duration
              </Grid>
            }
            {
              props.addTime &&
              <Grid item xs={props.addViewers ? 1 : 2} align="center" className={props.cssClasses.tableCell}>
                Pass Type
              </Grid>
            }
            <Grid item xs={2} align="center" className={props.cssClasses.tableCell}>
              New Expiration
            </Grid>
            <Grid item xs={2} align="right" className={props.cssClasses.tableCell}>
              Total
            </Grid>
            <Grid item xs={1}></Grid>
          </Grid>

          <Grid container className={props.cssClasses.tableBody}>
            <Grid item xs={2} align="center" className={props.cssClasses.tableCell}>
              <Typography>{currentExpirationDisplay}</Typography>
            </Grid>
            <Grid item xs={1} align="center" className={props.cssClasses.tableCell}>
              <Typography>{maxViewers}</Typography>
            </Grid>
            {
              props.addViewers &&
              <Grid item xs={props.addTime ? 1 : 2} align="center" className={props.cssClasses.tableCell}>
                <Input
                  type="number"
                  value={newViewers}
                  onChange={(e) => setNewViewers(Number(e.target.value))}
                  variant="contained"
                  color="primary"
                  autoFocus
                  inputProps={{
                    min: props.addViewers ? 1 : 0,
                  }}
                />
              </Grid>
            }
            {
              props.addViewers &&
              <Grid item xs={props.addTime ? 1 : 2} align="center" className={props.cssClasses.tableCell}>
                <Typography>{maxViewers + newViewers}</Typography>
              </Grid>
            }
            {
              props.addTime &&
              <Grid item xs={props.addViewers ? 1 : 2} align="center" className={props.cssClasses.tableCell}>
                <Input
                  type="number"
                  value={duration}
                  onChange={e => setDuration(Number(e.target.value))}
                  label="Choose Pass Duration"
                  required
                  color="primary"
                  inputProps={{
                    min: 0, // Must be 0 for auto duration_units switching to work
                  }}
                  style={{width:'60px'}}
                />
              </Grid>
            }
            {
              props.addTime &&
              <Grid item xs={props.addViewers ? 1 : 2} align="center" className={props.cssClasses.tableCell}>
                <Select
                  type="number"
                  value={durationUnits}
                  onChange={e => setDurationUnits(e.target.value)}
                  label="Choose Pass Type"
                  required
                  color="primary"
                >
                  <MenuItem value="hours">Hours</MenuItem>
                  <MenuItem value="days">Days</MenuItem>
                </Select>
              </Grid>
            }
            <Grid item xs={2} align="center" className={props.cssClasses.tableCell}>
              <Typography>{newExpirationDisplay}</Typography>
            </Grid>
            <Grid item xs={2} align="right" className={props.cssClasses.tableCell}>
              <Typography className={classes.subTotal}>
                SubTotal: ${subTotal.toFixed(2)}
              </Typography>
              {
                proRateDiscount > 0 &&
                <Typography className={classes.discounts}>
                  ProRate: (- ${proRateDiscount.toFixed(2)})
                </Typography>
              }
              {
                isPartner &&
                <Typography className={classes.discounts}>
                  Partner: (- ${partnerDiscount.toFixed(2)})
                </Typography>
              }
              <Typography className={classes.total}>
                Total: ${total.toFixed(2)}
              </Typography>
            </Grid>
            <Grid item xs={1} align="center" className={props.cssClasses.tableCell}>
              <Button
                color="primary"
                variant="contained"
                onClick={() => props.changeStep('Confirm', displayData, actionData, false)}
                disabled={!allowCheckout}
              >
                Checkout
              </Button>
            </Grid>
          </Grid>
        </Hidden>

        {/* Mobile */}
        <Hidden mdUp>
          <Grid container className={props.cssClasses.tableHeader}>
            <Grid item xs={12} align="center" className={props.cssClasses.tableCell}>
              Current Expiration
            </Grid>
          </Grid>

          <Grid container className={props.cssClasses.tableBody}>
            <Grid item xs={12} align="center" className={props.cssClasses.tableCell}>
              <Typography>{currentExpirationDisplay}</Typography>
            </Grid>
          </Grid>

          <Grid container className={props.cssClasses.tableHeader}>
            <Grid item xs={12} align="center" className={props.cssClasses.tableCell}>
              Current Viewers
            </Grid>
          </Grid>

          <Grid container className={props.cssClasses.tableBody}>
            <Grid item xs={12} align="center" className={props.cssClasses.tableCell}>
              <Typography>{maxViewers}</Typography>
            </Grid>
          </Grid>

          {
            props.addViewers &&
            <Grid container className={props.cssClasses.tableHeader}>
              <Grid item xs={12} align="center" className={props.cssClasses.tableCell}>
                How Many?
              </Grid>
            </Grid>
          }

          {
            props.addViewers &&
            <Grid container className={props.cssClasses.tableBody}>
              <Grid item xs={12} align="center" className={props.cssClasses.tableCell}>
                <Input
                  type="number"
                  value={newViewers}
                  onChange={(e) => setNewViewers(Number(e.target.value))}
                  variant="contained"
                  color="primary"
                  autoFocus
                  inputProps={{
                    min: 0,
                  }}
                />
              </Grid>
            </Grid>
          }

          {
            props.addViewers &&
            <Grid container className={props.cssClasses.tableHeader}>
              <Grid item xs={12} align="center" className={props.cssClasses.tableCell}>
                New Viewers
              </Grid>
            </Grid>
          }

          {
            props.addViewers &&
            <Grid container className={props.cssClasses.tableBody}>
              <Grid item xs={12} align="center" className={props.cssClasses.tableCell}>
                <Typography>{maxViewers + newViewers}</Typography>
              </Grid>
            </Grid>
          }

          {
            props.addTime &&
            <Grid container className={props.cssClasses.tableHeader}>
              <Grid item xs={12} align="center" className={props.cssClasses.tableCell}>
                Duration
              </Grid>
            </Grid>
          }

          {
            props.addTime &&
            <Grid container className={props.cssClasses.tableBody}>
              <Grid item xs={12} align="center" className={props.cssClasses.tableCell}>
                <Input
                  type="number"
                  value={duration}
                  onChange={e => setDuration(Number(e.target.value))}
                  label="Choose Pass Duration"
                  required
                  color="primary"
                  inputProps={{
                    min: 0,
                  }}
                  style={{width:'60px'}}
                />
              </Grid>
            </Grid>
          }

          {
            props.addTime &&
            <Grid container className={props.cssClasses.tableHeader}>
              <Grid item xs={12} align="center" className={props.cssClasses.tableCell}>
                Pass Type
              </Grid>
            </Grid>
          }

          {
            props.addTime &&
            <Grid container className={props.cssClasses.tableBody}>
              <Grid item xs={12} align="center" className={props.cssClasses.tableCell}>
                <Select
                  value={durationUnits}
                  onChange={e => setDurationUnits(e.target.value)}
                  label="Choose Pass Type"
                  required
                  color="primary"
                >
                  <MenuItem value="hours">Hours</MenuItem>
                  <MenuItem value="days">Days</MenuItem>
                </Select>
              </Grid>
            </Grid>
          }

          <Grid container className={props.cssClasses.tableHeader}>
            <Grid item xs={12} align="center" className={props.cssClasses.tableCell}>
              New Expiration
            </Grid>
          </Grid>

          <Grid container className={props.cssClasses.tableBody}>
            <Grid item xs={12} align="center" className={props.cssClasses.tableCell}>
              <Typography>{newExpirationDisplay}</Typography>
            </Grid>
          </Grid>

          <Grid container className={props.cssClasses.tableHeader}>
            <Grid item xs={12} align="center" className={props.cssClasses.tableCell}>
              Total
            </Grid>
          </Grid>

          <Grid container className={props.cssClasses.tableBody}>
            <Grid item xs={12} align="right" className={props.cssClasses.tableCell}>
              <Typography className={classes.subTotal}>
                SubTotal: ${subTotal.toFixed(2)}
              </Typography>
              {
                proRateDiscount > 0 &&
                <Typography className={classes.discounts}>
                  ProRate: (- ${proRateDiscount.toFixed(2)})
                </Typography>
              }
              {
                isPartner &&
                <Typography className={classes.discounts}>
                  Partner: (- ${partnerDiscount.toFixed(2)})
                </Typography>
              }
              <Typography className={classes.total}>
                Total: ${total.toFixed(2)}
              </Typography>
            </Grid>
          </Grid>

          <Grid container className={props.cssClasses.tableHeader}>
            <Grid item xs={12}></Grid>
          </Grid>

          <Grid container className={props.cssClasses.tableBody}>
            <Grid item xs={12} align="center" className={props.cssClasses.tableCell}>
              <Button
                color="primary"
                variant="contained"
                onClick={() => props.changeStep('Confirm', displayData, actionData, false)}
                disabled={!allowCheckout}
              >
                Checkout
              </Button>
            </Grid>
          </Grid>
        </Hidden>

        {/* Error messages */}
        <Grid container className={classes.messages}>

        {
          overlapWarning &&
          <Grid item xs={12} className={props.cssClasses.tableCell}>
            <Typography>
              {overlapWarning}
            </Typography>
            <Typography>
              Duration set back to {duration}
            </Typography>
            <Typography>
              {'Would you like to '}
              <Link
                onClick={() => props.changeStep('Manage Scheduled', {}, {}, false)}
              >
                Manage Scheduled Pass
              </Link>
              {' instead?'}
            </Typography>
          </Grid>
        }

        {
          monthlyWarning &&
          <Grid item xs={12} className={props.cssClasses.tableCell}>
            <Typography>
              A monthly subscription may save you money.
            </Typography>
            <Typography>
              {'Would you like to '}
              <Link
                onClick={() => props.changeStep('Purchase Subscription', {}, {}, false)}
              >
                Purchase a Subscription
              </Link>
              {' instead?'}
            </Typography>
          </Grid>
        }
      </Grid>
    </Grid>
  </Grid>
  );

}
