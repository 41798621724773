import React, { useEffect, useState } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import {
Grid,
Input,
Select,
MenuItem,
Button,
Backdrop,
CircularProgress,
LinearProgress,
Typography,
Link,
InputLabel,
} from '@material-ui/core/';
import StripePayments from './stripe_payments';
import StripeProvider from './StripeProvider/stripe_provider';
import { useSnackbar } from 'notistack';
import { isMobile } from '../../utils'
import useDashboardAccount from '../Dashboard/Context/useDashboardAccount';
import logToServer from '../../utils/log_to_server';

const useStyles = makeStyles((theme) => createStyles({
  container: {
    padding: theme.spacing(2),
  },
  padding: {
    padding: theme.spacing(4)
  }
}));

export default function SubscriptionSelect(props) {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const {
    customer_uuid
  } = useDashboardAccount();

  // State
  const [inProgress, setInProgress] = useState(false);
  const [viewers, setViewers] = useState(1);
  const [term, setTerm] = useState('');
  const [selectedPrice, setSelectedPrice] = useState('');
  const [discount, setDiscount] = useState();
  const [firstMonth, setFirstMonth] = useState();
  const [promo, setPromo] = useState();
  const [total, setTotal] = useState(0);
  const [subscriptionId, setSubscriptionId] = useState();
  const [clientSecret, setClientSecret] = useState();
  const [products, setProducts] = useState();
  const [prices, setPrices] = useState();
  const [coupons, setCoupons] = useState([]);
  const [promoCode, setPromoCode] = useState();
  const [promoInProgress, setPromoInProgress] = useState(false);
  const [appliedCoupon, setAppliedCoupon] = useState();
  const [appliedPromo, setAppliedPromo] = useState();
  const [termLabel, setTermLabel] = useState('');
  const [uuid, setUuid] = useState(customer_uuid);

  useEffect(() => {
    // check for landing here from stripe redirect
    let urlParams = window.location.search;
    let searchParams = new URLSearchParams(urlParams);
    let payment_intent = searchParams.get('payment_intent')
    let client_secret = searchParams.get('payment_intent_client_secret')
    if (client_secret) {
      setClientSecret(client_secret);
    } else {
      // Get prices on load
      getPricesList();
    }
  }, []);

  useEffect(() => {
    if (props.uuid) {
      setUuid(props.uuid);
    } else if (customer_uuid) {
      setUuid(customer_uuid);
    }
  }, [props.uuid, customer_uuid]);

  // Update cost
  useEffect(() => {
    let price = prices?.find(p => p.product === term);
    if (price && price.tiers && price.tiers.length > 0) {
      // Update stored data
      setSelectedPrice(price.price_id);

      let cost = 0

      price.tiers.forEach((tier, i) => {
        if (tier.up_to !== null && viewers >= tier.up_to) {
          if (i === 0) { // Handle first tier
            cost += tier.unit_amount
          } else {
            let viewersInTier = tier.up_to - price.tiers[i-1]?.up_to;
            cost += tier.unit_amount * viewersInTier
          }
        } else if (tier.up_to !== null && viewers < tier.up_to && viewers > price.tiers[i-1]?.up_to) { // Middle Tiers
          let viewersInTier = viewers - price.tiers[i-1]?.up_to;
          cost += tier.unit_amount * viewersInTier
        } else if (tier.up_to === null && viewers > price.tiers[i-1]?.up_to) { // Highest Tier
          let viewersInTier = viewers - price.tiers[i-1]?.up_to;
          cost += tier.unit_amount * viewersInTier
        }
      });

      let product = products?.find(p => p.product_id === price.product);
      if (product) {
        setTermLabel(product.description.substring(0, product.description.indexOf(' ')))
        
        // Apply coupons
        // Disabled for now
        // if (
        //   coupons && 
        //   coupons.length > 0 &&
        //   coupons[0].applies_to &&
        //   coupons[0].applies_to.length > 0
        // ) {
        //   // TODO: Add support for multiple coupons??
        //   // Currently only the first coupon returned is supported.

        //   if (coupons[0].applies_to.includes(product.product_id)) {
        //     setAppliedCoupon(coupons[0].coupon_id)

        //     let discount;
            
        //     // Percentage Discount
        //     if (coupons[0].percent_off) {
        //       discount = cost * (coupons[0].percent_off / 100);
        //     }
            
        //     // Fixed Discount
        //     if (coupons[0].amount_off) {
        //       discount = coupons[0].amount_off;
        //     }
    
        //     // Store
        //     setDiscount(discount / 100);
        //     setFirstMonth((cost - discount) / 100);
        //   }
        // }

        // Apply Promo Codes
        if (
          promo &&
          promo.coupon &&
          promo.id
        ) {

          setAppliedPromo(promo.id)

          let discount;
          
          // Percentage Discount
          if (promo.coupon.percent_off) {
            discount = cost * (promo.coupon.percent_off / 100);
          }
          
          // Fixed Discount
          if (promo.coupon.amount_off) {
            discount = promo.coupon.amount_off;
          }
  
          // Store
          setDiscount(discount / 100);
          setFirstMonth((cost - discount) / 100);
        }
      }

      // Adujst decimal for display and store
      setTotal(cost / 100);
    }
  }, [products, prices, term, viewers, coupons, promo]);

  useEffect(() => {
    if (term === '' && products?.length > 0 && prices?.length > 0) {
      let monthly = prices?.filter(price => price.recurring?.interval === 'month');
      if (monthly?.length > 0) {
        setTerm(monthly[0].product);
      } else {
        setTerm(products[0].product_id);
      }
    }
  }, [products, prices]);

  // Get prices and products
  const getPricesList = async () => {
    try {

      let getPrices = await fetch(process.env.REACT_APP_HOST_URL + '/api/stripe/prices/subscription');
      if (getPrices.ok) {
        let response = await getPrices.json();
        if (response && response.prices && response.products) {
          setPrices(response.prices);
          setProducts(response.products);
          setCoupons(response.coupons);
        }
      } else {
        enqueueSnackbar('We had an issue loading subcriptions.  Please refresh the page and try again.', { variant: 'warning' });
        logToServer({
          section: 'Subscriptions',
          action: 'Get subscription prices',
          result: 'Error',
          status: getPrices.status,
        });
      }
    } catch (e) {
      console.error('Network error retreiving prices');
      logToServer({
        section: 'Subscriptions',
        action: 'Get subscription prices',
        result: 'Network Error',
        message: e.message,
        name: e.name,
        stack: e.stack,
      });
      setTimeout(getPricesList(), 1000);
    } finally {
      setInProgress(false);
    }
  }

  // Create a new subscription and display payment form
  const createSubscription = async () => {
    setInProgress(true);
    try {
      // Data check
      if (selectedPrice === '') { throw new Error('Please check the billing terms and try again')}

      // Create subscription
      let fetchURL = process.env.REACT_APP_HOST_URL + '/api/stripe/subscriptions/create/' + uuid;
      let fetchData = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          redirect_url: window.location.href,
          price_id: selectedPrice,
          // coupon_id: appliedCoupon, // Disabled for now
          promo_code: appliedPromo,
          viewers: viewers,
        })
      }
      let res = await fetch(fetchURL, fetchData);
      if (res.ok) {
        let response = await res.json();
        // Check data
        if (response.subscriptionId && response.client_secret) {
          // Create Payment Element and display
          setClientSecret(response.client_secret);
          setSubscriptionId(response.subscriptionId);
        } else {
          throw new Error('Please try again');
        }
        setInProgress(false);

      } else {
        // Non 200 response
        setInProgress(false);
        enqueueSnackbar('Looks like we had an issue - ' + res.status , { variant: 'warning' });
        console.error('Could not create subscription.  Response ', res.status);
        logToServer({
          section: 'Subscriptions',
          action: 'Create subscription',
          result: 'Failed',
          status: res.status,
        });
      }
    } catch (e) {
      setInProgress(false);
      enqueueSnackbar('Looks like we had an issue ' + e.message, { variant: 'warning' });
      console.error('Network error screating subscription ', e);
      logToServer({
        section: 'Subscriptions',
        action: 'Create subscription',
        result: 'Network Error',
        message: e.message,
        name: e.name,
        stack: e.stack
      });
    }
  }

  const applyPromo = async () => {
    setPromoInProgress(true);
    try {
      let fetchURL = process.env.REACT_APP_HOST_URL + '/api/stripe/promo/' + uuid;
      let fetchObj = {
        method: 'POST',
        headers: {
          'Content-Type':'application/json',
        },
        body: JSON.stringify({
          promo_code: promoCode
        })
      }
      let res = await fetch(fetchURL, fetchObj);
      if (res.ok) {
        let response = await res.json();
        // Check data
        if (response.type.toLowerCase() === 'success' && response.promo) {
          setPromo(response.promo)
          // setPromoCode('');
          setPromoInProgress(false);
        } else {
          setPromoCode('');
          setPromoInProgress(false);
          enqueueSnackbar('Promo Code could not be applied', { variant: 'warning' });  
        }
      } else {
        // Non 200 response
        setPromoCode('');
        setPromoInProgress(false);
        enqueueSnackbar('Looks like we had an issue - ' + res.status , { variant: 'warning' });
        console.error('Could not apply promo.  Response ', res.status);
        logToServer({
          section: 'Subscriptions',
          action: 'Apply Promo Code',
          result: 'Failed',
          status: res.status,
        });
      }     
    } catch (e) {
      setPromoCode('');
      setPromoInProgress(false);
      enqueueSnackbar('Looks like we had an issue ' + e.message, { variant: 'warning' });
      console.error('Network error applying promo to subscription ', e);
      logToServer({
        section: 'Subscriptions',
        action: 'Apply Promo',
        result: 'Network Error',
        message: e.message,
        name: e.name,
        stack: e.stack
      });
    }
  }

  return (
    <Grid container className={classes.container}>
      {
        products &&
        prices &&
        <Grid item xs={12}>
          Subscribe to SetStream
        </Grid>,

        <Grid item xs={12} className={props.cssClasses.tableHeader}>

        </Grid>,

        <Grid item xs={12} className={props.cssClasses.tableBody}>
          <Grid container className={classes.padding}>

            <Grid item xs={12} md={6} align={isMobile ? "center" : "right"} className={props.cssClasses.tableCell}>
              {
                products &&
                <img src="/shop/subscription_pass_square.png" alt="Subscription" height={isMobile ? " 100px" : "250px"} />
              }
              {
                !products &&
                <Grid container>
                  <Grid item xs={12} style={{paddingRight:'24px'}}>
                    <CircularProgress color="primary" variant="indeterminate" />
                  </Grid>
                </Grid>
              }
            </Grid>

            <Grid item xs={12} md={6} align={isMobile ? "center" : "left"} className={props.cssClasses.tableCell}>
              {
                products &&
                <Grid container style={{maxWidth:'300px'}}>

                <Grid item xs={12}>
                  <Select
                    id="Billing Term"
                    labelId="billing_term"
                    label="Billing Term"
                    variant="outlined"
                    fullWidth
                    color="primary"
                    value={term}
                    onChange={e => setTerm(e.target.value)}
                  >
                    {
                      products &&
                      products.map(product => (
                        <MenuItem
                          value={product.product_id}
                          key={product.product_id}
                        >
                          {product.description}
                        </MenuItem>
                      ))
                    }
                  </Select>
                </Grid>

                <Grid item xs={12} className={classes.padding}></Grid>

                <Grid item xs={12}>
                  <Input
                    type="number"
                    value={viewers}
                    onChange={e => setViewers(Number(e.target.value))}
                    label="Viewers"
                    required
                    color="primary"
                    autoFocus
                    style={{width: '100%', textAlign: 'right'}}
                    inputProps={{
                      min: 1,
                      max: 45,
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  # of Viewers
                </Grid>

                <Grid item xs={12} className={classes.padding}></Grid>

                <Grid item xs={12} className={props.cssClasses.tableCell}>
                  <Grid container>
                    <Grid item xs={12} sm={8}>
                      <Input
                        type="text"
                        value={promoCode}
                        onChange={e => setPromoCode(e.target.value)}
                        placeholder="Promo Code"
                      />
                    </Grid>

                    <Grid item xs={12} sm={4}>
                      <Button
                        onClick={applyPromo}
                        variant="contained"
                        color="primary"
                        disabled={promo}
                      >
                        Apply Code
                      </Button>
                      {
                        promoInProgress &&
                        <CircularProgress color="primary" variant="indeterminate" />
                      }
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={12} className={classes.padding}></Grid>

                <Grid item xs={12} className={props.cssClasses.tableCell}>
                  { 
                    discount >= 0 &&
                    firstMonth >= 0 &&
                    <Typography variant="h5">
                      First {termLabel.toLowerCase() === 'annual' ? 'Year' : 'Month'}: ${firstMonth}
                    </Typography>
                  }
                  { 
                    discount >= 0 &&
                    firstMonth >= 0 &&
                    <Typography variant="subtitle">
                      then
                    </Typography>
                  }
                </Grid>

                <Grid item xs={12} className={props.cssClasses.tableCell}>
                  <Typography variant="h6" display="inline">
                    {termLabel.toLocaleLowerCase() === 'annual' ? 'Yearly' : termLabel}:
                  </Typography>
                  <Typography display="inline" variant="h5" gutterBottom>
                    {' $' + total}
                  </Typography>
                </Grid>

                <Grid item xs={12} className={classes.padding}>
                </Grid>

                <Grid item xs={12} className={props.cssClasses.tableCell}>
                  <Button
                    id="Checkout"
                    color="primary"
                    onClick={createSubscription}
                    disabled={(inProgress || !uuid)}
                  >
                    Checkout
                  </Button>
                </Grid>

              </Grid>
            }
            </Grid>

            <Grid item xs={12} className={classes.padding}></Grid>

            <Grid item xs={12} align="center">
              {
                products &&
                <Typography>
                  Your subscription will automatically renew at the start of each billing cycle with your payment method on file.
                  For more info see our
                  <Link
                    href={process.env.REACT_TERMS}
                    title="terms"
                    target="_blank"
                  >
                    {' Terms'}
                  </Link>
                </Typography>
              }
            </Grid>
          </Grid>
        </Grid>
      }

      {
        <Grid item xs={12}>
          {/* Checkout form */}
          {
            clientSecret &&
            <StripeProvider clientSecret={clientSecret}>
              <StripePayments handleClose={props.handleClose} />
            </StripeProvider>
          }
        </Grid>
      }

      <Backdrop open={inProgress} style={{zIndex:2001}}>
        <CircularProgress color="primary" variant="indeterminate" />
      </Backdrop>
    </Grid>
  );

}
