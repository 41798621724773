import React, { useEffect, useState } from 'react';
import { createStyles, Theme } from '@material-ui/core/styles';
import useDevices from '../../../hooks/useDevices/useDevices';
import {
Button,
} from '@material-ui/core/';
import DevicesIcon from '@material-ui/icons/Devices';
import DeviceChangeDialog from '../MenuBar/DeviceChangeDialog';

const useStyles = createStyles(theme => ({

}));

export default function DeviceChangeButton(props) {
  const classes = useStyles();
  const [isOpen, setIsOpen] = useState(false);
  const { hasVideoInputDevices } = useDevices();


  useEffect(() => {

    return () => {
    };
  }, []);

  return (
    <>
      <Button
        onClick={() => setIsOpen(!isOpen)}
        className={props.className}
        disabled={!hasVideoInputDevices || props.disabled}
        startIcon={<DevicesIcon />}
      >
        Change Devices
      </Button>
      <DeviceChangeDialog isOpen={isOpen} setIsOpen={setIsOpen} />
    </>
  );

}
