import React, { Component } from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import Link from '@material-ui/core/Link';
import Paper from '@material-ui/core/Paper';
import { Button } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import { Container, Grid } from '@material-ui/core';
import { Table, TableContainer, TableRow, TableBody, TableCell, TableHead, TablePagination } from '@material-ui/core';
import axios from 'axios';
import { withRouter } from 'react-router-dom';
import { withSnackbar } from 'notistack';

// import classes from '../css/app.module.css';

const tableHeaders = [
  '_id',
  'project_name',
  'purchasing_user_uuid',
  'publisher_uuid',
  'broadcaster_uuid',
  'subscriber_uuid',
  'publisher_token_id',
  'subscriber_token_id',
  'last_pass_activated_at',
  'expires_on',
  'connected_users_count',
]

class SessionList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      message: [],
      authorized_users: []
    }

    this.listSessions = this.listSessions.bind(this);
  }

  Copyright() {
    return (
      <Typography variant="body2" color="textSecondary" align="center">
        {'Copyright © '}
        <Link color="inherit" href="https://setstream.io">
          SetStream.io
        </Link>{' '}
        {new Date().getFullYear()}
        {'.'}
      </Typography>
    );
  }

  listSessions() {
    let opts = {
      'url': process.env.REACT_APP_HOST_URL + '/api/active_sessions_list/' + this.props.userName,
      'method': 'post',
      'data': tableHeaders
    }
    axios(opts)
    .then(res => {
      switch (res.data.type) {
        case 'Success':
          this.setState({
            message: res.data.activeSessionsList,
          });
          break;
        case 'logged_out':
          console.log('SL User is not logged in ');
          this.props.enqueueSnackbar('It appears you are not logged in', { variant: 'warning' });
          this.props.history.push('/login');
          break;
        case 'unauthorized':
          this.props.enqueueSnackbar('You are not authorized for this page.', {variant: 'error'})
          this.props.history.push('/login');
          break;
        case 'error':
          console.log('Error ', res.data.message);
          // this.props.enqueueSnackbar('Network Error '+ res.data.message, { variant: 'error' });
          break;
        default:

      }
    })
    .catch(err => {
      console.error('Error - ', err);
      // this.props.enqueueSnackbar('Network Error', { variant: 'error' });
    });
  }

  // Sum total connected users
  connected_users() {
    let con_users = 0;
    let user_count_index = tableHeaders.indexOf('connected_users_count');

    this.state.message.forEach((session, i) => {
      if (session && session[user_count_index]) {
        con_users += session[user_count_index]
      }
    });
    return con_users;
  }

  // Lifecycle
  componentDidMount() {
    this.listSessions();
  }

  render() {
    return (
      <Grid container component="main" className={this.props.cssClasses.root}>
        <Container>
          <Grid item xs={12}>
            Connected Users - {this.connected_users()}
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h5">Sessions</Typography>
            <Button variant="contained" onClick={() => {this.listSessions()}}>Refresh</Button>
          </Grid>
          <Grid item>
            <TableContainer component={Paper} style={{'max-height':'calc(100vh - 200px)'}}>
              <Table size="small" aria-label="Sessions list" stickyHeader>
                <TableHead className={this.props.cssClasses.tableBody}>
                  <TableRow>
                  {
                    tableHeaders.map((userH, i) => {
                      return (
                        <TableCell key={'TCH'+i} component="th" className={this.props.cssClasses.tableHeader}>
                          {userH}
                        </TableCell>
                      )
                    })
                  }
                  </TableRow>
                </TableHead>
                <TableBody>
                  {
                    this.state.message.map((user, i) => {
                      let components = []
                      tableHeaders.map((meta, ii) => {
                        components.push(
                          <TableCell key={'TCB'+i+'-'+ii} className={this.props.cssClasses.tableText}>
                            {user[meta]}
                          </TableCell>
                        );
                        return true
                      })

                      return [<TableRow key={'TRB'+i}>
                        {components}
                        </TableRow>
                        ]
                    })
                  }
                </TableBody>
              </Table>
            </TableContainer>
            {/*}<TablePagination
              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              count={rows.length}
              rowsPerPage={this.state.rowsPerPage}
              page={this.state.page}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />*/}
          </Grid>
        </Container>
      </Grid>
    );
  }
}

export default withRouter((withSnackbar(SessionList)))
