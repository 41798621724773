import React, { useEffect, useState } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import {
  Grid,
  Card,
  CardMedia
} from '@material-ui/core/';
import useCamera from '../CameraProvider/useCamera';

const useStyles = makeStyles((theme) => createStyles({
  container: {
    // padding: theme.spacing(2),
    paddingLeft: theme.spacing(4)
  },
  videoBox: {
    backgroundColor: theme.palette.background.default
  }
}));

export default function Preview() {
  // Hooks
  const {
    color,
    logoLoop,
    videoElement,
    mediaStream,
    mediaStreamStatus
  } = useCamera();

  const classes = useStyles();

  // State

  // Effects
  useEffect(() => {
    if (
      mediaStreamStatus === "active" && 
      mediaStream && 
      videoElement.current
    ) {
      videoElement.current.srcObject = mediaStream;
    } else if (videoElement.current) {
      videoElement.current.srcObject = undefined;
    }
    
  }, [mediaStream, mediaStreamStatus]);

  // Actions

  // Render
  return (
    <Grid container className={classes.container}>
      <Grid item xs={12}>

        <Card
          elevation={4}
          className={classes.videoBox}
        >
          <CardMedia
            component = 'video'
            ref = {videoElement}
            autoPlay = {true}
            playsInline
            loop = {true}
            // muted = {true}
            src = {logoLoop}
          />
        </Card>
      </Grid>
    </Grid>
  );

}
