import React, { useState } from 'react';
import { DEFAULT_VIDEO_CONSTRAINTS, SELECTED_VIDEO_INPUT_KEY } from '../../../../../constants';
import { FormControl, MenuItem, Typography, Select, Grid } from '@material-ui/core';
import { LocalVideoTrack } from 'twilio-video';
import { makeStyles } from '@material-ui/core/styles';
import useDevices from '../../../../../hooks/useDevices/useDevices';
import useMediaStreamTrack from '../../../../../hooks/useMediaStreamTrack/useMediaStreamTrack';
import useVideoContext from '../../../../../hooks/useVideoContext/useVideoContext';
import ToggleVideoButton from '../../../Buttons/ToggleVideoButton/ToggleVideoButton';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    padding: theme.spacing(4),
    marginBottom: theme.spacing(6)
  },
  form: {
    maxWidth:'100%'
  },
  joinButtons: {
    width:'150px',
    [theme.breakpoints.down('sm')]: {
      width: '100%'
    },
    marginTop:'12px',
    marginBottom:'12px',
    marginLeft:'auto',
    marginRight:'auto',
    color: 'rgba(255,255,255,1)',
    backgroundColor: 'rgba(75,75,75,.85)',
    '&:hover': {
      backgroundColor: 'rgba(125,125,125,.85)',
    },
  },
}));

export default function VideoInputList({ disableButtons }: Boolean) {
  const classes = useStyles();
  const { videoInputDevices } = useDevices();
  const { localTracks } = useVideoContext();

  const localVideoTrack = localTracks.find(track => track.kind === 'video') as LocalVideoTrack | undefined;
  const mediaStreamTrack = useMediaStreamTrack(localVideoTrack);
  const [storedLocalVideoDeviceId, setStoredLocalVideoDeviceId] = useState(
    window.localStorage.getItem(SELECTED_VIDEO_INPUT_KEY)
  );
  const localVideoInputDeviceId = mediaStreamTrack?.getSettings().deviceId || storedLocalVideoDeviceId;

  function replaceTrack(newDeviceId: string) {
    // Here we store the device ID in the component state. This is so we can re-render this component display
    // to display the name of the selected device when it is changed while the users camera is off.
    setStoredLocalVideoDeviceId(newDeviceId);
    window.localStorage.setItem(SELECTED_VIDEO_INPUT_KEY, newDeviceId);
    localVideoTrack?.restart({
      ...(DEFAULT_VIDEO_CONSTRAINTS as {}),
      deviceId: { exact: newDeviceId },
    });
  }

  return (
    <div className={classes.container}>

      <Grid container alignItems="center" justify="space-between">
        <Grid item xs={12} sm={4}>
          <ToggleVideoButton disabled={disableButtons} className={classes.joinButtons} />
        </Grid>

        <Grid item xs={12} sm={8}>
          <Typography variant="subtitle2" gutterBottom>
          Video Input
          </Typography>

          {videoInputDevices.length > 1 ? (
            <FormControl className={classes.form}>
              <Select
                onChange={e => replaceTrack(e.target.value as string)}
                value={localVideoInputDeviceId || ''}
                variant="outlined"
              >
                {videoInputDevices.map(device => (
                  <MenuItem value={device.deviceId} key={device.deviceId}>
                    {device.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          ) : (
            <Typography style={{display:"inline"}}>{localVideoTrack?.mediaStreamTrack.label || 'No Local Video'}</Typography>
          )}
        </Grid>
      </Grid>

    </div>
  );
}
