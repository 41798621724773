import React, { useState, useEffect } from 'react';
import LocalVideoPreview from './LocalVideoPreview/LocalVideoPreview';
import { Steps } from '../PreJoinScreens';
// import ToggleAudioButton from '../../Buttons/ToggleAudioButton/ToggleAudioButton';
// import ToggleVideoButton from '../../Buttons/ToggleVideoButton/ToggleVideoButton';
import { useAppState } from '../../../../state';
import useVideoContext from '../../../../hooks/useVideoContext/useVideoContext';
import { useSnackbar } from 'notistack';
import useLocalAudioToggle from '../../../../hooks/useLocalAudioToggle/useLocalAudioToggle';
import SquareCard from '../../../Elements/SquareCard';
import AudioInputList from './AudioInputList/AudioInputList';
import AudioOutputList from './AudioOutputList/AudioOutputList';
import VideoInputList from './VideoInputList/VideoInputList';
import LinearProgress from '@material-ui/core/LinearProgress';

import {
  makeStyles,
  Grid,
  Button,
  Theme,
  Dialog,
  DialogContent
} from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    width: '650px',
    [theme.breakpoints.down('sm')]: {
      width: 'auto'
    },
  },
  gutterBottom: {
    marginBottom: '1em',
  },
  marginTop: {
    marginTop: '1em',
  },
  deviceButton: {
    width: '100%',
    border: '2px solid #aaa',
    margin: '1em 0',
  },
  localPreviewContainer: {
    paddingRight: '2em',
    [theme.breakpoints.down('sm')]: {
      padding: '0 2.5em',
    },
  },
  joinButtons: {
    width:'150px',
    [theme.breakpoints.down('sm')]: {
      width: '100px'
    },
    display:'block',
    marginTop:'12px',
    marginBottom:'12px',
    marginLeft:'auto',
    marginRight:'auto',
    color: 'rgba(255,255,255,1)',
    backgroundColor: 'rgba(75,75,75,.85)',
    '&:hover': {
      backgroundColor: 'rgba(125,125,125,.85)',
    },
  },
  connectButton: {
    width:'150px',
    [theme.breakpoints.down('sm')]: {
      width: '100px'
    },
    display:'block',
    marginTop:'12px',
    marginBottom:'12px',
    marginLeft:'auto',
    marginRight:'auto',
  },
  mobileButtonBar: {
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      justifyContent: 'space-between',
      margin: '1.5em 0 1em',
    },
  },
  mobileButton: {
    padding: '0.8em 0',
    margin: 0,
  },
}));

interface DeviceSelectionScreenProps {
  name: string;
  roomName: string;
  userUuid: string;
  setStep: (step: Steps) => void;
}

export default function DeviceSelectionDialog({ name, roomName, userUuid, setStep }: DeviceSelectionScreenProps) {
  const classes = useStyles();
  const { getToken, isFetching } = useAppState();
  const { connect, isAcquiringLocalTracks, isConnecting, isSharingScreen, toggleScreenShare, removeLocalAudioTrack, removeLocalVideoTrack } = useVideoContext();
  const disableButtons = isFetching || isAcquiringLocalTracks || isConnecting;
  const { enqueueSnackbar } = useSnackbar();
  const [isOpen, setIsOpen] = useState(true);
  const [isEnabled, toggleAudioEnabled] = useLocalAudioToggle();

  // Default user to muted
  useEffect(() => {
    if (!isAcquiringLocalTracks) {
      toggleAudioEnabled();
    }
  }, [isAcquiringLocalTracks])

  const handleJoin = () => {
    getToken(name, userUuid, roomName).then(token => {

      if (token && token.authToken) {
        // Connect to room
        connect(token.authToken)

        // Close modal
        setIsOpen(false);

        // Revert to device selection in case of error
        setStep(Steps.RoomConnectingStep);
      } else {
        // Session is not active, notify user
        enqueueSnackbar(token.message, {variant:'warning'})

        // Close camera
        handleCancel();
      }
    });
  };

  const handleCancel = () => {
    if (isSharingScreen) {
      toggleScreenShare();
    }
    removeLocalAudioTrack();
    removeLocalVideoTrack();
    setIsOpen(false);
    setStep(Steps.roomNameStep);
  }

  return (
    <Dialog
      open={isOpen}
      maxWidth="lg"
    >
      <DialogContent>
        <Grid container justify="center" className={classes.container}>
          <Grid item xs={12} style={{height: '24px', paddingBottom: '8px'}}>
            {
              disableButtons &&
              <LinearProgress
                variant="indeterminate"
              />
            }
          </Grid>
          <Grid item sm={12} md={3} style={{paddingTop: '20px'}}>
            <SquareCard border={false}>
              <LocalVideoPreview identity={name} />
            </SquareCard>

            <Grid container>
              <Grid item xs={6} md={12}>
                <Button
                  variant="contained"
                  onClick={handleCancel}
                  className={classes.joinButtons}
                >
                  Cancel
                </Button>
              </Grid>

              <Grid item xs={6} md={12}>
                <Button
                  className={classes.connectButton}
                  variant="contained"
                  color="primary"
                  data-cy-join-now
                  onClick={handleJoin}
                  disabled={disableButtons}
                >
                  Join Now
                </Button>
              </Grid>
            </Grid>

          </Grid>

          <Grid item xs={12} md={9}>

            <VideoInputList disableButtons={disableButtons} />
            <AudioInputList disableButtons={disableButtons} />
            <AudioOutputList />

          </Grid>


        </Grid>
      </DialogContent>
    </Dialog>
  );
}
