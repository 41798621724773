import React, { useEffect, useState } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import {
Grid,
Hidden,
Typography,
Select,
MenuItem,
InputLabel,
Backdrop,
CircularProgress,
Button,
} from '@material-ui/core/';
import { useSnackbar } from 'notistack';
import AvailablePassesList from '../Passes/Lists/available_passes_list';
import ScheduledPassesList from '../Passes/Lists/scheduled_passes_list';
import ActivePassesList from '../Passes/Lists/active_passes_list';
import ExpiredPassesList from '../Passes/Lists/expired_passes_list';
import DashboardPurchasePassDialog from '../Passes/Dialogs/dashboard_purchase_passes_dialog';
import usePusher from '../Pusher/usePusher';

const useStyles = makeStyles((theme) => createStyles({
  container: {
    padding: theme.spacing(2),
  }
}));

export default function PassesTab(props) {
  // Hooks
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  // State
  const [selected, setSelected] = useState('available_passes');
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  // Setup Pusher
  const [pusher, channel] = usePusher();
  useEffect(() => {
    if (channel) {
      channel.bind('update_dashboard', (message) => {
        setIsDialogOpen(false);
      });
    }
    return (() => {
      if (channel) {
        channel.unbind('update_dashboard');
      }
    })
  }, [pusher, channel]);

  // Effects
  useEffect(() => {
    return () => {
    };
  }, []);

  // Actions
  const handleClose = () => {
    setIsDialogOpen(false);
  }

  // Renders
  const renderList = () => {
    switch (selected) {
      case 'available_passes':
        return (<AvailablePassesList
          {...props}
        />)
        break;
      case 'scheduled_passes':
        return (<ScheduledPassesList
          {...props}
        />)
        break;
      case 'active_passes':
        return (<ActivePassesList
          {...props}
        />)
        break;
      case 'expired_passes':
        return (<ExpiredPassesList
          {...props}
        />)
        break;
      default:
        return (null);
        break;
    }
  }

  return (
    <Grid container className={classes.container}>
      <Grid item xs={12} className={props.cssClasses.expansion}>

        <Grid container>
          <Grid item xs={6}>
            <Select
              id="filter_passes"
              value={selected}
              onChange={e => setSelected(e.target.value)}
              variant="outlined"
              required
            >
              <MenuItem
                value="available_passes"
              >
                Available Passes
              </MenuItem>
              <MenuItem
                value="scheduled_passes"
              >
                Scheduled Passes
              </MenuItem>
              <MenuItem
                value="active_passes"
              >
                Active Passes
              </MenuItem>
              <MenuItem
                value="expired_passes"
              >
                Expired Passes
              </MenuItem>
            </Select>
          </Grid>

          <Grid item xs={6} align="right" style={{margin:'auto'}}>
            <Button
              variant="contained"
              color="primary"
              onClick={() => setIsDialogOpen(true)}
            >
              Purchase Pass
            </Button>
          </Grid>

          <Grid item xs={12} className={props.cssClasses.expansion}>
            {renderList()}
          </Grid>
        </Grid>

        <DashboardPurchasePassDialog
          isOpen={isDialogOpen}
          handleClose={handleClose}
          {...props}
        />

      </Grid>
    </Grid>
  );

}
