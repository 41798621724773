import React, { Component } from 'react';
import { Card, CardMedia, CircularProgress, Switch } from '@material-ui/core';
import { Grid } from '@material-ui/core';
import Cookies from 'js-cookie';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { withSnackbar } from 'notistack';
import Popover from '@material-ui/core/Popover';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import WifiIcon from '@material-ui/icons/Wifi';
import updateSdpAudioParameters from './Camera/CameraProvider/Utils/updateSdpAudioParameters';
import MenuOpenIcon from '@material-ui/icons/MenuOpen';

const axios = require('axios').default;

class WebPubPortal extends Component {
  constructor(props) {
    super(props)
    this.state = {
      message: '',
      cookies: Cookies.get(props.publisher_uuid),
      rtcUrl: null,
      rtcJwt: null,
      rtcStreamName: null,
      iceServers: null,
      rtcConnection: null,
      number_of_cameras: 1,
      cameras: [
        {
          label: 'A',
          enabled: true,
          open: false,
          anchorEl: null,
          color: 'rgba(226, 29, 29, .7)',
          selectedVideoDevice: '1',
          selectedAudioDevice: '1',
          selectedQuality: 2,
          selectedCodec: 'vp8',
          selectedEchoCancellation: false,
          audioChannels: 1,
          video_display: null,
          videoSource: React.createRef(),
          videoStream: new MediaStream(),
          rtcStreamName: null,
          rtcConnection: null,
          isConnected: false,
          connectionStatus: null
        },
        {
          label: 'B',
          enabled: false,
          open: false,
          anchorEl: null,
          color: 'rgba(50, 171, 223, .7)',
          selectedVideoDevice: '1',
          selectedAudioDevice: '1',
          selectedQuality: 2,
          selectedCodec: 'vp8',
          selectedEchoCancellation: false,
          audioChannels: 1,
          video_display: null,
          videoSource: React.createRef(),
          videoStream: new MediaStream(),
          rtcStreamName: null,
          rtcConnection: null,
          isConnected: false,
          connectionStatus: null
        },
        {
          label: 'C',
          enabled: false,
          open: false,
          anchorEl: null,
          color: 'rgba(226, 226, 29, .7)',
          selectedVideoDevice: '1',
          selectedAudioDevice: '1',
          selectedQuality: 2,
          selectedCodec: 'vp8',
          selectedEchoCancellation: false,
          audioChannels: 1,
          video_display: null,
          videoSource: React.createRef(),
          videoStream: new MediaStream(),
          rtcStreamName: null,
          rtcConnection: null,
          isConnected: false,
          connectionStatus: null
        },
        {
          label: 'D',
          enabled: false,
          open: false,
          anchorEl: null,
          color: 'rgba(71, 185, 105, .7)',
          selectedVideoDevice: '1',
          selectedAudioDevice: '1',
          selectedQuality: 2,
          selectedCodec: 'vp8',
          selectedEchoCancellation: false,
          audioChannels: 1,
          video_display: null,
          videoSource: React.createRef(),
          videoStream: new MediaStream(),
          rtcStreamName: null,
          rtcConnection: null,
          isConnected: false,
          connectionStatus: null
        }
      ],
      videoDevices: [{
        deviceId: '1',
        groupId: '',
        kind: 'videoinput',
        label: 'Please select a device',
      }],
      audioDevices: [{
        deviceId: '1',
        groupId: '',
        kind: 'audioinput',
        label: 'Please select a device',
      }],
      quality: [
        {
          id: 0,
          label: 'low (720p / 1000Kbps)',
          bitrate: '1000',
          resolution: '1280'
        },
        {
          id: 1,
          label: 'Medium (720p / 2000Kbps)',
          bitrate: '2000',
          resolution: '1280'
        },
        {
          id: 2,
          label: 'High (1080p / 3000 Kbps)',
          bitrate: '3000',
          resolution: '1920'
        },
        {
          id: 3,
          label: 'Super (1080p / 5000 Kbps)',
          bitrate: '5000',
          resolution: '1920'
        },
        {
          id: 4,
          label: 'Max (1080p / 8000 Kbps)',
          bitrate: '8000',
          resolution: '1920'
        }
      ],
      allowedCodecs: [
        {
          rate: 'vp8',
          label: 'VP8 - Default'
        },
        {
          rate: 'vp9',
          label: 'VP9 - iOS > 14 Required'
        },
        {
          rate: 'av1',
          label: 'AV1 - Chrome Only'
        }
      ],
      logStats: false,
    }
    this.logRTCSenderStats = this.logRTCSenderStats.bind(this);

  }

  // ***** GUM *****

  // Get a list of available devices and populate the dropdown
  getAvailableDevices() {
    // console.log('Getting video devices');
    navigator.mediaDevices.enumerateDevices()
    .then(devices => {
      // Get video devices
      const videoOnly = devices.filter(device => device.kind === 'videoinput');
      const audioOnly = devices.filter(device => device.kind === 'audioinput');

      // If the first device label is empty assume we have not requested Permission
      if (
        (videoOnly.length > 0 &&
        videoOnly[0].label === '') ||
        (audioOnly.length > 0 &&
        audioOnly[0].label === '')
      ) {
        // Open video device and populate device names
        navigator.mediaDevices.getUserMedia({
          video: videoOnly.length > 0 ? true : false,
          audio: audioOnly.length > 0 ? true : false
        })
        .then(stream => {
          // Check track capabilities
          stream.getTracks().forEach((track, i) => {
            // console.log('Track ', track, ' - with settings ', track.getSettings());
          });

          // enumerate devices again to get device names
          navigator.mediaDevices.enumerateDevices()
          .then(videoDevices => {

            // Filter by device type
            const vidOnly = videoDevices.filter(vdev => vdev.kind === 'videoinput');
            const audOnly = videoDevices.filter(vdev => vdev.kind === 'audioinput');

            // Add screen sharing option for non Safari browsers
            if (this.props.adapter.browserDetails.browser !== 'safari') {
              vidOnly.push({
                deviceId: 'screen',
                groupId: '',
                kind: 'videoinput',
                label: 'Screen Sharing'
              });
            }

            // Add a no audio option
            // if (audOnly.length === 0) {
              audOnly.push({
                deviceId: 'none',
                groupId: null,
                kind: 'audioinput',
                label: 'NO AUDIO'
              });
            // }

            // Set default selected devices
            let new_cameras = this.state.cameras;
            new_cameras.forEach((cam, i) => {

              // Get stored selection if it exists
              let selCam = sessionStorage.getItem(cam.label + '_cam_select');
              let selAud = sessionStorage.getItem(cam.label + '_aud_select');
              let selQual = sessionStorage.getItem(cam.label + '_qual_select');
              let selCodec = sessionStorage.getItem(cam.label + '_codec_select');
              let echoCan = sessionStorage.getItem(cam.label + '_echo_select');
              let audChan = sessionStorage.getItem(cam.label + '_audio_channels');

              // Select Video Device
              new_cameras[i].selectedVideoDevice = selCam ?
                selCam :
                vidOnly.length > 0 ?
                  vidOnly[0].deviceId :
                  'screen';

              // Select audio device
              new_cameras[i].selectedAudioDevice = selAud ?
                selAud :
                audOnly.length > 0 ?
                  audOnly[0].deviceId :
                  'none';

              // Select Quality
              new_cameras[i].selectedQuality = selQual ?
                Number(selQual) :
                2

              // Select Codec
              new_cameras[i].selectedCodec = selCodec ?
                selCodec :
                'vp8'

              new_cameras[i].selectedEchoCancellation = echoCan === 'true' ?
                true :
                false 

              new_cameras[i].audioChannels = audChan ?
                Number(audChan) :
                1
            });

            // Store device information
            this.setState({
              videoDevices: vidOnly,
              audioDevices: audOnly,
              cameras: new_cameras
            });
          })
          .catch(err => {
            console.error('Cannot read media devices the 2nd time around ', err);
            this.props.enqueueSnackbar('Could not load camera. This might be blocked by your browser' + err, { variant: 'warning' });
          })
          .finally(() => {
            // Stop stream created to get permissions
            stream.getTracks().forEach(track => {
              track.stop();
            });
          });
        })
        .catch(err => {
          console.error('Cannot get video media ', err);
          this.props.enqueueSnackbar('Unable to load input devices.' + err, { variant: 'warning' });
        });
      } else {
        // We have permsission already so populate the device list

        // Add screen sharing option for non Safari browsers
        if (this.props.adapter.browserDetails.browser !== 'safari') {
          videoOnly.push({
            deviceId: 'screen',
            groupId: '',
            kind: 'videoinput',
            label: 'Screen Sharing'
          });
        }

        // Add a no audio option
        // if (audioOnly.length === 0) {
          audioOnly.push({
            deviceId: 'none',
            groupId: null,
            kind: 'audioinput',
            label: 'NO AUDIO'
          });
        // }

        // Set default selected devices
        let default_cameras = this.state.cameras;
        default_cameras.forEach((cam, i) => {
          // Get stored selection if it exists
          let selCam = sessionStorage.getItem(cam.label + '_cam_select');
          let selAud = sessionStorage.getItem(cam.label + '_aud_select');
          let selQual = sessionStorage.getItem(cam.label + '_qual_select');
          let selCodec = sessionStorage.getItem(cam.label + '_codec_select');
          let echoCan = sessionStorage.getItem(cam.label + '_echo_select');
          let audChan = sessionStorage.getItem(cam.label + '_audio_channels');

          // Create default selection
          default_cameras[i].selectedVideoDevice = selCam ?
            selCam :
            videoOnly.length > 0 ?
              videoOnly[0].deviceId :
              'screen';

          // Create default selection
          default_cameras[i].selectedAudioDevice = selAud ?
            selAud :
            audioOnly.length > 0 ?
              audioOnly[0].deviceId :
              'none';

          // Select Quality
          default_cameras[i].selectedQuality = selQual ?
            Number(selQual) :
            2

          // Select Codec
          default_cameras[i].selectedCodec = selCodec ?
            selCodec :
            'vp8'

          default_cameras[i].selectedEchoCancellation = echoCan === 'true' ?
            true :
            false
          
          default_cameras[i].audioChannels = audChan ?
            Number(audChan) :
            1
        });


        // Store device information
        this.setState({
          videoDevices: videoOnly,
          audioDevices: audioOnly,
          cameras: default_cameras
        });

      }
    })
    .catch(err => {
      this.props.enqueueSnackbar('Could not start camera input ' + err.message, { variant: 'error' });
      console.error('GUM Error ', err.name, " - ", err.message);
    });
  }

  // Prepare for opening camera
  setConstraints(type, cam_index) {
    var availableConstraints = navigator.mediaDevices.getSupportedConstraints();
    // console.log('available constraints: ', availableConstraints);

    // Verify and set Audio Constraints
    let aConstraints = {
      deviceId: {exact: this.state.cameras[cam_index].selectedAudioDevice},
      autoGainControl: availableConstraints.autoGainControl ? false : undefined,
      echoCancellation: availableConstraints.echoCancellation ? this.state.cameras[cam_index].selectedEchoCancellation : undefined,
      noiseSupression: availableConstraints.noiseSuppression ? false : undefined,
      channelCount: availableConstraints.channelCount ? {
        max: 2,
        ideal: 2,
        min: 1
      } : undefined
    };

    // Verify and set video constraints
    let vConstraints = {
      deviceId: {exact: this.state.cameras[cam_index].selectedVideoDevice},
      aspectRatio: availableConstraints.aspectRatio ? (1920/1080) : undefined,
      frameRate: availableConstraints.frameRate ? { max: 30 } : undefined,
      facingMode: availableConstraints.facingMode ? "environment" : undefined,
      resizeMode: availableConstraints.resizeMode ? "none" : undefined,
      width: availableConstraints.width ? {
          max: 1920,
          // Force 720p for Firefox
          ideal: this.props.adapter.browserDetails.browser === "firefox" ? 1280 : this.state.quality[this.state.cameras[cam_index].selectedQuality].resolution,
          min: 960
        } : undefined,
      height: availableConstraints.height ? {
          max: 1080,
          min: 540
        } : undefined
    }

    // Verify and set Screen Sharing constriants
    let sConstraints = {
      video: {
        aspectRatio: availableConstraints.aspectRatio ? (1920/1080) : undefined,
        frameRate: availableConstraints.frameRate ? { max: 30 } : undefined,
        cursor: availableConstraints.cursor ? 'always' : undefined,
        resizeMode: availableConstraints.resizeMode ? 'crop-and-scale' : undefined,
        width: availableConstraints.width ? {
            max: 1920,
            // Force 720p for Firefox
            ideal: this.props.adapter.browserDetails.browser === "firefox" ? 1280 : this.state.quality[this.state.cameras[cam_index].selectedQuality].resolution,
          } : undefined,
        height: availableConstraints.height ? {
            max: 1080,
          } : undefined
      }
    }

    // Combine constraints and return
    let constraints = {
      'video': vConstraints
    }

    // Only add audio if it exists
    if (aConstraints.deviceId.exact !== 'none') {
      constraints.audio = aConstraints;
    }

    // console.log('all constraints ', constraints);
    switch (type) {
      case 'video':
        return {video: vConstraints};
        break;
      case 'audio':
        // Only return constraints if we are opening audio devices
        return {audio: aConstraints.deviceId.exact === 'none' ? null : aConstraints};
        break;
      case 'screen':
        return sConstraints;
        break;
      default:
        return constraints;
    }

  }

  // Open the camera and display stream on screen
  async openCamera(cam_index, startStreaming) {

    // Update connection status
    this.updateCameras(cam_index, "connectionStatus", "Starting Camera")

    // Close camera settings menu
    this.hide_camera_settings(cam_index);

    if (this.state.cameras[cam_index]?.rtcConnection) {
      this.closeCamera(cam_index);
    }

    // Make sure session is active
    if (this.props.isActive() === true) {

      // Check if we are getting camera or screen share
      if (this.state.cameras[cam_index].selectedVideoDevice === 'screen') {

        // Set Constraints
        const screenConstraints = this.setConstraints('screen', cam_index);
        const audioConstraints = this.setConstraints('audio', cam_index);

        try {

          this.updateCameras(cam_index, "connectionStatus", "Sharing Screen")

          let screenMedia = await navigator.mediaDevices.getDisplayMedia(screenConstraints);

          // Do we have audio?
          if (audioConstraints.audio) {
            let audioMedia = await navigator.mediaDevices.getUserMedia(audioConstraints);

            // Get tracks and add them to screenMedia
            let audioTracks = audioMedia.getAudioTracks();
            audioTracks.forEach(audio => {
              screenMedia.addTrack(audio)
            });
          }

          // Store media stream in state
          let new_cameras = this.state.cameras;
          new_cameras[cam_index].videoSource.current.srcObject = screenMedia;
          new_cameras[cam_index].videoStream = screenMedia;

          this.setState({
            cameras: new_cameras
          });

          // Should we start streaming
          if (startStreaming) {
            // Get ICE servers if we don't have them then create connection
            if (this.state.iceServers == null) {
              this.getIceServers()
              .then(result => {
                this.getPubInfo(screenMedia, cam_index, true);
              })
              .catch(err => {
                console.error('Error - Could not get ICE servers', err);
                this.updateCameras(cam_index, "connectionStatus", "ICE Error")
              });
            } else {
              this.getPubInfo(screenMedia, cam_index, true);
            }
          }


        } catch (err) {
          this.updateCameras(cam_index, "connectionStatus", null)

          this.props.enqueueSnackbar('Could not open devices ' + err, { variant: 'warning' });
          console.error('Could not open media devices ', + err);
        }

      } else { // Open camera

        // Set Constraints
        const myConstraints = this.setConstraints('All', cam_index);
        try {

          // Start Media Stream
          let userMedia = await navigator.mediaDevices.getUserMedia(myConstraints)

          // Store media stream in state
          let new_cameras = this.state.cameras;
          new_cameras[cam_index].videoSource.current.srcObject = userMedia;
          new_cameras[cam_index].videoStream = userMedia;

          this.setState({
            cameras: new_cameras
          });

          // Should we start streaming
          if (startStreaming) {
            // Get ICE servers if we don't have them then create connection
            if (this.state.iceServers == null) {
              this.getIceServers()
              .then(result => {
                this.getPubInfo(userMedia, cam_index, true);
              })
              .catch(err => {
                console.error('Error - Could not get ICE servers', err);
                this.updateCameras(cam_index, "connectionStatus", "ICE Error")
              });
            } else {
              this.getPubInfo(userMedia, cam_index, true);
            }
          }

        } catch (err) {
          this.updateCameras(cam_index, "connectionStatus", null)

          this.props.enqueueSnackbar('Could not open media device ' + err, { variant: 'warning' });
          console.error('openCamera error: ', err.name, ' - ', err);
        }
      }
    } else {
      this.updateCameras(cam_index, "connectionStatus", null)
      
      this.props.enqueueSnackbar('Please activate a session before beginning to stream', { variant: 'info' });
      console.log('Could not open camera - Session not active');
    }
  }

  // Close camera and stop stream
  closeCamera(cam_index) {
    this.updateCameras(cam_index, "connectionStatus", "Closing")

    // Close camera settings menu
    this.hide_camera_settings(cam_index);

    // Remove streams from rtcConnection
    if (this.state.cameras[cam_index]?.rtcConnection) {

      // Close connection
      this.state.cameras[cam_index].rtcConnection.close();
      delete this.state.cameras[cam_index].rtcConnection;
      this.updateCameras(cam_index, "rtcConnection", null);

      // // Mark as disconnected
      // this.updateCameras(cam_index, isConnected, false);
      console.log('Closed RTC Connection');
    }

    // If we have a WebSocket, close it
    if (this.state.cameras[cam_index]?.ws) {
      this.state.cameras[cam_index].ws.close();
      this.updateCameras(cam_index, 'ws', null);
    }

    // Stop stream tracks
    if (this.state.cameras[cam_index]?.videoSource?.current?.srcObject) {
      this.state.cameras[cam_index].videoSource.current.srcObject.getTracks().forEach(track => {
        track.stop();
        console.log('Stopped track ');
      });
      // Remove stream from html video element
      this.state.cameras[cam_index].videoSource.current.srcObject = undefined;
    }

    if (this.state.cameras[cam_index]?.videoStream) {
      this.state.cameras[cam_index].videoStream.getTracks().forEach(track => {
        track.stop();
        console.log('Stopped track ');
      });
    }

    this.updateCameras(cam_index, "connectionStatus", null)
    this.props.enqueueSnackbar('Streaming paused', { variant: 'info' });
    console.log('Camera stopped ', cam_index);
  }

  // ******************** end GUM ************************************

  // Get publishing info from server
  getPubInfo(userMedia, cam_index, to_connect) {

    if (cam_index) { this.updateCameras(cam_index, "connectionStatus", "Getting Info") }

    // Get ice servers if needed
    if (!this.state.iceServers) {
      this.getIceServers()
      .then(ice => {
        this.setState({
          iceServers: ice
        });
      })
      .catch(err => {
        console.error('Error - Could not get ICE servers on load', err);
        if (cam_index) { this.updateCameras(cam_index, "connectionStatus", "ICE Error") }
      });
    }

    axios.get(process.env.REACT_APP_HOST_URL + '/api/web/' + (this.props.is_broadcast_only ? 'Broadcast/' : 'Project/') + this.props.publisher_uuid)
    .then(res => {
      switch (res.data.type) {
        case 'inactive':
          if (cam_index) { this.updateCameras(cam_index, "connectionStatus", null) }
          console.log('Web No active pass');
          this.props.enqueueSnackbar("No active Pass.  Pleaes activate a pass and try again", { variant: "warning" })
          break;
        case 'error':
          console.log('Web Error ', res.data.message);
          if (cam_index) { this.updateCameras(cam_index, "connectionStatus", "Connection Error") }
          break;
        case 'success':

          // Get publish info for each camera
          this.state.cameras.forEach((camera, i) => {
              let streamName = res.data.streams.filter(stream => ( stream.streamName.split('_')[0] === this.state.cameras[i].label ));

              if (streamName.length > 0 && streamName[0].streamName) {
                // Store stream name
                let new_cameras = this.state.cameras;
                new_cameras[i].rtcStreamName = streamName[0].streamName;
                this.setState({
                  cameras: new_cameras,
                  rtcToken: res.data.token
                },
                () => {
                  if (i === cam_index && to_connect) {
                    this.getSubscribe(cam_index);
                  }
                });
              } else {
                console.error('Could not find stream name ', streamName);
                if (cam_index) { this.updateCameras(cam_index, "connectionStatus", "Stream Error") }
              }
          });

          console.log('RTC info received ', res.data.type);
          break;
        default:
        // Nothing happens here
      }

    })
    .catch(err => {
      
      console.error('Error - Could not get RTC Connection info', typeof err.Error, err.Error);
      
      if (err.Error === "Network Error") {
        if (cam_index) { this.updateCameras(cam_index, "connectionStatus", "Network Error") }
        this.props.enqueueSnackbar('Network Error ... Retrying', {variant: 'info'});
        // Retry connection on network error
        window.setTimeout(() => {
          this.getPubInfo(this.state.cameras[cam_index].videoStream, cam_index);
        }, 2000);
      } else {
        if (cam_index) { this.updateCameras(cam_index, "connectionStatus", "Connection Error") }
        this.props.enqueueSnackbar('We are having trouble connecting.  Please refresh the page and try again.');
      }
    });
    // console.log('Getting RTC connection info');
  }

  getSubscribe(cam_index) {

    this.updateCameras(cam_index, "connectionStatus", "Connecting .....")

    let opts = {
      url: 'https://director.millicast.com/api/director/publish',
      method: 'POST',
      headers: {
        Authorization: 'Bearer ' + this.state.rtcToken,
        'Content-Type': 'application/json'
      },
      data: {
        streamName: this.state.cameras[cam_index].rtcStreamName
      }
    }

    // Get publishing information
    axios(opts)
    .then(list => {
      if (list.data.status === 'success') {
        // Store publish data
        let new_cameras = this.state.cameras;
        new_cameras[cam_index].rtcUrl = list.data.data.wsUrl;
        new_cameras[cam_index].rtcJwt = list.data.data.jwt;

        this.setState({
          cameras: new_cameras
        },
        () => {
          // Create connection?
          this.connectRTC(this.state.cameras[cam_index].videoStream, cam_index);
        });

      } else {
        // this.props.enqueueSnackbar('Could not get publishing info.  Please contact support.');
        console.error(new Date(Date.now()).toISOString(), ' WEB PUB Could not get web pub info ', list.data);
      }
    })
    .catch(err => {
      if (err && err.response && err.response.status && err.response.status === 400) {
        // Stream has not begun.  Check back in 3 sec.
        console.log('Subscribe error ', err.response);
        setTimeout(() => {this.getSubscribe(cam_index)}, 3000);
      } else {
        this.updateCameras(cam_index, "connectionStatus", "Connection error, retrying ...")
        // An error occured.  Check back in 3 sec in case it resolves itself
        console.error('Error - Could not get connect info', err.message);
        // setTimeout(() => {this.getSubscribe(cam_index)}, 3000);
        this.closeCamera(cam_index);
        this.props.enqueueSnackbar('Connection failed ' + err.message, { variant: 'error' })
      }
    });
  }

  // ********************** Begin CDN ****************************

  getIceServers() {
    // console.log('Getting ICE');
    return new Promise(function(resolve, reject) {
      let iceServers = [];
      axios.put('https://turn.millicast.com/webrtc/_turn')
      .then(ice => {
        // console.log('Received ICE ', ice.data.s);
        if (ice.status === 200) {
          if (ice.data.s !== 'ok') {
            console.log('ICE return is not OK ', ice.data.s);
            resolve(iceServers);
          }
          let list = ice.data.v.iceServers;
          list.forEach((cred, i) => {
            let v = cred.url;
            if (!!v) {
              cred.urls = v;
              delete cred.url;
            }
            iceServers.push(cred);
            resolve(iceServers);
          });
        } else {
          console.log('Could not retreive ICE servers ', ice);
          resolve(iceServers);
        }
      })
      .catch(err => {
        console.log('Error - Could not get ICE servers', err);
        resolve(iceServers);
      });
    });
  }

  // Create RTC Peer connection
  connectRTC(videoStream, cam_index) {

    this.updateCameras(cam_index, "connectionStatus", "Starting Stream")

    if (
      this.state.cameras[cam_index].rtcUrl &&
      this.state.cameras[cam_index].rtcJwt &&
      this.state.cameras[cam_index].rtcStreamName &&
      this.state.iceServers
    ) {

      // Create remote connection and add media tracks
      let rtcConf = {
        iceServers : this.state.iceServers,
        rtcpMuxPolicy : "require",
        bundlePolicy: "max-bundle"
      };

      let pc = new RTCPeerConnection(rtcConf);
      videoStream.getTracks()
      .forEach((track, i) => {

        // Add media track to peer connection
        pc.addTrack(track, videoStream);

        // console.log('PC track capabilities ', track.getCapabilities());
        // console.log('PC track constraints ', track.getConstraints());
        // console.log('PC track settings ', track.getSettings());
      });
      // Get RTCRtpSender to set maxBitrate (Chrome)
      // let senders = pc.getSenders();

      let ws = new WebSocket(this.state.cameras[cam_index].rtcUrl + '?token=' + this.state.cameras[cam_index].rtcJwt);

      ws.onopen = () => {

        // Create RTC offer
        pc.createOffer()
        .then(desc => {
          
          // Set stereo?
          if (this.state.cameras[cam_index].audioChannels === 2) {
            desc.sdp = updateSdpAudioParameters(desc.sdp, 2)
          }

          pc.setLocalDescription(desc)
          .then(() => {
            //set required information for media server.
            let newSDP = this.updateBandwidthRestriction(
              desc.sdp,
              this.state.quality[this.state.cameras[cam_index].selectedQuality].bitrate
            );

            let data    = {
              name:  this.state.cameras[cam_index].rtcStreamName,
              sdp: newSDP,
              codec: this.state.cameras[cam_index].selectedCodec
            }
            //create payload
            let payload = {
              type:    "cmd",
              transId: Math.random() * 10000,
              name:    'publish',
              data:    data
            }

            // Send payload to start Peer Connection
            ws.send(JSON.stringify(payload));

            // Store peer connection
            let new_cameras = this.state.cameras;
            new_cameras[cam_index].rtcConnection = pc;

            this.setState({
              cameras: new_cameras
            });

            // this.props.enqueueSnackbar('Initiating Connection ...', { variant: 'info' });
            // console.log('Local description set ');
          })
          .catch(err => {
            this.props.enqueueSnackbar('Looks like we had an issue connecting. ' + err, { variant: 'error' });
            this.closeCamera(cam_index);
            console.error('Error - Could not set local description', err);
          });
          // console.log('Offer created');
        })
        .catch(err => {
          this.props.enqueueSnackbar('Looks like we had an issue connecting. ' + err, { variant: 'error' });
          this.closeCamera(cam_index);
          console.error('Error - Could not create offer', err);
        });
        // console.log('Opened WS connection');
      }

      // Listen for WebSocket replies
      ws.addEventListener('message', evt => {
        let msg = JSON.parse(evt.data);
        switch (msg.type) {
          // Receive remote description
          case 'response':

            // Create answer
            let newSDP = this.updateBandwidthRestriction(
              msg.data.sdp,
              this.state.quality[this.state.cameras[cam_index].selectedQuality].bitrate
            );

            // let data = msg.data;
            let answer = new RTCSessionDescription({
              type: 'answer',
              sdp: newSDP
            });

            // Set remote description
            pc.setRemoteDescription(answer)
            .then(d => {

              // this.props.enqueueSnackbar('Connecting ....', { variant: 'info' });

              // Update peer connection
              let new_cameras = this.state.cameras;
              new_cameras[cam_index].isConnected = true;
              new_cameras[cam_index].rtcConnection = pc;

              this.setState({
                cameras: new_cameras
              });
              // console.log('Remote description set');
            })
            .then(f => {
              // Update video track with bandwidth limit
              pc.getSenders().forEach((sender, i) => {
                if (
                  sender.track.kind === 'video'
                ) {
                  let params = sender.getParameters();
                  // console.log('Sender Parameters ', params);
                  if (!params.encodings) {
                    params.encodings = [{}];
                  }
                  params.encodings[0].maxBitrate = this.state.quality[this.state.cameras[cam_index].selectedQuality].bitrate * 1000;
                  sender.setParameters(params)
                  .catch(err => {
                    this.updateCameras(cam_index, "connectionStatus", "Stream Description Error")
                    console.error('remote paramaters Error - ', err);
                  });
// this.logRTCSenderStats(senders);

                }
              });
            })
            .catch(err => {
              this.updateCameras(cam_index, "connectionStatus", "Stream Error")
              this.props.enqueueSnackbar('Looks like we had an issue connecting. ' + err, { variant: 'error' });
              this.closeCamera(cam_index);
              console.error('Error - Could not set remote description ', err);
            });

          break;
        // Receive connection status
        case 'event':
          switch (msg.name) {
            case 'active':
            // TODO: Update conection status indicator - Not recieving this message
              console.log('RTC Connection Active');
              break;
            case 'inactive':
            // TODO: Update conection status indicator
              console.log('RTC Connection Inactive');
              break;
            default:

          }
          break;
        default:
          console.log('Received unknown WS reply ', msg.type);
      }
      })

      // Handle websocket connection errors
      ws.onerror = (e) => {
        console.error('Websocket connection error ', e);
        setTimeout(() => {
          if (this.state.cameras[cam_index].videoStream) {
            console.log('Attempting WS reconnection for Cam %s', cam_index);
            this.updateCameras(cam_index, 'isConnected', false);
            this.getSubscribe(cam_index);

          } else {
            this.updateCameras(cam_index, "connectionStatus", "Stream Failed")

            this.props.enqueueSnackbar('Stream Disconnected.  Please restart stream.', { variant: 'warning' });
            console.log('No video stream to connect with.  Full restart?');
          }

        }, 2000);
      }

      // Add connection even listeners
      pc.addEventListener("connectionstatechange", (event) => {
        switch(pc.connectionState) {
          case "connected":
            // The connection has become fully connected
            this.updateCameras(cam_index, "connectionStatus", "Connected")

            this.updateCameras(cam_index, 'isConnected', true);
            this.props.enqueueSnackbar('Cam '+ this.state.cameras[cam_index].label +' Connection active', { variant: 'success' });
            console.log('RTC connected for Cam %s', cam_index);
            break;
          case "disconnected":
            this.updateCameras(cam_index, 'isConnected', false);
            break;
          case "failed":
            this.updateCameras(cam_index, "connectionStatus", "Connection Failed.  Retrying ...")
            this.props.enqueueSnackbar('Disconnected.  Attempting to reconnect ......', { variant: 'warning' });
            
            setTimeout(() => {
              if (this.state.cameras[cam_index].videoStream) {
                console.log('Attempting RTC reconnection for Cam %s', cam_index);
                this.updateCameras(cam_index, 'isConnected', false);
                this.getSubscribe(cam_index);
              } else {
                this.props.enqueueSnackbar('Stream Disconnected.  Please restart stream.', { variant: 'warning' });
                console.log('No video stream to connect with.  Full restart?');
              }

            }, 2000);
            // console.log('Cam %s RTC ', cam_index, pc.connectionState);
            break;
          case "closed":
            // The connection has been closed
            this.updateCameras(cam_index, "connectionStatus", "Closed")
            this.updateCameras(cam_index, 'isConnected', false);
            console.log('RTC closed');
            break;
        }
      })

      // console.log('Creating a connection for stream ', this.state.cameras[cam_index].rtcStreamName);
    } else {
      // Connection could not proceed.  Release camera
      this.closeCamera(cam_index);
      console.error('RTC No information to connect with ', cam_index, '\n',
        this.state.cameras[cam_index].rtcUrl, '\n',
        this.state.cameras[cam_index].rtcJwt, '\n',
        this.state.cameras[cam_index].rtcStreamName, '\n',
        this.state.iceServers
      );
    }

    // console.log('Creating RTC connection for Cam ', this.state.cameras[cam_index].label);
  }

  // Modify SDP to set bitrate
  updateBandwidthRestriction(sdp, bandwidth) {
    let modifier = 'AS';
    if (this.props.adapter.browserDetails.browser === 'firefox') {
      bandwidth = (bandwidth >>> 0) * 1000;
      modifier = 'TIAS';
    }
    if (sdp.indexOf('b=' + modifier + ':') === -1) {
      // insert b= after c= line.
      sdp = sdp.replace(/m=video (.*)\r\nc=IN (.*)\r\n/, 'm=video $1\r\nc=IN $2\r\nb=' + modifier + ':' + bandwidth + '\r\n');
    } else {
      // Replace existing rate limit
      sdp = sdp.replace(new RegExp('b=' + modifier + ':.*\r\n'), 'b=' + modifier + ':' + bandwidth + '\r\n');
    }
    return sdp;
  }

  logRTCSenderStats(sender) {
    sender.getStats()
    .then(result => {
      let entries = result.entries();
      let res = entries.next();
      while(!res.done) {
        res = entries.next();
      }
      if (this.state.logStats) {
        setTimeout(() => this.logRTCSenderStats(sender), 5000);
      }
    })
    .catch(err => {
      console.error('Error - Could not get RTCRtp stats ', err);
    });
  }

  // ***** end CDN *****

  // ***** Device Selection *****

  // Update selected camera selection
  changeVideoDevice(e, cam_index) {

    // Store new selection
    let new_cameras = this.state.cameras;
    new_cameras[cam_index].selectedVideoDevice = e.target.value;

    // Make device selection persistent on reload
    sessionStorage.setItem(this.state.cameras[cam_index].label + '_cam_select', e.target.value);

    // Update selected video device in state
    this.setState({
      cameras: new_cameras
    });

    // Update tracks on active connections
    this.updateStream(e.target.value === 'screen' ? 'screen' : 'video', cam_index);
  }

  // Update selected audio device
  changeAudioDevice(e, cam_index) {

    // Store new selection
    let new_cameras = this.state.cameras;
    new_cameras[cam_index].selectedAudioDevice = e.target.value;

    // Make device selection persistent on reload
    sessionStorage.setItem(this.state.cameras[cam_index].label + '_aud_select', e.target.value);

    // Update selected video device in state
    this.setState({
      cameras: new_cameras
    });

    // Update tracks on active connections
    this.updateStream('audio', cam_index);
  }

  // Change selected quality
  changeQuality(e, cam_index) {
    let new_cameras = this.state.cameras;
    new_cameras[cam_index].selectedQuality = e.target.value;

    // Make device selection persistent on reload
    sessionStorage.setItem(this.state.cameras[cam_index].label + '_qual_select', e.target.value);

    // Update selected video device in state
    this.setState({
      cameras: new_cameras
    });

    // Reconnect peer connection with new settings
    if (this.state.cameras[cam_index].rtcConnection && this.state.cameras[cam_index].rtcConnection.connectionState === 'connected') {

      // Hid the camera settings overlay
      this.hide_camera_settings(cam_index);

      // Stop and Start the peer connection
      this.closeCamera(cam_index);
      this.openCamera(cam_index, true);
    }
  }

  // Change selected codec
  changeCodec(e, cam_index) {
    let new_cameras = this.state.cameras;
    new_cameras[cam_index].selectedCodec = e.target.value;

    // Make device selection persistent on reload
    sessionStorage.setItem(this.state.cameras[cam_index].label + '_codec_select', e.target.value);

    // Update selected video device in state
    this.setState({
      cameras: new_cameras
    });

    // Reconnect peer connection with new settings
    if (this.state.cameras[cam_index].rtcConnection && this.state.cameras[cam_index].rtcConnection.connectionState === 'connected') {

      // Hide the camera settings overlay
      this.hide_camera_settings(cam_index);

      // Stop and Start the peer connection
      this.closeCamera(cam_index);
      this.openCamera(cam_index, true);
    }
  }

  toggleEchoCancellation(e, cam_index) {
    let new_cameras = this.state.cameras;
    new_cameras[cam_index].selectedEchoCancellation = e.target.checked;

    // Make device selection persistent on reload
    sessionStorage.setItem(this.state.cameras[cam_index].label + '_echo_select', e.target.checked);

    // Cannot use stereo with echo cancellation
    if (this.state.cameras[cam_index]?.audioChannels ===2) {
      this.toggleAudioChannels({ target: {checked: false }}, cam_index)
    }

    // Update selected video device in state
    this.setState({
      cameras: new_cameras
    });

    // Reconnect peer connection with new settings
    if (this.state.cameras[cam_index].rtcConnection && this.state.cameras[cam_index].rtcConnection.connectionState === 'connected') {

      // Hide the camera settings overlay
      this.hide_camera_settings(cam_index);

      // Stop and Start the peer connection
      this.closeCamera(cam_index);
      this.openCamera(cam_index, true);
    }

  }

  toggleAudioChannels(e, cam_index) {
    let audioChannels;
    if (e.target.checked && this.state.cameras[cam_index]?.audioChannels !== 2) {
      audioChannels = 2
      // Echo cancellation is mono only
      this.toggleEchoCancellation({target: {checked: false}}, cam_index)
      this.updateCameras(cam_index, 'audioChannels', 2)
      sessionStorage.setItem(this.state.cameras[cam_index].label + '_audio_channels', 2);
    } else if (!e.target.checked && this.state.cameras[cam_index]?.audioChannels !== 1) {
      audioChannels = 1
      this.updateCameras(cam_index, 'audioChannels', 1)
      sessionStorage.setItem(this.state.cameras[cam_index].label + '_audio_channels', 1);
    }

    // Reconnect peer connection with new settings
    if (this.state.cameras[cam_index].rtcConnection && this.state.cameras[cam_index].rtcConnection.connectionState === 'connected') {

      // Hide the camera settings overlay
      this.hide_camera_settings(cam_index);

      // Stop and Start the peer connection
      this.closeCamera(cam_index);
      this.openCamera(cam_index, true);
    }
  }

  // Replace media stream tracks on Peer Connection
  async updateStream(type, cam_index) {
    // Update tracks on active connections
    if (
      this.state.cameras[cam_index].rtcConnection &&
      this.state.cameras[cam_index].rtcConnection.connectionState === 'connected'
    ) {

      // Close display
      this.hide_camera_settings(cam_index);

      // Create reference for new media stream
      let userMedia;

      // Set Constraints
      const myConstraints = this.setConstraints(type, cam_index);

      // Restart connection if removing audio
      if (myConstraints && !myConstraints.audio) {

        // console.log('## Restarting connection ', myConstraints);
        // Close and restart Peer Connection
        this.closeCamera(cam_index);
        this.openCamera(cam_index, true);

      } else { // Replace individual tracks

        // Start Media Stream
        try {
          if (type === 'screen') {
            userMedia = await navigator.mediaDevices.getDisplayMedia(myConstraints);
          } else {
            userMedia = await navigator.mediaDevices.getUserMedia(myConstraints);
          }
        } catch (e) {
          // Try closing and restaring peer connection
          this.closeCamera(cam_index);
          this.openCamera(cam_index, true);

          // this.props.enqueueSnackbar('There was an error changing devices.  Try refreshing your browser window and starting the streams again.', { variant: 'error' });
          console.error('** Could not update media stream, restarting peer connection ', e);
        }

        // Stop existing streams
        this.state.cameras[cam_index].videoStream.getTracks().forEach(track => {
          if (track.type === type) {
            track.stop();
          }
        })

        // Get tracks from userMedia and RTCRtpSenders
        let senders;
        let newTracks;
        let localTracks
        if (type === 'audio') {

          // Get new audio tracks
          newTracks = userMedia.getAudioTracks();

          // Get audio track senders
          senders = this.state.cameras[cam_index].rtcConnection.getSenders().filter(sender => {
            if (sender.track && sender.track.kind) {
              return sender.track.kind === 'audio';
            } else {
              return false;
            }
          });

          // Get local video tracks
          localTracks = this.state.cameras[cam_index].videoStream.getAudioTracks();

        } else {
          // Get new video tracks
          newTracks = userMedia.getVideoTracks();

          // Get video track senders
          senders = this.state.cameras[cam_index].rtcConnection.getSenders().filter(sender => {
            if (sender.track && sender.track.kind) {
              return sender.track.kind === 'video';
            } else {
              return false;
            }
          });

          // Get local video tracks
          localTracks = this.state.cameras[cam_index].videoStream.getVideoTracks();

        }

        // Check that track exists
        if (
          newTracks.length > 0 &&
          newTracks.length === senders.length &&
          newTracks.length === localTracks.length
        ) {
          newTracks.forEach((track, i) => {
            // Replace remote track
            senders[i].replaceTrack(track);

            // Replace local display track
            this.state.cameras[cam_index].videoStream.removeTrack(localTracks[i]);
            this.state.cameras[cam_index].videoStream.addTrack(newTracks[i])

          });

        } else {
          // This is a fallback in case the number of tracks / senders differs which
          // doesn't allow for directly replacing tracks 1 to 1

          // Close and restart Peer Connection
          this.closeCamera(cam_index);
          this.openCamera(cam_index, true);

          // console.log('** Could not replace tracks, restarting peer connection ', senders.length, localTracks.length, newTracks.length);
        }

      }

    } else {

      // TODO: Replace preview tracks instead of stream tracks
      // Close display
      // this.hide_camera_settings(cam_index);
      // // Close and restart video
      // this.closeCamera(cam_index);
      // this.openCamera(cam_index, false);
    }
  }

  // ***** Display functions *****

  // Show camera settings popover
  display_camera_settings(e, i) {
    // Update open state
    let new_camera = this.state.cameras;
    new_camera[i].open = true;
    new_camera[i].anchorEl = e.target;
    // Store open state
    this.setState({
      cameras: new_camera
    });
  }

  // Hide camera settings popover
  hide_camera_settings(i) {
    // Update open state
    let new_camera = this.state.cameras;
    new_camera[i].open = false;
    new_camera[i].anchorEl = null;
    // Store open state
    this.setState({
      cameras: new_camera
    });
  }

  // Enable or Disable cameras
  enable_cameras(enabled_cameras) {

    // Close streams for cameras to be hidden
    for (let c = enabled_cameras; this.state.cameras.length > c; c++) {
      console.log('Closing connection ', c);
      this.closeCamera(c);
    }

    // Turn cameras on
    let new_camera = this.state.cameras;

    switch (enabled_cameras) {

      // Single Camera
      case 1:
        // Loop through cameras
        for (let i=0; i < this.state.cameras.length; i++) {
          // Enable each camera
          new_camera[i].enabled = i < enabled_cameras ? true : false;

          // Get element reference if not already stored
          if (!new_camera[i].video_display) {
            new_camera[i].video_display = document.getElementById(this.state.cameras[i].label + '_cam');
          }
        }

        break;

        // 2 Cameras
        case 2:
          // Loop through cameras
          for (let i=0; i < this.state.cameras.length; i++) {
            // Enable each camera
            new_camera[i].enabled = i < enabled_cameras ? true : false;

            // Get element reference if not already stored
            if (!new_camera[i].video_display) {
              new_camera[i].video_display = document.getElementById(this.state.cameras[i].label + '_cam');
            }
          }
          break;

      // Multiple cameras
      default:
        // Loop through cameras
        for (let i=0; i < this.state.cameras.length; i++) {
          // Enable each camera
          new_camera[i].enabled = i < enabled_cameras ? true : false;

          // Get element reference if not already stored
          if (!new_camera[i].video_display) {
            new_camera[i].video_display = document.getElementById(this.state.cameras[i].label + '_cam');
          }
        }
    }

    // Store selection
    this.setState({
      number_of_cameras: enabled_cameras,
      cameras: new_camera
    })

  }

  // Update cameras array
  updateCameras(cam_index, cam_key, cam_value) {
    let new_cameras = this.state.cameras;
    new_cameras[cam_index][cam_key] = cam_value;
    this.setState({
      cameras: new_cameras
    });
  }

  // ***** Lifecycle *****
  componentDidMount() {
    if (this.state.cookies) {
      // Request device access permission if focused tab
      if (this.props.tab) {
        // Get connection info
        this.getPubInfo(null, null, false);
        // Get available input devices
        this.getAvailableDevices();

        // Add device change listener
        navigator.mediaDevices.ondevicechange = () => {
          this.getAvailableDevices();
        }

      }
    }
  }

  componentWillUnmount() {
    // Cleanup RTC Connections
    this.state.cameras.forEach((camera, i) => {
      this.closeCamera(i);
    })
  }

  // ***** Rendering Functions *****

  // Return connection status display
  connectionStatus(cam_index) {
    if (this.state.cameras[cam_index]?.rtcConnection) {
      return this.state.cameras[cam_index].rtcConnection.connectionState
    } else if (this.state.cameras[cam_index].connectionStatus) {
      return this.state.cameras[cam_index].connectionStatus
    } else {
      return "Paused"
    }
  }

  connectionIcon(cam_index) {
    if (this.state.cameras[cam_index]?.rtcConnection?.connectionState === "connected") {
      return (<WifiIcon />)
    } else if (this.state.cameras[cam_index].connectionStatus) {
      return (<CircularProgress color='primary' size={20} />)
    } else {
      return (<MenuOpenIcon />)
    }
  }

  // Render video display
  viewers(cssClasses, i) {
    return (
      <Grid
        item
        className={
          this.props.enabledCameras === 1 ?
            (this.props.isVCOpen ? this.props.cssClasses.videoDisplayOne : this.props.cssClasses.videoDisplayOneNoVC) :
            this.props.enabledCameras === 2 ?
              (this.props.isVCOpen ? this.props.cssClasses.videoDisplayTwo : this.props.cssClasses.videoDisplayTwoNoVC) :
              this.props.enabledCameras > 2 ?
                (this.props.isVCOpen ? this.props.cssClasses.videoDisplayQuad : this.props.cssClasses.videoDisplayQuadNoVC) :
                this.props.cssClasses.videoDisplayOne
        }
        style={{
          display: this.state.cameras[i].enabled ? 'block' : 'none',
          order: i,
        }}
        id={this.state.cameras[i].label + '_cam'}
        key={'VW'+i}
      >
        {this.camera_controls(cssClasses, i)}
        <Card
          elevation={4}
          style={{"border":"solid 2px " + this.state.cameras[i].color}}
        >
          <CardMedia
            component = 'video'
            ref = {this.state.cameras[i].videoSource}
            autoPlay = {true}
            playsInline
            controls
            loop = {true}
            muted = {true}
            src = {this.props.logoLoop}
          />
        </Card>
      </Grid>
    )
  }

  // Render camera settings popovers
  camera_controls(cssClasses, i) {
    return (
      <Grid
        container
        style={{"flex":"1", "textAlign":"center", "backgroundColor":this.state.cameras[i].color}}
      >
        <Grid item xs={12}>
          <Button
            aria-describedby={"camera_" + this.state.cameras[i].label}
            fullWidth
            variant="contained"
            style={{"backgroundColor":this.state.cameras[i].color}}
            onClick={e => this.display_camera_settings(e, i)}
            startIcon = {
              this.connectionIcon(i)
            }
            // endIcon = {
            //   this.state.cameras[i].rtcConnection ?
            //     this.state.cameras[i].rtcConnection.connectionState === 'connected' ?
            //     <WifiIcon />
            //     :
            //     this.state.cameras[i].connectionStatus ?
            //     <CircularProgress 
            //       color="primary"
            //       size="small"
            //     /> : null
            //   :
            //   null
            // }
          >

            {this.state.cameras[i].label}-Cam Settings ({this.connectionStatus(i)})

          </Button>
        </Grid>

          <Popover
            id={"camera_" + this.state.cameras[i].label}
            open={this.state.cameras[i].open}
            anchorEl={this.state.cameras[i].anchorEl}
            onClose={e => this.hide_camera_settings(i)}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
          >
            <Grid style={{'padding':'16px'}}>

              <Grid item className={cssClasses.select}>
                <FormControl
                  fullWidth
                  focused
                  className = {cssClasses.select}
                >

                  <InputLabel id="videoDevices" className={cssClasses.selectLabel}>Video Devices</InputLabel>
                  <Select
                    id = 'videoDevices'
                    labelId = 'videoDevices'
                    label = 'Video Devices'
                    value = {this.state.cameras[i].selectedVideoDevice}
                    fullWidth
                    variant="outlined"
                    onChange = {vi => this.changeVideoDevice(vi, i)}
                  >
                  {
                    this.state.videoDevices.map((vdev, i) => (
                      <MenuItem value={vdev.deviceId} key={'MEN'+i}>{vdev.label}</MenuItem>
                    ))
                  }
                  </Select>
                </FormControl>
              </Grid>

              <Grid item className={cssClasses.select}>
                <FormControl
                  fullWidth
                  className = {cssClasses.select}
                  focused
                >
                  <InputLabel id="audioDevices" className={cssClasses.selectLabel}>Audio Devices</InputLabel>
                  <Select
                    id = 'audioDevices'
                    labelId = 'audioDevices'
                    label = 'Audio Devices'
                    value = {this.state.cameras[i].selectedAudioDevice}
                    fullWidth
                    variant="outlined"
                    onChange = {au => this.changeAudioDevice(au, i)}
                  >
                  {
                    this.state.audioDevices.map((adev, i) => (
                      <MenuItem value={adev.deviceId} className={cssClasses.select} style={{'fontSize':'.8em'}} key={'ADM'+i}>{adev.label}</MenuItem>
                    ))
                  }
                  </Select>
                </FormControl>
              </Grid>

              <Grid item className={cssClasses.select}>
                <FormControl fullWidth className={cssClasses.select}>
                  <InputLabel id="quality" className={cssClasses.selectLabel}>Quality</InputLabel>
                  <Select
                    id = 'quality'
                    labelId = 'quality'
                    label = 'Quality'
                    value = {this.state.cameras[i].selectedQuality}
                    fullWidth
                    variant="outlined"
                    onChange = {fr => this.changeQuality(fr, i)}
                  >
                  {
                    this.state.quality.map((br, i) => (
                      <MenuItem value={br.id} key={'SQM'+i}>{br.label}</MenuItem>
                    ))
                  }
                  </Select>
                </FormControl>
              </Grid>

              <Grid item className={cssClasses.select}>
                <FormControl fullWidth className={cssClasses.select}>
                  <InputLabel id="bitRate" className={cssClasses.selectLabel}>Codec</InputLabel>
                  <Select
                    id = 'allowedCodecs'
                    labelId = 'bitRate'
                    label = 'Codec'
                    value = {this.state.cameras[i].selectedCodec}
                    fullWidth
                    variant="outlined"
                    onChange = {fr => this.changeCodec(fr, i)}
                  >
                  {
                    this.state.allowedCodecs.map((br, i) => (
                      <MenuItem value={br.rate} key={'SAC'+i}>{br.label}</MenuItem>
                    ))
                  }
                  </Select>
                </FormControl>
              </Grid>

              <Grid item className={cssClasses.select}>
                <FormControl fullWidth className={cssClasses.select}>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={this.state.cameras[i].audioChannels === 2}
                        onChange={ec => this.toggleAudioChannels(ec, i)}
                        color="primary"
                        id="audioChannels"
                      />
                    }
                    label={this.state.cameras[i].audioChannels === 2 ? "Stereo" : "Mono"}
                  />
                </FormControl>
              </Grid>

              <Grid item className={cssClasses.select}>
                <FormControl fullWidth className={cssClasses.select}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={this.state.cameras[i].selectedEchoCancellation}
                        onChange={ec => this.toggleEchoCancellation(ec, i)}
                        color="primary"
                        id="echoCancellation"
                      />
                    }
                    label="Echo Cancellation"
                  />
                </FormControl>
              </Grid>

              {
                this.state.cameras[i].rtcConnection ?
                  this.state.cameras[i].rtcConnection.connectionState === 'connected' ?
                  <Grid item>
                    <Button
                      onClick={ev => this.closeCamera(i)}
                      variant="contained"
                      color="secondary"
                      fullWidth
                    >
                      Stop {this.state.cameras[i].label} Cam
                    </Button>
                  </Grid>
                  :
                  <Grid item className={cssClasses.tableBody}>
                    <Button
                      onClick={ev => this.openCamera(i, true)}
                      variant="contained"
                      color="primary"
                      fullWidth
                    >
                      Stream {this.state.cameras[i].label} Cam
                    </Button>
                  </Grid>
                :
                <Grid item className={cssClasses.tableBody}>
                  <Button
                    onClick={ev => this.openCamera(i, true)}
                    variant="contained"
                    color="primary"
                    fullWidth
                  >
                    Start Streaming
                  </Button>
                </Grid>
              }

            </Grid>
          </Popover>

      </Grid>
    )
  }

  render() {

    // Create display elements
    let viewers = [];
    for (let i = 0; i < this.state.cameras.length; i++) {
      viewers.push(this.viewers(this.props.cssClasses, i));
    }

    return (
      <Grid
        container
        className={this.props.isVCOpen ? this.props.cssClasses.broadcastDispaly : this.props.cssClasses.broadcastDisplayNoVC}
      >
        <Grid
          item
          xs={12}
          id="video_displays"
          className={
            this.props.isVCOpen ?
              this.props.cssClasses.videoDisplay :
              this.props.cssClasses.videoDisplayNoVC
          }
        >
          {viewers}
        </Grid>
      </Grid>
    )
  }
}

export default (withSnackbar(WebPubPortal))
