import React, { useState } from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { useSnackbar } from 'notistack';
import { useHistory } from 'react-router-dom';

const axios = require('axios').default;

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://setstream.io">
        Set Stream .io
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

export default function SignIn() {
  const classes = useStyles();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();

  // State
  const initialState = {
    userName: '',
    password: ''
  }
  const [formState, updateFormState] = useState(initialState);
  // Update state
  function setFormState(event) {
    const name = event.target.name;
    let value;
    if (event.target.type === 'checkbox') {
      value = event.target.checked;
    } else {
      value = event.target.value;
    }
    updateFormState(
      {...formState, [name]: value}
    );
  }

  // Form submission
  function submitForm(e) {
    e.preventDefault();
    enqueueSnackbar('Submitting your request, please stand by ....');
    console.log('Logging in user ', formState.userName);
    axios.post(process.env.REACT_APP_HOST_URL + '/api/admin_login', formState)
    .then(res => {
      console.log('Received login reply ', res.data.type);
      if (res.data.type) {
        switch (res.data.type) {
          case 'success':
            enqueueSnackbar('Logged in', {variant: 'success'});
            console.log('Logged in ', res.data.admin_uuid);
            history.push('/site_admin/' + res.data.admin_uuid);
            break;
          case 'error':
            enqueueSnackbar(res.data.message, {variant: 'error'});
            break;
          default:
        }
      } else {
        console.log('Error in sign in: ', res.data.message);
        enqueueSnackbar('Something went wrong. ' + res.data.message, {variant: 'error'});
      }
    })
    .catch(err => {
      console.error('Error in processing your request ', err);
      enqueueSnackbar('Something went wrong. ' + err, {variant: 'error'});
    })
  }

  return (
    <Container maxWidth="xs">
      <CssBaseline />
      <Box className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Sign in
        </Typography>
        <form
        className={classes.form}
        onSubmit={(e) => submitForm(e)}
        >
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="userName"
            label="User Name"
            name="userName"
            autoComplete="userName"
            autoFocus
            onChange = {(e) => setFormState(e)}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
            onChange = {(e) => setFormState(e)}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
          >
            Sign In
          </Button>
          <Grid container>
            <Grid item xs>
              <Link href="#" variant="body2">
                {/* Forgot password? */}
              </Link>
            </Grid>
            <Grid item>
              {/*<Link href="register" variant="body2">
                {"Don't have an account? Sign Up"}
              </Link>*/}
            </Grid>
          </Grid>
        </form>
      </Box>
      <Box mt={8}>
        <Copyright />
      </Box>
    </Container>
  );
}
