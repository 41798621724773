export default function prettyKeys(data) {
  // This hook takes a camelCase key name and converts it to human redable capitolized text

  return (
    data
    .split(/(?=[A-Z])/)
    .map(item => item[0].toUpperCase() + item.slice(1))
    .join(' ')
  )
}
