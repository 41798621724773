import React, { useEffect, useState } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import {
Grid,
Hidden,
Typography,
Button,
FormControlLabel,
Switch,
} from '@material-ui/core/';
import { useSnackbar } from 'notistack';
import CreateNewProject from '../Projects/create_new_project';
import ProjectListItem from '../Projects/project_list_item';
import useDashboardAccount from '../Context/useDashboardAccount';
import ArchivedProjectsListItem from '../Projects/archived_project_list_item';

const useStyles = makeStyles((theme) => createStyles({
  container: {
    padding: theme.spacing(2),
  },
  spacing: {
    padding: theme.spacing(12)
  }
}));

export default function ProjectsTab(props) {
  // Hooks
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const { 
    customer_uuid, 
    projects, 
    archivedProjects
  } = useDashboardAccount();

  // State
  const [selected, setSelected] = useState('available_passes');
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [showArchived, setShowArchived] = useState(false);

  // Actions
  const handleClose = () => {
    setIsDialogOpen(false);
  }

  return (
    <Grid container className={classes.container}>
      <Grid item xs={12} className={props.cssClasses.expansion}>

        <Grid container>
          <Grid item xs={3}>
              <Typography variant="h5">Projects</Typography>
            </Grid>

            <Grid item xs={9} align="right">
              <FormControlLabel
                control={
                  <Switch
                    checked={showArchived}
                    onChange={(e) => setShowArchived(!showArchived)}
                  />
                }
                label="Show Archived Projects"
              />
            </Grid>

            <Grid item xs={12} className={props.cssClasses.tableBody}>
              <CreateNewProject 
                cssClasses={props.cssClasses} 
              />
            </Grid>

            <Grid item xs={12} className={props.cssClasses.spacing}></Grid>

            <Grid item xs={2} sm={1} align="center" className={props.cssClasses.tableHeader}>
              Settings
            </Grid>

            <Grid item xs={10} sm={3} className={props.cssClasses.tableHeader}>
              Project Name
            </Grid>

            <Hidden xsDown>
              <Grid item xs={6} className={props.cssClasses.tableHeader}>
                Project URL
              </Grid>

              <Grid item xs={2} className={props.cssClasses.tableHeader}>
                Password
              </Grid>
            </Hidden>
            
            <Grid item xs={12} className={props.cssClasses.tableBody}>
              {
                projects?.map((project, i) => {
                  return (
                    <ProjectListItem
                      key={'project'+i}
                      project={project}
                      {...props}
                    />
                  )
                })
              }
              {
                showArchived &&
                archivedProjects?.map((project,i) => {
                  return (
                    <ArchivedProjectsListItem
                      key={'aproject'+i}
                      project={project}
                      {...props}
                    />
                  )
                })
              }
            </Grid>
        </Grid>

      </Grid>
    </Grid>
  );

}
