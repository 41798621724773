import React from 'react';
import { styled } from '@material-ui/core/styles';
import { Participant } from 'twilio-video';
import VolumeOffIcon from '@material-ui/icons/VolumeOff';
import VolumeUpIcon from '@material-ui/icons/VolumeUp';
import IconButton from '@material-ui/core/IconButton';
import useSubscriptionRules from '../../../hooks/useSubscriptionRules/useSubscriptionRules';
import usePublications from '../../../hooks/usePublications/usePublications';
import useTrack from '../../../hooks/useTrack/useTrack';
import useIsTrackEnabled from '../../../hooks/useIsTrackEnabled/useIsTrackEnabled';
import useIsTrackSubscribed from '../../../hooks/usePublicationIsAudioTrackSubscribed/usePublicationIsAudioTrackSubscribed';
import useMutedParticipants from '../VideoProvider/useMutedParticipants/useMutedParticipants';



export default function MuteParticipant({ participant }: { participant: Participant }) {

  const [mutedParticipants, setMutedParticipants] = useMutedParticipants();

  const handleClick = () => {
    setMutedParticipants(participant);
  }

  return (
    !mutedParticipants.includes(participant) ?
      <IconButton
        onClick={handleClick}
      >
        <VolumeUpIcon
          color="primary"
        />
      </IconButton>
      :
      <IconButton
        onClick={handleClick}
      >
        <VolumeOffIcon />
      </IconButton>
  );
}
