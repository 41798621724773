import React, { useEffect } from 'react';
import { styled, Theme } from '@material-ui/core/styles';
import MainParticipant from './MainParticipant/MainParticipant';
// import { useAppState } from '../../state';
import useRoomState from '../../hooks/useRoomState/useRoomState';
import Grid from '@material-ui/core/Grid';

const Main = styled('main')(({ theme }: { theme: Theme }) => ({
  overflow: 'hidden',
  width: '100%',
  maxWidth: '254px',
  height: '254px',
  maxHeight: '254px',
  background: 'black',
  borderRadius: '16px',
  zIndex: 5
}));

export default function VCActiveSpeaker(props) {
  const roomState = useRoomState();
  // const { isAudioOnly } = useAppState();
  const setWithActiveSpeaker = props.setWithActiveSpeaker;

  useEffect(() => {
    let newValue = roomState === 'disconnected' ? false : true;
    setWithActiveSpeaker(newValue);
  }, [roomState])

  // Don't render component if audio only
  // if (isAudioOnly) {return null;}

  return (

    <Grid container>
      {roomState !== 'disconnected' ? (
        <Main style={{margin:'auto'}}>
          <MainParticipant />
        </Main>
      ) : null}
    </Grid>
  );
}
