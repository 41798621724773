import React, { useEffect, useState, useRef } from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import getCurrentSize from '../Utils/getCurrentSize';
import MarkerIcon from './marker_icon';
import { Marker } from '../sketches_types';

interface Props {
  marker: Marker
}

const useStyles = makeStyles((theme) => createStyles({
  image: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    pointerEvents: 'none',
  }
}));

export default function SketchesMarker(props: Props) {
  // Hooks
  const classes = useStyles();

  const [color, setColor] = useState<string>('#FFFFFF');

  const markerRef = useRef<SVGElement>();

  // Marker Data
  useEffect(() => {
    positionMarker();
  }, [props.marker]);

  // Actions
  const positionMarker = () => {
    if (props.marker?.mEvents?.length > 0 && markerRef?.current) {

      let [currentWidth, currentHeight] = getCurrentSize(markerRef.current.parentNode as Element);
      
      // Scale
      let scaleX = currentWidth / props.marker.canvasWidth;
      let scaleY = currentHeight / props.marker.canvasHeight;

      // Set marker boundaries
      let newLeft = props.marker.mEvents[0].x * scaleX;
      let newTop = props.marker.mEvents[0].y * scaleY;
      if (newLeft > currentWidth) { newLeft = currentWidth}
      if (newTop > currentHeight - 16) { newTop = currentHeight - 16 }

      let percentageX = (newLeft / currentWidth) * 100;
      let percentageY = (newTop / currentHeight) * 100;

      markerRef.current.style.left = percentageX + '%';
      markerRef.current.style.top = percentageY + '%';

    }
    if (props.marker?.color) {
      setColor(props.marker.color)
    }
  }

  // Render
  return (
    <MarkerIcon
      markerRef={markerRef}
      viewer_color={color}
      showMarker={true}
    />
  );

}
