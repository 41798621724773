import React, { useEffect, useState } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import {
Grid,
Hidden,
Typography,
Backdrop,
CircularProgress,
Box,
Link,
TextField,
Avatar,
Button,
Container
} from '@material-ui/core/';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import { useSnackbar } from 'notistack';
import { useHistory } from 'react-router-dom';


const useStyles = makeStyles((theme) => createStyles({
  container: {
  },
  center: {
    margin: 'auto',
    maxWidth: '450px'
  },
  paper: {
    marginTop: theme.spacing(8),
  },
  avatar: {
    backgroundColor: theme.palette.secondary.main,
    margin: 'auto'
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export default function AccountLogin(props) {
  // Hooks
  const classes = useStyles();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();

  // State
  const [inProgress, setInProgress] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  // Effects
  useEffect(() => {

    return () => {
    };
  }, []);

  // Actions
  const sendData = async (e) => {
    e.preventDefault();

    try {
      setInProgress(true);

      let fetchUrl = process.env.REACT_APP_HOST_URL + '/api/account_login'
      let fetchData = {
        method: 'POST',
        headers: {
          'Content-Type':'application/json'
        },
        body: JSON.stringify({
          email: email,
          password: password
        })
      }
      let res = await fetch(fetchUrl, fetchData);
      if (res.ok) {
        setInProgress(false);
        
        let response = await res.json();
        // Store response in state

        if (response.type === 'success' && response.uuid) {
          enqueueSnackbar('Logged in', {variant: 'success'});
          console.log('Logged in ', response.uuid);
          history.push('/Producer/' + response.uuid);
        } else {
          console.error('Could not log in ', response);
        }

      } else {
        console.error('Could not retrieve data ', res.status);
      }  
    } catch (error) {
      console.error('Network error getting data ', error);
    }
  }

  // Render
  return (
    <Grid container className={classes.container}>
      <Grid item className={classes.center}>
        <Container >
          <Box className={classes.paper}>
            <Avatar className={classes.avatar}>
              <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5" align="center">
              Sign in
            </Typography>
            <form
            className={classes.form}
            onSubmit={sendData}
            >
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email"
                name="email"
                autoComplete="email"
                type="email"
                value={email}
                onChange = {(e) => setEmail(e.target.value)}
              />
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                autoComplete="current-password"
                onChange = {(e) => setPassword(e.target.value)}
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
              >
                Sign In
              </Button>
            </form>
          </Box>
        </Container>
      </Grid>

      <Backdrop open={inProgress} style={{zIndex:2001}}>
        <CircularProgress color="primary" variant="indeterminate" />
      </Backdrop>
    </Grid>
  );

}
