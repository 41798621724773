import React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import VideocamIcon from '@material-ui/icons/Videocam';
import VideocamOffIcon from '@material-ui/icons/VideocamOff';
import useAudioOnly from '../../../../hooks/useAudioOnly/useAudioOnly';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    startIcon: {
      marginLeft: '-4px',
      marginRight: '6px',
      [theme.breakpoints.down('md')]: {
        margin:0
      },
    },
  })
);

export default function ToggleAudioButton(props: { disabled?: boolean; className?: string }) {
  const classes = useStyles();
  const [isAudioOnly, toggleAudioOnly] = useAudioOnly();

  return (
    <Button
      className={props.className}
      classes={{startIcon: classes.startIcon}}
      onClick={toggleAudioOnly}
      disabled={props.disabled}
      startIcon={isAudioOnly ? <VideocamOffIcon /> : <VideocamIcon />}
      data-cy-audio-toggle
      variant='contained'
    >
      {isAudioOnly ? 'Enable Peer Video' : 'Reduce Bandwidth'}
    </Button>
  );
}
