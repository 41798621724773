import { useEffect, useState } from 'react';
import Pusher from 'pusher-js/with-encryption';
import useDashboardAccount from '../Context/useDashboardAccount';
import logToServer from '../../../utils/log_to_server';

export default function usePusher() {
  const [pusher, setPusher] = useState();
  const [channel, setChannel] = useState();
  const {
    customer_uuid,
    getAccountData,
  } = useDashboardAccount();

  useEffect(() => {
    // Create Pusher Connection
    let pusher = new Pusher(process.env.REACT_APP_PUSHER_KEY, {
      forceTLS: true,
      cluster: 'us3',
      userAuthentication: {
        endpoint: '/api/pusher/id/'+ customer_uuid + '/' + customer_uuid
      },
      channelAuthorization: {
        endpoint: '/api/pusher/auth/'+ customer_uuid + '/' + customer_uuid
      }
    });

    // pusher.signin()
    
    // Store connection
    setPusher(pusher);

    // Monitor connection status
    pusher.connection.bind('error', (error) => {
      if(
        error?.data?.code !== 1006 &&
        error?.data?.code !== 4009
      ) {
        // Error 4004 is rate limit error
        console.error('Pusher connection error', error?.data);
        // logToServer({
        //   section: 'Dashboard',
        //   action: 'Pusher Error',
        //   page: window.location.href,
        //   message: error?.data?.message,
        //   code: error?.data?.code,
        //   data: error?.data
        // });
      }
    })

    // Setup Channel
    let channel = pusher.subscribe('private-' + customer_uuid);

    channel.bind('pusher:subscription_succeeded', success => {
      // Update channel on subscription
      setChannel(channel);
    });

    channel.bind('update_dashboard', (message) => {
      getAccountData();
    })

    return () => {
      pusher.unbind('update_dashboard');
      pusher.unsubscribe('private-' + customer_uuid);
      pusher.connection.unbind('error');
      pusher.disconnect();
    };
  }, []);

  return [pusher, channel];
}
